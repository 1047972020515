import React from "react";
import PropTypes from "prop-types";

import CreditCardIcon from "@material-ui/icons/CreditCard";
import { Container, BoxSelected, Box } from "./style";

export default function Payment({ payment, selected, handleSelectPayment }) {
  return (
    <Container
      selected={selected}
      card={payment.selectedCard && payment.selectedCard.brand}
      onClick={handleSelectPayment}
    >
      {selected ? (
        <i className="far fa-check-circle" />
      ) : (
        <i className="far fa-circle" />
      )}
      <span>{payment.title}</span>
      {payment.selectedCard && (
        <BoxSelected>
          <Box>
            <span>Credicard</span>
            <span>{payment.selectedCard.brand}</span>
          </Box>
          <Box>
            <span>
              <CreditCardIcon />
            </span>
            <span>************{payment.selectedCard.lastFourDigits}</span>
          </Box>
        </BoxSelected>
      )}
    </Container>
  );
}

Payment.propTypes = {
  selected: PropTypes.bool,
  handleSelectPayment: PropTypes.func,
  payment: PropTypes.shape({
    title: PropTypes.string,
    selectedCard: PropTypes.object,
  }).isRequired,
};

Payment.defaultProps = {
  selected: false,
  handleSelectPayment: () => {},
};
