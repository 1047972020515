export function addToCartSuccess(stamp) {
  return {
    type: "@cart/ADD_TO_CART",
    payload: { stamp },
  };
}

export function selectShipment(shipment) {
  return {
    type: "@cart/SELECT_SHIPMENT",
    payload: { shipment },
  };
}

export function selectPayment(payment) {
  return {
    type: "@cart/SELECT_PAYMENT",
    payload: { payment },
  };
}

export function checkoutRequest(order) {
  return {
    type: "@cart/CHECKOUT_REQUEST",
    payload: { order },
  };
}

export function checkoutSuccess() {
  return {
    type: "@cart/CHECKOUT_SUCCESS",
    payload: {},
  };
}

export function clear() {
  return {
    type: "@cart/CLEAR",
    payload: {},
  };
}

export function checkoutFailure(message) {
  return {
    type: "@cart/CHECKOUT_FAILURE",
    payload: { message },
  };
}

export function orderProcessed() {
  return {
    type: "@cart/ORDER_PROCESSED",
    payload: {},
  };
}

export function processingOrder() {
  return {
    type: "@cart/PROCESSING_ORDER",
    payload: {},
  };
}
