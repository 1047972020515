import React from "react";
import PropTypes from "prop-types";
import { Modal, Content, Button, LinksBox } from "./style";

export default function DefaultInfoModal({
  opened,
  onClose,
  title,
  children,
  showCloseButton,
  closeOnDocumentClick,
  loading,
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
      title={title}
      className="buttons"
      showCloseButton={showCloseButton}
    >
      <Content>{children}</Content>
      <LinksBox>
        <Button
          className="dark"
          type="button"
          onClick={onClose}
          value="Ok"
          loading={loading}
          disabled={loading}
        />
      </LinksBox>
    </Modal>
  );
}

DefaultInfoModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool,
  loading: PropTypes.bool,
};

DefaultInfoModal.defaultProps = {
  onClose: () => { },
  showCloseButton: false,
  closeOnDocumentClick: true,
  loading: false,
};
