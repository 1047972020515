export function setNotifications(hasUnread, notifications) {
  return {
    type: "@notification/SET_NOTIFICATIONS",
    payload: { hasUnread, notifications },
  };
}

export function loadNotifications() {
  return {
    type: "@notification/LOAD_NOTIFICATIONS",
    payload: {},
  };
}
