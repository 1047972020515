import styled from "styled-components";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

export const Container = styled(Card)`
  width: 290px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 182px;
  background-color: #e7eaf2;
  border-radius: 15px;
  padding: 25px 20px;*/
`;

export const Logo = styled.img``;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
  }
`;
