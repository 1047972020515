import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./style";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SuccessModal from "../../../components/modals/DefaultSuccess";
import TicketSmsModal from "../../../components/modals/TicketSendSms";
import TermsModal from "../../../components/modals/TermsModal";

export default function DefaultLayout({ children }) {
  return (
    <>
      <TermsModal />
      <Header />
      <Wrapper>{children}</Wrapper>
      <Footer />
      <SuccessModal />
      <TicketSmsModal />
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
