import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { FormControlLabel } from "@material-ui/core";
import { CheckCircleOutline, RadioButtonUnchecked } from "@material-ui/icons";

import Button from "../../defaultComponents/Button";
import {
  Box,
  Checkbox,
  FavoriteCheckbox,
  Modal,
  FavoriteBox,
  LinksBox,
  Sites,
} from "./style";
import api from "../../../services/api";
import { setSelectedContract } from "../../../store/modules/session/actions";
import { clear } from "../../../store/modules/cart/actions";

export default function SelectSite({ opened, onClose, closeOnDocumentClick }) {
  const dispatch = useDispatch();
  const { sites, selectedContract } = useSelector(state => state.session);
  const [selected, setSelected] = useState({});
  const [makeFavorite, setMakeFavorite] = useState(false);

  useEffect(() => {
    setSelected(selectedContract);
  }, [selectedContract, setSelected]);

  async function handleSelectSite() {
    await api.post("contracts", {
      contractId: selected.contract,
      favorite: makeFavorite,
    });
    dispatch(setSelectedContract(selected));
    dispatch(clear());
    onClose();
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <h2>Você deseja comprar selos para qual unidade?</h2>

      <Sites>
        {sites &&
          sites.map(site => (
            <Box key={site.contract}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircleOutline />}
                    checked={selected.contract === site.contract}
                    onChange={() => setSelected(site)}
                    value={site.contract}
                  />
                }
                label={site.descriptionSite}
              />
            </Box>
          ))}
      </Sites>

      <FavoriteBox>
        <FormControlLabel
          control={
            <FavoriteCheckbox
              checked={makeFavorite}
              onChange={() => setMakeFavorite(!makeFavorite)}
              value="favorite"
              color="primary"
            />
          }
          label="Manter a unidade selecionada como favorita"
        />
      </FavoriteBox>

      <LinksBox>
        <Button className="dark" value="Ok" onClick={handleSelectSite} />
      </LinksBox>
    </Modal>
  );
}

/*
  <Button
    className="light"
    value="Comprar selos"
    onClick={handleSelectSite}
  />
  <Button className="dark" value="Validar selos" onClick={onClose} />
*/

SelectSite.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
};

SelectSite.defaultProps = {
  closeOnDocumentClick: true,
};
