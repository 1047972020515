import socketio from "socket.io-client";
import { successMessage, errorMessage } from "../util/toastNotification";
import { loadNotifications } from "../store/modules/notification/actions";
import { orderProcessed } from "../store/modules/cart/actions";
import { ticketProcessed } from "../store/modules/cartValidate/actions";

const baseURL = process.env.REACT_APP_API_HOST;
const socket = socketio(`${baseURL}`, {
  autoConnect: false,
  rejectUnauthorized: false,
  secure: true,
  /* transports: ["websocket"], */
  /* secure: true,
  transports: ["websocket"],
  rejectUnauthorized: false, */
});
let dispatch = () => {};

socket.on("message", message => {
  if (message.type === "success") {
    successMessage({ message: message.message });
    return;
  }
  if (message.type === "error") {
    errorMessage({ message: message.message });
  }
});

socket.on("new_notification", () => {
  dispatch(loadNotifications());
});

socket.on("allow_buy_stamps", () => {
  dispatch(orderProcessed());
});

socket.on("allow_validate_stamps", () => {
  dispatch(ticketProcessed());
});

function ioConnect(customerNo) {
  if (socket.connected) {
    socket.disconnect();
  }
  socket.io.opts.query = { userId: customerNo };
  socket.connect();
}

function ioDisconnect() {
  if (socket.connected) {
    socket.disconnect();
  }
}

function ioStatus() {
  return { connected: socket.connected };
}

function setDispatch(item) {
  dispatch = item;
}

export { ioConnect, ioDisconnect, ioStatus, setDispatch };
