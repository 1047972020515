import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  background: ${COLORS.$baseGrayBackground};
`;

export const ContentModal = styled.ul`
  h3 {
    color: ${COLORS.$darkBlue};
    font-size: 20px;
    margin-bottom: 14px;
    text-align: center;
  }
  p {
    color: ${COLORS.$darkGray};
    font-size: 14px;
    margin-bottom: 8px;
    text-align: justify;
  }
  li {
    color: ${COLORS.$darkGray};
    text-align: justify;
    margin-bottom: 8px;
    font-size: 14px;
    margin-left: 15px;
  }
  h5 {
    color: ${COLORS.$darkGray};
    text-align: justify;
    margin-top: 12px;
    font-size: 14px;
    font-weight: normal;
  }
`;
