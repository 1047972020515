export function setSelectedContract(contract) {
  return {
    type: "@session/SET_SELECTED_CONTRACT",
    payload: { contract },
  };
}

export function setSites(sites) {
  return {
    type: "@session/SET_SITES",
    payload: { sites },
  };
}

export function setUser(user) {
  return {
    type: "@session/SET_USER",
    payload: { user },
  };
}

export function rebuildSession() {
  return {
    type: "@session/REBUILD_SESSION",
    payload: {},
  };
}
