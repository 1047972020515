import React from "react";
import { Link } from "react-router-dom";

import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import history from "../../../services/history";

import {
  Container,
  Content,
  DetailsBox,
  Header,
  ResumeBox,
  InfoBox,
  Total,
  Actions,
} from "./style";
import Table from "./table";

export default function Order({ order }) {
  const total = order.formattedTotal.substring(2);
  return (
    <Container>
      <Content>
        <DetailsBox>
          <Header>
            <h3>{order.orderNo}</h3>
            <span>{order.orderDate}</span>
          </Header>
        </DetailsBox>
        <ResumeBox>
          <Table order={order} />
          <div>
            <InfoBox>
              <div>
                <span>Selo</span>
                <span>{order.formattedTotalStamps}</span>
              </div>
              <div>
                <span>Entrega</span>
                <span>{order.formattedTotalDelivery}</span>
              </div>
            </InfoBox>
            <Total>
              <span>Total do pedido</span>
              <h3>
                R$<b>{total}</b>
              </h3>
            </Total>
          </div>
          <Actions>
            <span onClick={() => history.push(`/extract/${order.orderNo}`)}>
              <MonetizationOnOutlinedIcon />
              Ver extrato
            </span>
            <a href={order.linkNf} target="_blank" rel="noopener noreferrer">
              <LocalAtmOutlinedIcon />
              Baixar Nf
            </a>
            {order.invoiceId && order.company ? (
              <Link
                to={`/boleto?invoiceId=${order.invoiceId}&company=${order.company}`}
              >
                <ReceiptOutlinedIcon />
                Baixar Boleto
              </Link>
            ) : (
              <span>
                <ReceiptOutlinedIcon />
                Não há boleto
              </span>
            )}
          </Actions>
        </ResumeBox>
      </Content>
    </Container>
  );
}
