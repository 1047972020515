import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";
import DefaultButton from "../../defaultComponents/Button";
import DefaultTitle from "../../defaultComponents/Title";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  color: #565656;
  background: #fff;
  padding: 0;
  border-radius: 15px;

  .box {
    @media only screen and (max-width: 860px) {
      display: flex;
      flex-direction: column;
      margin: 15px 20px 0 20px;
      > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      }
    }
  }

  .marginTop {
    margin-top: 30px;
  }

  span {
    font-size: 12px;
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="password"] {
    border: 1px solid #bababa;
    height: 35px;
    border-radius: 30px;
    padding-left: 15px;
    width: 100%;
    max-width: 600px;
    @media only screen and (max-width: 860px) {
      max-width: 100%;
    }
  }

  input[type="button"] {
    font-size: 13px;
    width: fit-content;
    margin-top: 35px;
    height: 40px;
    transition: background-color 1s ease;
    &:disabled {
      background-color: #d6d6d6;
    }
  }
`;

export const ProtectedBox = styled.div`
  display: grid;
  margin: 15px 50px 0 50px;
  grid-column-gap: 20px;
  grid-template-columns: 4fr 4fr 2fr 2fr 3fr;

  input:disabled {
    background: ${COLORS.$baseBackground};
  }

  @media only screen and (max-width: 1350px) {
    grid-template-columns: 2fr 3fr 2fr 3fr;
  }
`;

export const AllowedBox = styled.div`
  display: grid;
  margin: 5px 50px 0 50px;
  grid-column-gap: 20px;
  grid-template-columns: 3fr 2fr 2fr 3fr 2fr;
  @media only screen and (max-width: 1350px) {
    grid-template-columns: 4fr 3fr 3fr;
  }
`;

export const PasswordBox = styled.div`
  display: grid;
  margin: 15px 50px 0 50px;
  grid-column-gap: 20px;
  grid-template-columns: 2fr 2fr 3fr 3fr 2fr;
  @media only screen and (max-width: 1350px) {
    grid-template-columns: 4fr 4fr 1fr 1fr;
  }
`;

export const SubTitle = styled.h3`
  font-size: 22px;
  font-weight: 500;
  color: ${COLORS.$darkBlue};
  margin-top: 40px;
  margin-left: 50px;

  @media only screen and (max-width: 860px) {
    margin-top: 20px;
    margin-left: 20px;
  }
`;

export const LineSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.$gray.$borderLine};
  margin: 60px 0 20px 0;
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-bottom: 30px;
  @media only screen and (max-width: 860px) {
    padding-right: 20px;
    padding-left: 20px;
    .actionButton {
      flex: 1;
    }
  }
`;

export const Button = styled(DefaultButton)`
  height: 38px;
  margin-top: 35px;
  font-size: 14px;
`;

export const Warn = styled(DefaultTitle)`
  color: ${COLORS.$darkBlue};
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 6px;
  margin-left: 15px;
`;
