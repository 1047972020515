import { combineReducers } from "redux";

import auth from "./auth/reducer";
import session from "./session/reducer";
import cart from "./cart/reducer";
import cartValidate from "./cartValidate/reducer";
import header from "./header/reducer";
import modalSuccessBought from "./modalSuccessBought/reducer";
import extract from "./extract/reducer";
import modalWaitingBought from "./modalWaitingBought/reducer";
import notification from "./notification/reducer";
import wallet from "./wallet/reducer";
import register from "./register/reducer";
import changePassword from "./changePassword/reducer";
import confirmEmail from "./confirmEmail/reducer";
import successModal from "./modalSuccess/reducer";
import modalTicketSendSms from "./modalTicketSendSms/reducer";

const reducers = combineReducers({
  auth,
  session,
  cart,
  cartValidate,
  header,
  modalSuccessBought,
  modalWaitingBought,
  extract,
  notification,
  wallet,
  register,
  changePassword,
  confirmEmail,
  successModal,
  modalTicketSendSms,
});

export default reducers;
