import React from "react";

import Contact from "../../components/Contact";
import { Container, Content, ContactBox } from "./style";
import Title from "../../components/defaultComponents/Title";

export default function ContactUs() {
  return (
    <Container>
      <Content>
        <Title>Fale Conosco</Title>
        <ContactBox>
          <Contact />
        </ContactBox>
      </Content>
    </Container>
  );
}
