import React from "react";

import { TableBox, Table } from "./style";

export default function OrderTable({ order }) {
  return (
    <TableBox>
      <Table>
        <thead>
          <tr>
            <th>Qnt</th>
            <th>Periodo</th>
            <th>Valor Unitário</th>
            <th>Total</th>
            <th>Tipo de Entrega</th>
            <th>Pagamento</th>
            <th>Comprador</th>
          </tr>
        </thead>
        <tbody>
          {order.stamps.map((item, key) => (
            <tr>
              <td>{item.quantity}</td>
              <td>{item.formattedHour}</td>
              <td>{item.formattedValue}</td>
              <td>{item.formattedTotal}</td>
              <td>{key === 0 && order.deliveryType}</td>
              <td>{key === 0 && order.paymentType}</td>
              <td>{key === 0 && order.buyer}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableBox>
  );
}
