import React, { useState, useEffect, useRef } from "react";
import api from "../../../services/api";
import { Container, Content, Box, Button, Header } from "./style";
import Title from "../../../components/defaultComponents/Title";
import Table from "../../../components/manageUsers/Table";
import LoadingTable from "../../../components/manageUsers/LoadingTable";
import history from "../../../services/history";
import { errorMessage } from "../../../util/toastNotification";

export default function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [boxWidth, setBoxWidth] = useState(1000);
  const boxRef = useRef(null);

  useEffect(() => {
    async function loadUsers() {
      setLoading(true);
      const { data } = await api.get("users");
      let dataUsers = data.users || [];
      dataUsers = dataUsers.map(user => {
        user.loading = false;
        return user;
      });
      setUsers(dataUsers);
      setLoading(false);
    }

    loadUsers();
  }, []);

  function handleCreateUser() {
    history.push("/users/add");
  }

  async function handleChangeUser(customerNo, newStatus) {
    const request = {
      customerNo,
      actived: newStatus,
    };
    setUsers(
      users.map(user => {
        if (user.customerNo === customerNo) {
          user.loading = true;
        }
        return user;
      })
    );

    let changed = false;
    try {
      const { data } = await api.put("users", request);
      if (data.changed) {
        changed = true;
      }
    } catch ({ response }) {
      let message = "Falha ao alterar status";
      if (response && response.data && response.data.error) {
        message = response.data.error;
      }
      errorMessage({ message });
    } finally {
      setUsers(
        users.map(user => {
          if (user.customerNo === customerNo) {
            user.loading = false;
            if (changed) {
              user.actived = newStatus;
            }
          }
          return user;
        })
      );
    }
  }

  useEffect(() => {
    setBoxWidth(boxRef.current ? boxRef.current.offsetWidth : 0);
  }, []);

  return (
    <Container>
      <Content>
        <Header>
          <Title>Gerenciar Usuários</Title>
          <Button onClick={handleCreateUser} value="Cadastrar novo usuário" />
        </Header>
        <Box ref={boxRef}>
          {loading ? (
            <LoadingTable width={boxWidth} />
          ) : (
            <Table users={users} handleChangeUser={handleChangeUser} />
          )}
        </Box>
      </Content>
    </Container>
  );
}
