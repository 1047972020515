import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import DefaultInfoModal from "../../../components/modals/DefaultInfoModal";

import { Wrapper, ContentModal } from "./style";

export default function DefaultLayout({ children }) {
  const [modalOpened, setModalOpened] = useState(
    () => !JSON.parse(localStorage.getItem("@NetSelos:already_seen") || "false")
  );

  useEffect(() => {
    localStorage.setItem("@NetSelos:already_seen", JSON.stringify(true));
  }, []);

  return (
    <Wrapper>
      {children}
      <DefaultInfoModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Reformulamos o portal de selos da Netpark!"
      >
        <ContentModal>
          <h3>Seja bem vindo ao novo Portal de Selos Netpark!</h3>
          <p>
            Confira as diversas novidades que implementamos para melhorar a sua
            experiência conosco:
          </p>
          <li>
            Novo Login Único Netpark: Estamos unificando as suas credenciais de
            acesso. Agora, utilizando o mesmo login e senha, você acessa o
            Portal de Selos e o Aplicativo Netpark.
          </li>
          <li>
            Visual modernizado: Criamos uma experiência visual completamente
            nova para você, mais dinâmica e informativa.
          </li>
          <li>
            Experiência de uso simplificada: Agora você conta com filtros,
            controle maior na criação de seu pedido e mais detalhes em seu
            extrato e compra.
          </li>
          <h5>
            Para usufruir do novo Portal, será necessário criar uma nova conta,
            diferente da que você utilizava no antigo Portal de Selos.
          </h5>
        </ContentModal>
      </DefaultInfoModal>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
