import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";

import { Container } from "./style";
import { Field, HtmlTooltip, InfoBox } from "../../../pre/List/style";

export default function Table({ data }) {
  return (
    <Container>
      <thead>
        <tr>
          <th>Data/Hora</th>
          <th>No Ticket</th>
          <th>Selos</th>
          <th>Observações</th>
          <th>Usuário</th>
          <th>R$</th>
        </tr>
      </thead>
      <tbody>
        {data.map(order => (
          <tr key={order.id}>
            <td>{order.time}</td>
            <td>{order.ticket}</td>

            {order.quantityStamps === 1 ? (
              <td>{order.types[0]}</td>
            ) : (
              <td>
                <Field>
                  {`${order.quantityStamps} selos`}
                  <HtmlTooltip
                    title={
                      <InfoBox>
                        <h4>Selos Utilizados</h4>
                        {order.stamps.map((stamp, key) => (
                          <span key={`${stamp}:${key}`}>{stamp}</span>
                        ))}
                      </InfoBox>
                    }
                    placement="right"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                </Field>
              </td>
            )}

            <td>{order.obs}</td>
            <td>{order.user}</td>
            <td>{order.total}</td>
          </tr>
        ))}
      </tbody>
    </Container>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      time: PropTypes.string,
      ticket: PropTypes.string,
      quantityStamps: PropTypes.number,
      obs: PropTypes.string,
      user: PropTypes.string,
      total: PropTypes.string,
    })
  ).isRequired,
};
