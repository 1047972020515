import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import formatString from "format-string-by-pattern";
import ReactLoading from "react-loading";
import { errorMessage, successMessage } from "../../../util/toastNotification";
import {
  Container,
  Content,
  Box,
  Form,
  MiniBox,
  InputBox,
  Button,
  Input,
} from "./style";
import Title from "../../../components/defaultComponents/Title";
import Card from "../../../components/CreditCard";
import numbersOnly from "../../../util/numbersOnly";
import api from "../../../services/api";
import history from "../../../services/history";

export default function CardForm() {
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [focused, setFocused] = useState("");
  const [validCardNumber, setValidCardNumber] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [monthTouched, setMonthTouched] = useState(false);
  const [yearTouched, setYearTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const { master } = useSelector(state => state.session.user);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validForm) {
      errorMessage({ message: "Confira os dados do cartão" });
      return;
    }
    setLoading(true);
    const request = {
      number: numbersOnly(number),
      name,
      month,
      year,
      cvv: cvc,
    };
    const { data } = await api.post("cards", request);
    if (data.inserted) {
      successMessage({ message: "Cartão cadastrado com sucesso" });
      history.push("/cards");
      setLoading(false);
      return;
    }
    setLoading(false);
    errorMessage({ message: "Falha ao inserir cartão de crédito" });
  }

  function callbackCard({ issuer }, isValid) {
    setValidCardNumber(isValid);
  }

  useEffect(() => {
    setExpiry(`${month || "XX"}/${year || "XX"}`);
  }, [month, year]);

  useEffect(() => {
    if (master === "FALSE" || master === false) {
      errorMessage({
        message: "Apenas usuários Master podem adicionar novos cartões",
      });
      history.push("/cards");
    }
  }, [master]);

  useEffect(() => {
    function validateForm() {
      if (!validCardNumber) {
        setValidForm(false);
        return;
      }

      if (name === "") {
        setValidForm(false);
        return;
      }

      if (month === "" || month > 12) {
        setValidForm(false);
        return;
      }

      if (year === "" || year < 20) {
        setValidForm(false);
        return;
      }

      if (cvc === "") {
        setValidForm(false);
        return;
      }

      setValidForm(true);
    }
    validateForm();
  }, [validCardNumber, name, month, year, cvc]);

  return (
    <Container>
      <Content>
        <Title>Cadastrar Novo Cartão de Crédito</Title>
        <Box>
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={callbackCard}
          />
          <Form onSubmit={handleSubmit}>
            <MiniBox>
              <InputBox span="3">
                <span>Número do cartão</span>
                <Input
                  type="text"
                  value={number}
                  onFocus={() => setFocused("number")}
                  onBlur={() => setFocused("")}
                  onChange={e =>
                    setNumber(
                      formatString(
                        "XXXX XXXX XXXX XXXX",
                        numbersOnly(e.target.value)
                      )
                    )
                  }
                />
              </InputBox>
              <InputBox span="3">
                <span>Nome impresso no cartão</span>
                <Input
                  onFocus={() => setFocused("name")}
                  onBlur={() => setFocused("")}
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </InputBox>
            </MiniBox>
            <MiniBox>
              <InputBox span="2">
                <span>Mês</span>
                <Input
                  error={monthTouched && (month > 12 || month < 1)}
                  onFocus={() => setFocused("expiry")}
                  onBlur={() => {
                    setMonthTouched(true);
                    setFocused("");
                  }}
                  type="text"
                  value={month}
                  onChange={e => setMonth(numbersOnly(e.target.value))}
                  maxLength="2"
                />
              </InputBox>
              <InputBox span="2">
                <span>Ano</span>
                <Input
                  error={yearTouched && year < 20}
                  onFocus={() => setFocused("expiry")}
                  onBlur={() => {
                    setYearTouched(true);
                    setFocused("");
                  }}
                  type="text"
                  value={year}
                  onChange={e => setYear(numbersOnly(e.target.value))}
                  maxLength="2"
                />
              </InputBox>
              <InputBox span="2">
                <span>Código de Segurança (CVV)</span>
                <Input
                  onFocus={() => setFocused("cvc")}
                  onBlur={() => setFocused("")}
                  type="text"
                  value={cvc}
                  onChange={e => setCvc(numbersOnly(e.target.value))}
                  maxLength="5"
                />
              </InputBox>
            </MiniBox>
            <span>
              Você pode alterar os dados do seu cartão a qualquer momento
              acessando o seu perfil
            </span>
            <input type="submit" />
          </Form>
          <Button
            onClick={handleSubmit}
            className="actionButton"
            disabled={validForm ? null : true}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={24} width={24} />
            ) : (
              "Salvar alterações"
            )}
          </Button>
        </Box>
      </Content>
    </Container>
  );
}
