import { takeLatest, call, put, all } from "redux-saga/effects";
import history from "../../../services/history";
import api from "../../../services/api";
import { checkTokenSuccess, checkTokenFailure } from "./actions";
import { successMessage, errorMessage } from "../../../util/toastNotification";

export function* checkTokenRequest({ payload }) {
  const { token } = payload;

  try {
    const { data } = yield call(api.get, `confirm-email?token=${token}`);
    yield put(checkTokenSuccess());
    successMessage({
      message: data.message
        ? data.message
        : "Email confirmado com sucesso. Por favor faça o seu login.",
    });
    history.push("/login");
  } catch ({ response: error }) {
    yield put(checkTokenFailure());
    errorMessage({
      message:
        error && error.data && error.data.message
          ? error.data.message
          : "Token inválido",
    });
    history.push("/login");
  }
}

export default all([
  takeLatest("@confirm_email/CHECK_TOKEN_REQUEST", checkTokenRequest),
]);
