import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-header.jpg";
import { Container, ContentModal } from "./style";
import DefaultInfoModal from "../modals/DefaultInfoModal";

export default function Footer() {
  const { selectedContract } = useSelector(state => state.session);
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <Container>
        <div className="left-side">
          <img src={logo} alt="Netpark" />
          <ul>
            <li>
              {selectedContract.name !== "" && selectedContract.name}
              {selectedContract.descriptionSite !== "" &&
                `Unidade: ${selectedContract.descriptionSite}`}
            </li>
            <li>{selectedContract.address ? selectedContract.address : ""}</li>
            <li>{selectedContract.city ? selectedContract.city : ""}</li>
          </ul>
        </div>
        <ul>
          <li>
            <a href="https://redenetpark.zendesk.com/hc/pt-br">Fale Conosco</a>
          </li>
          <li>
            <button onClick={() => setModalOpened(true)} type="button">
              Política de Troca e Validação de Selos
            </button>
          </li>
        </ul>
      </Container>
      <DefaultInfoModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Política de Troca e Validade dos Selos"
      >
        <ContentModal>
          <li>Validade dos selos de 1 ano.</li>
          <li>Após esse período não serão aceitas trocas.</li>
          <li>Não existe reembolso.</li>
          <li>Tempo de entrega: Se for PDF na hora.</li>
          <li>Se for escolhido selo adesivo: 3 dias uteis.</li>
          <li>Pedidos de selos em formato PDF não podem ser cancelados.</li>
        </ContentModal>
      </DefaultInfoModal>
    </>
  );
}
