import React from "react";
import PropTypes from "prop-types";
import { Container, Box, Select } from "./style";
import api from "../../../services/api";

const styles = {
  container: provided => ({
    ...provided,
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: "20px",
    transform: "translateY(-6px)",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    color: "#565656",
    fontSize: "14px",
  }),
  menu: provided => ({
    ...provided,
    marginTop: "0",
    zIndex: "10",
    border: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
    backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
    borderRadius: "4px",
    paddingTop: "0",
    paddingBottom: "0",
  }),
  valueContainer: provided => ({
    ...provided,
    border: "none",
    paddingLeft: "20px",
    borderRadius: "30px 0 0 30px",
    backgroundColor: "#fff",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    border: "none",
    borderRadius: "0 30px 30px 0",
    backgroundColor: "#fff",
    cursor: "pointer",
    opacity: 0,
  }),
  control: provided => ({
    ...provided,
    background: "#fff",
    borderRadius: "30px",
    border: "none",
    boxShadow: "none",
  }),
  singleValue: provided => ({
    ...provided,
    color: "#565656",
    fontSize: "14px",
  }),
};

export default function OrderFilterSelect({
  valueState,
  setValueState,
  className,
}) {
  const defaultAdditional = { page: 1 };

  async function getFilterOptions(filter) {
    let { data } = await api.get("sites");
    if (filter) {
      data = data.filter(
        item =>
          item.site.toString().includes(filter) ||
          item.siteName.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return data.map(site => ({
      value: site.site,
      label: `${site.site} - ${site.siteName}`,
    }));
  }

  async function loadOptions(e) {
    const options = await getFilterOptions(e);

    const hasMore = false;

    return {
      options,
      hasMore,
      additional: { page: 2 },
    };
  }

  return (
    <Box className={className}>
      <Container>
        <Select
          styles={styles}
          additional={defaultAdditional}
          value={valueState}
          loadOptions={e => loadOptions(e)}
          onChange={setValueState}
          debounceTimeout={400}
          placeholder="Filtrar por"
        />
      </Container>
    </Box>
  );
}

OrderFilterSelect.propTypes = {
  valueState: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  setValueState: PropTypes.func.isRequired,
  className: PropTypes.string,
};

OrderFilterSelect.defaultProps = {
  className: "",
};
