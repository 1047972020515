import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  changePasswordRequest,
  closeModal,
} from "../../../store/modules/changePassword/actions";
import { Modal, Content, Input, Button, LinksBox, Info } from "./style";
import {
  validateLowerIsPresent,
  validateNumberIsPresent,
  validateUpperIsPresent,
  validatePasswordLength,
} from "../../../pages/signUp/validate";

export default function RecoverPassword() {
  const dispatch = useDispatch();
  const [inputPassword, setInputPassword] = useState("");
  const [inputConfirmPassword, setInputConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { token, loading, opened } = useSelector(state => state.changePassword);

  function validatePassword() {
    if (!validateUpperIsPresent(inputPassword)) {
      return "Ao menos uma letra maiúscula é obrigatória";
    }
    if (!validateLowerIsPresent(inputPassword)) {
      return "Ao menos uma letra minúscula é obrigatória";
    }
    if (!validateNumberIsPresent(inputPassword)) {
      return "Ao menos um número é obrigatório";
    }
    if (!validatePasswordLength(inputPassword)) {
      return "Ao menos 6 caracteres são obrigatórios";
    }
    return "";
  }

  function handleChangePassword() {
    if (inputPassword !== inputConfirmPassword) {
      setErrorMessage("Confirmação de senha não confere");
      return;
    }
    const passwordErrors = validatePassword(inputPassword);
    if (passwordErrors !== "") {
      setErrorMessage(passwordErrors);
      return;
    }
    dispatch(changePasswordRequest(token, inputPassword));
  }

  useEffect(() => {
    if (!opened) {
      return;
    }
    setInputPassword("");
    setInputConfirmPassword("");
    setErrorMessage("");
  }, [opened]);

  function onClose() {
    dispatch(closeModal());
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick
      title="Recuperação de Senha"
      className="buttons"
      showCloseButton
    >
      {token && token !== "" ? (
        <>
          <Content>
            <span>Preencha sua nova senha e confirmação</span>

            <Input
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              label="Nova senha"
              value={inputPassword}
              onChange={e => setInputPassword(e.target.value)}
            />

            <Input
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              label="Confirmar nova senha"
              value={inputConfirmPassword}
              onChange={e => setInputConfirmPassword(e.target.value)}
            />

            {errorMessage && (
              <Info>
                <span>{errorMessage}</span>
              </Info>
            )}
          </Content>
          <LinksBox>
            <Button
              className="dark"
              type="button"
              onClick={handleChangePassword}
              loading={loading}
              value="Enviar"
            />
          </LinksBox>
        </>
      ) : (
        <span>Carregando ...</span>
      )}
    </Modal>
  );
}
