import produce from "immer";

const INITIAL_STATE = {
  opened: false,
  processed: true,
};

export default function modalSuccessBought(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@modal_bought/SET_MODAL_OPENED":
      return produce(state, draft => {
        draft.opened = action.payload.status;
        draft.processed = action.payload.processed;
      });
    default:
      return state;
  }
}
