import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 100%;
  max-width: 1280px;
  h2 {
    margin-bottom: 60px;
    margin-left: 35px;
  }
`;

export const ContactBox = styled.div`
  margin: 0 35px;
  justify-self: flex-start;
`;
