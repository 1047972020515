import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  height: 70px;
  @media only screen and (max-width: 1048px) {
    flex-direction: column;
    height: auto;
  }
  .hamburguerMenu {
    display: none;
  }

  @media only screen and (max-width: 860px) {
    .menuContainer {
      /*display: none;*/
      position: fixed;
      z-index: 1;
      height: 100%;
      width: 350px;
      max-width: 100%;
      right: 0;
      padding-top: 75px;
      padding-bottom: 80px;
      transform-origin: 0% 0%;
      transform: translate(200%, 0);
      background: linear-gradient(
          to bottom,
          rgba(30, 54, 121, 0.5) 15%,
          rgba(30, 54, 121, 1) 70%
        )
        no-repeat !important;
      transition: transform 0.5s cubic-bezier(1, 0.05, 0.2, 0.77);
    }

    .showItSelf {
      display: flex;
      transform: none;
      /*background-color: rgba(30, 54, 121, 0.6);*/
    }

    .hamburguerMenu {
      display: flex;
      background-color: #00205b;
      padding-right: 15px;
      z-index: 10;
    }
    flex-direction: row;
  }
`;

export const Logo = styled.div`
  color: blue;
  display: flex;
  justify-content: center;
  width: 250px;
  background-color: #00205b;
  z-index: 1;
  @media only screen and (max-width: 1048px) {
    width: 100%;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: #00205b;
  @media only screen and (max-width: 1048px) {
    width: 100%;
    flex: auto;
    height: 64px;
  }

  @media only screen and (max-width: 860px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    .hamburguerMenu {
      display: flex;
    }
  }
`;

export const Unidade = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
  color: white;
  span {
    opacity: 0.7;
    font-size: 14px;
    font-weight: 500;
  }
  strong {
    line-height: 1.2;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 860px) {
    align-self: flex-start;
  }
  ul {
    display: flex;
    align-items: baseline;
    @media only screen and (max-width: 860px) {
      flex-direction: column;
      li {
        margin-bottom: 25px;
      }
    }
    list-style: none;
    outline-style: none;
    .active {
      border-bottom: 2px solid #fff;
      a {
        opacity: 1;
      }
    }
    li {
      margin-left: 25px;
      a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        opacity: 0.7;
        transition: ease 0.2s;
        &:hover {
          opacity: 1;
          transition: ease 0.2s;
        }
      }
      &:first-child {
        margin-left: 0;
        @media only screen and (max-width: 860px) {
          margin-left: 25px;
        }
      }
    }
  }
`;

export const SelectUnit = styled.strong`
  cursor: pointer;
`;
