import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  margin-top: 40px;
  & + div {
    margin-top: 25px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding: 20px 0;
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;

  h3 {
    font-size: 32px;
    margin-right: 15px;
    font-weight: 500;
    color: #565656;
    line-height: 1;
  }
  span {
    font-size: 20px;
    color: #565656;
    line-height: 1.2;
  }
`;

export const TableBox = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #bababa;
  max-width: 780px;
  height: fit-content;
`;

export const Table = styled.table`
  width: 100%;

  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;

  thead {
    background: #eeeff0;
  }

  th {
    color: #565656;
    padding: 10px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
    border-radius: 2px;
    vertical-align: middle;
    display: table-cell;
  }
  tbody {
    tr {
      border-top: 1px solid #bababa;
    }
    td {
      color: #656565;
      text-align: right;
      padding: 10px;
      font-size: 12px;
      vertical-align: middle;
      border-radius: 2px;
      display: table-cell;
    }
  }

  tr {
    td:nth-child(1),
    th:nth-child(1) {
      width: 55px;
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 90px;
    }
    td:nth-child(3),
    th:nth-child(3) {
      width: 132px;
    }
    td:nth-child(4),
    th:nth-child(4) {
      width: 110px;
    }
    td:nth-child(5),
    th:nth-child(5) {
      width: 138px;
    }
    td:nth-child(6),
    th:nth-child(6) {
      width: 90px;
    }
  }
`;

export const ResumeBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 20px;
  > div {
    /*transform: translateY(43px);*/
  }
`;

export const InfoBox = styled.div`
  width: 226px;
  > div {
    display: flex;
    justify-content: space-between;
    color: #656565;
    font-size: 12px;
    & + div {
      border-top: 1.3px solid rgba(0, 0, 0, 0.3);
      margin-top: 6px;
      padding-top: 6px;
    }
  }
`;
export const Total = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: #707070;
  > span {
    text-transform: uppercase;
    color: ${COLORS.$darkBlue};
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
  }

  > h3 {
    font-size: 16px;
    line-height: 1;
    margin-top: 3px;
    > b {
      font-size: 30px;
      margin-left: 6px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  a {
    margin-top: 8px;
    & + span {
      margin-top: 8px;
    }
  }
  > span,
  > a {
    text-transform: uppercase;
    color: #353535;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    > svg {
      margin-right: 6px;
    }
  }
`;
