import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactToPrint from "react-to-print";
import DaysSelect from "../../../components/defaultComponents/DaysFilterSelect";
import Title from "../../../components/defaultComponents/Title";
import OrderFilterSelect from "../../../components/defaultComponents/OrderFilterSelect";
import { savePdf } from "../../../util/pdf";
import api from "../../../services/api";

import { Container, Header, TitleBox, FilterBox, ActionBox } from "./style";
import { ActionButton } from "../Pre/style";
import ExtractList from "../../../components/extract/pos/List";

export default function Pos() {
  const dispatch = useDispatch();

  const { selectedContract } = useSelector(state => state.session);
  const { selectedUser, selectedDaysFilter } = useSelector(
    state => state.extract
  );

  const [loadingExtract, setLoadingExtract] = useState(false);

  const [page, setPage] = useState(1);
  const [extract, setExtract] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [filterDaysSelected, setFilterDaysSelected] = useState({
    value: "60",
    label: "Ultimos 60 dias",
  });
  const [filterSelected, setFilterSelected] = useState({
    value: "",
    label: "Filtrar por",
  });
  const [inputFilter, setInputFilter] = useState("");
  const [filter, setFilter] = useState({ filterName: "", filterValue: "" });

  const extractRef = useRef(null);

  function handleFilter() {
    setFilter({
      filterName: filterSelected.value,
      filterValue: inputFilter,
    });
  }

  function handleInputEnterClick(e) {
    if (e.which === 13) {
      e.preventDefault();
      handleFilter();
    }
  }

  const loadExtract = useCallback(async function load(
    pageDesired,
    userId,
    days
  ) {
    setLoadingExtract(true);
    let url = `extract?page=${pageDesired}`;
    if (userId) {
      url += `&customerNo=${userId}`;
    }
    url += `&days=${days || 60}`;
    const { data } = await api.get(url);
    setLoadingExtract(false);
    if (data && data.extract) {
      return data.extract;
    }

    return [];
  },
  []);

  async function loadMore() {
    const newItems = await loadExtract(
      page + 1,
      selectedUser.value > -1 ? selectedUser.value : false,
      selectedDaysFilter.value
    );
    setPage(page + 1);
    setExtract([...extract, ...newItems]);
    if (!newItems || newItems.length === 0) {
      setHasMore(false);
    }
  }

  useEffect(() => {
    if (!selectedContract || !selectedContract.contract) {
      return;
    }
    setExtract([]);

    async function initExtract() {
      const items = await loadExtract(
        1,
        selectedUser.value > -1 ? selectedUser.value : false,
        selectedDaysFilter.value
      );
      setExtract(items);
      setPage(1);
      setHasMore(true);
    }

    initExtract();
  }, [
    loadExtract,
    selectedContract,
    selectedDaysFilter.value,
    selectedUser.value,
  ]);

  return (
    <Container>
      <Header>
        <TitleBox>
          <Title>Fatura</Title>
          <DaysSelect
            valueState={filterDaysSelected}
            setValueState={e => setFilterDaysSelected(e)}
            className="select"
          />
        </TitleBox>
        <FilterBox>
          <span>Busca por ordem de venda: </span>
          <div>
            <input
              type="text"
              placeholder="Digite aqui"
              value={inputFilter}
              onChange={e => setInputFilter(e.target.value)}
              onKeyDown={handleInputEnterClick}
            />
            <input type="button" value="Ok" onClick={handleFilter} />
          </div>
        </FilterBox>
        <ActionBox>
          <ReactToPrint
            trigger={() => (
              <ActionButton>
                <i className="fas fa-print" /> Imprimir
              </ActionButton>
            )}
            content={() => extractRef.current}
          />
          <ActionButton
            onClick={() => {
              savePdf(extractRef.current);
            }}
          >
            <i className="far fa-file" />
            Salvar pdf
          </ActionButton>
        </ActionBox>
      </Header>
      <ExtractList loading={loadingExtract} extract={extract} />
    </Container>
  );
}
