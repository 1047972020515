import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { MiniBox } from "./style";
import { reload } from "../../store/modules/wallet/actions";
import api from "../../services/api";

export default function Wallet({ className }) {
    const dispatch = useDispatch();
    const { selectedContract } = useSelector(state => state.session);
    const { selectedUser, selectedDaysFilter } = useSelector(
        state => state.extract
    );
    const { wallet, loading } = useSelector(state => state.wallet);

    useEffect(() => {
        if (!selectedContract || !selectedContract.contract) {
            return;
        }
        dispatch(reload());
    }, [
        dispatch,
        selectedContract,
        selectedDaysFilter.value,
        selectedUser.value,
    ]);

    return (
        <>
            {loading ? (
                <ReactLoading type="spin" color="blue" className={className} />
            ) : (
                    <>
                        {wallet.map(item => (
                            <MiniBox
                                key={item.stampId}
                                className={className}
                                title={`${item.hourValue} ${
                                    item.hourValue > 1 ? "horas" : "hora"
                                    }`}
                                content={`${item.quantity}`}
                                description={`${item.stampId.substring(0, 3) === "SCO" ? "cortesia" : "selos"}`}
                            />
                        ))}
                    </>
                )}
        </>
    );
}

Wallet.propTypes = {
    className: PropTypes.string,
};

Wallet.defaultProps = {
    className: "",
};
