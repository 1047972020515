import React from "react";

import { Container } from "./style";

export default function hamburguer({ active, setActive, className }) {
  return (
    <Container className={className}>
      <label>
        <input
          type="checkbox"
          checked={active}
          onChange={e => setActive(e.target.checked)}
        />
        <span />
        <span />
        <span />
      </label>
    </Container>
  );
}
