import { takeLatest, call, put, all } from "redux-saga/effects";
import api from "../../../services/api";
import { checkoutSuccess, checkoutFailure } from "./actions";
import { successMessage, errorMessage } from "../../../util/toastNotification";

export function* checkout({ payload }) {
  const { order } = payload;
  const { payment, taxId, stamps, text } = order;

  try {
    const { data } = yield call(api.post, "order", {
      payment,
      taxId,
      stamps,
      text,
    });
    const { message } = data;

    if (message) {
      // successMessage({ message });
    }

    yield put(checkoutSuccess());
  } catch ({ response }) {
    errorMessage({
      message: response.data.error || "Falha ao finalizar pedido",
    });
    yield put(checkoutFailure());
  }
}

export default all([takeLatest("@cart/CHECKOUT_REQUEST", checkout)]);
