export function setWallet(wallet) {
  return {
    type: "@wallet/SET_WALLET",
    payload: { wallet },
  };
}

export function reload() {
  return {
    type: "@wallet/RELOAD",
    payload: {},
  };
}
