/*


*/

import React from "react";
import ContentLoader from "react-content-loader";

export default function Loading() {
  return (
    <ContentLoader
      speed={2}
      width={1320}
      height={330}
      viewBox="0 0 1320 330"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="8" ry="8" width="124" height="32" />
      <rect x="140" y="23" rx="8" ry="8" width="126" height="24" />
      <rect x="0" y="61" rx="8" ry="8" width="734" height="60" />
      <rect x="781" y="61" rx="8" ry="8" width="226" height="49" />
      <rect x="781" y="127" rx="8" ry="8" width="226" height="49" />
      <rect x="1032" y="61" rx="8" ry="8" width="142" height="24" />
      <rect x="1032" y="92" rx="8" ry="8" width="142" height="24" />
      <rect x="1032" y="123" rx="8" ry="8" width="142" height="24" />
    </ContentLoader>
  );
}
