import SignIn from "../pages/signIn";
import SignUp from "../pages/signUp";
import Recover from "../pages/recoverPassword";
import ConfirmEmail from "../pages/confirmEmail";

export default [
  { path: "/login", component: SignIn, isPrivate: false },
  { path: "/register", component: SignUp, isPrivate: false },
  { path: "/recover-password", component: Recover, special: true },
  { path: "/confirmation", component: ConfirmEmail, special: true },
];
