import produce from "immer";

const INITIAL_STATE = {
  token: null,
  loading: false,
  opened: false,
};

export default function changePassword(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@change_password/CHECK_TOKEN_SUCCESS":
      return produce(state, draft => {
        draft.token = action.payload.token;
        draft.loading = false;
        draft.opened = true;
      });
    case "@change_password/CHANGE_PASSWORD_REQUEST":
      return produce(state, draft => {
        draft.loading = true;
      });
    case "@change_password/CHANGE_PASSWORD_SUCCESS":
      return INITIAL_STATE;
    case "@change_password/CHANGE_PASSWORD_FAILURE":
      return produce(state, draft => {
        draft.loading = false;
      });
    case "@change_password/CLOSE_MODAL":
      return INITIAL_STATE;
    default:
      return state;
  }
}
