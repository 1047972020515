import React from "react";
import ContentLoader from "react-content-loader";

import { Container } from "../List/style";

export default function LoadingStamps() {
  return (
    <ContentLoader
      height={330}
      width={1100}
      speed={2}
      primaryColor="#b2b2b2"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="182" height="274" />
      <rect x="220" y="0" rx="10" ry="10" width="182" height="274" />
      <rect x="440" y="0" rx="10" ry="10" width="182" height="274" />
      <rect x="660" y="0" rx="10" ry="10" width="182" height="274" />
      <rect x="880" y="0" rx="10" ry="10" width="182" height="274" />
    </ContentLoader>
  );
}
