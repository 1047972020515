import produce from "immer";

const INITIAL_STATE = {
  token: null,
  loading: false,
  opened: false,
};

export default function confirmEmail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@confirm_email/CHECK_TOKEN_SUCCESS":
      return produce(state, draft => {
        draft.token = action.payload.token;
        draft.loading = false;
        draft.opened = true;
      });
    case "@confirm_email/CHECK_TOKEN_FAILURE":
      return produce(state, draft => {
        draft.token = null;
        draft.loading = false;
        draft.opened = false;
      });
    default:
      return state;
  }
}
