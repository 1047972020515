import produce from "immer";

const INITIAL_STATE = {
  hasUnread: false,
  notifications: [],
};

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@notification/SET_NOTIFICATIONS":
      return produce(state, draft => {
        draft.hasUnread = action.payload.hasUnread;
        draft.notifications = action.payload.notifications;
      });
    default:
      return state;
  }
}
