import styled from "styled-components";

export default styled.input`
  font-size: 14px;
  border-radius: 20px;
  padding-left: 10px;
  height: 34px;
  width: 100%;
  border: 0;
`;
