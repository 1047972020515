export function checkTokenRequest(token) {
  return {
    type: "@change_password/CHECK_TOKEN_REQUEST",
    payload: { token },
  };
}

export function checkTokenSuccess(token) {
  return {
    type: "@change_password/CHECK_TOKEN_SUCCESS",
    payload: { token },
  };
}

export function changePasswordRequest(token, password) {
  return {
    type: "@change_password/CHANGE_PASSWORD_REQUEST",
    payload: { token, password },
  };
}

export function changePasswordSucess() {
  return {
    type: "@change_password/CHANGE_PASSWORD_SUCCESS",
  };
}

export function changePasswordFailure() {
  return {
    type: "@change_password/CHANGE_PASSWORD_FAILURE",
  };
}

export function closeModal() {
  return {
    type: "@change_password/CLOSE_MODAL",
  };
}
