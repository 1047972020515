import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  color: #565656;
  background: #fff;
  padding: 20px;
  border-radius: 15px;

  .marginTop {
    margin-top: 30px;
  }

  span {
    font-size: 12px;
    margin-bottom: 5px;
  }

  input[type="text"] {
    border: 1px solid #bababa;
    height: 35px;
    border-radius: 30px;
    padding-left: 15px;
    width: 100%;
    max-width: 600px;
  }

  textarea {
    border: 1px solid #bababa;
    border-radius: 20px;
    padding: 15px;
    width: 100%;
    max-width: 600px;
  }
`;

export const Button = styled.button`
  background-color: #00205b;
  color: #fff;
  font-size: 13px;
  height: 40px;
  width: 195px;
  border-radius: 5px;
  border: 0;
  padding: 0 30px;
  margin-top: 35px;
  cursor: ${props => (props.loading ? "not-allowed" : "pointer")};
  opacity: ${props => (props.loading ? 0.8 : 1)};
  transition: background-color 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: not-allowed;
    background-color: #d6d6d6;
  }
`;
