import React, { useState, useEffect } from "react";
import formatString from "format-string-by-pattern";
import { Modal, Content, Input, Button, LinksBox } from "./style";
import api from "../../../services/api";
import { errorMessage, successMessage } from "../../../util/toastNotification";
import numbersOnly from "../../../util/numbersOnly";

export default function RecoverPasswordTokenRequest({ opened, onClose }) {
  const [cpf, setCpf] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleRequestEmail() {
    setLoading(true);
    try {
      const { data } = await api.get(`send-recover-password-token?cpf=${cpf}`);
      const { sent, email } = data;
      if (!sent) {
        errorMessage({ message: "Cpf não cadastrado" });
        return;
      }
      successMessage({
        message: `Link de recuperação de senha enviado ao email ${email}`,
      });
    } catch (e) {
      errorMessage({
        message: "Falha ao solicitar email de recuperação de senha.",
      });
    } finally {
      setLoading(false);
      onClose();
    }
  }

  useEffect(() => {
    if (!opened) {
      return;
    }
    setCpf("");
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick
      title="Recuperação de Senha"
      className="buttons"
      showCloseButton
    >
      <Content>
        <span>Informe seu cpf para recuperação de senha</span>

        <Input
          name="cpf"
          type="text"
          label="Cpf"
          required
          value={formatString("XXX.XXX.XXX-XX", cpf)}
          onChange={e => setCpf(numbersOnly(e.target.value))}
          maxLength={14}
        />
      </Content>
      <LinksBox>
        <Button
          className="dark"
          type="button"
          onClick={handleRequestEmail}
          loading={loading}
          value="Enviar"
        />
      </LinksBox>
    </Modal>
  );
}
