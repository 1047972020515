import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { LinksBox, Modal } from "./style";
import Button from "../../defaultComponents/Button";

export default function SuccessBought({
  opened,
  onClose,
  closeOnDocumentClick,
}) {
  const { processed } = useSelector(state => state.modalSuccessBought);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      {processed ? (
        <i className="far fa-check-circle" />
      ) : (
        <i className="far fa-times-circle redColor" />
      )}

      <h2>{`${
        processed
          ? "Compra realizada com sucesso!"
          : "Falha ao processar compra"
      }`}</h2>
      <p>
        No prazo máximo de 24 horas você receberá no e-mail cadastrado a
        confirmação com os detalhes do seu pedido.
      </p>
      <ul>
        <li>
          Certifique-se de verificar sua caixa de spam ou seu lixo eletrônico.
        </li>
        <li>
          Qualquer dúvida entre em contato pelo e-mail: selos@netpark.com.br
        </li>
      </ul>
      <LinksBox>
        <Button className="dark" value="Ok" onClick={onClose} />
      </LinksBox>
    </Modal>
  );
}

SuccessBought.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
};

SuccessBought.defaultProps = {
  closeOnDocumentClick: true,
};
