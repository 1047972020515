import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { LinksBox } from "../DefaultModal/style";
import Button from "../../defaultComponents/Button";
import { Modal, CardBox, List } from "./style";
import Card from "../../CreditCard";
import api from "../../../services/api";
import numbersOnly from "../../../util/numbersOnly";
import { errorMessage } from "../../../util/toastNotification";
import history from "../../../services/history";

export default function SelectCard({
  opened,
  onClose,
  closeOnDocumentClick,
  onSelectCard,
}) {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [modeAdd, setModeAdd] = useState(false);
  const { master } = useSelector(state => state.session.user);

  useEffect(() => {
    async function loadCards() {
      const { data } = await api.get("cards");
      if (data.cards) {
        const result = data.cards.map(card => ({
          ...card,
          lastFourDigits: numbersOnly(card.cardNumber),
          expiry: `${card.month.toString().padStart(2, "0")}/${card.year}`,
        }));

        setCards(result);
      }
    }
    setModeAdd(false);
    if (opened) {
      loadCards();
    }
  }, [opened]);

  async function handleSelectCard() {
    if (!selectedCard.token) {
      errorMessage({ message: "Selecione ou adicione um cartão de crédito" });
      return;
    }
    const { token, lastFourDigits, brand } = selectedCard;
    onSelectCard({ token, lastFourDigits, brand });
  }

  async function handleAddCard() {
    if (master === "FALSE" || master === false) {
      errorMessage({
        message: "Apenas usuários Master podem adicionar novos cartões",
      });
      return;
    }

    history.push("cards/new");
    onClose();
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <h2>Seus cartões</h2>
      {modeAdd ? (
        <h2>teste</h2>
      ) : (
        <List>
          {cards &&
            cards.map(card => (
              <CardBox
                key={card.token}
                onClick={() => setSelectedCard(card)}
                selected={card.token === selectedCard.token}
              >
                <Card
                  lastNumbers={card.lastFourDigits}
                  name={card.name}
                  expiry={card.expiry}
                  brand={card.brand}
                />
              </CardBox>
            ))}
        </List>
      )}

      <LinksBox>
        <Button
          className="light"
          value="Adicionar cartão"
          onClick={handleAddCard}
        />
        <Button
          className="dark"
          value="Selecionar"
          onClick={handleSelectCard}
        />
      </LinksBox>
    </Modal>
  );
}

SelectCard.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
  onSelectCard: PropTypes.func.isRequired,
};

SelectCard.defaultProps = {
  closeOnDocumentClick: true,
};
