import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ArrowDropDown } from "@material-ui/icons";
import SelectSitePopup from "../modals/SelectSite";
import {
  Container,
  Logo,
  MenuContainer,
  Unidade,
  Nav,
  SelectUnit,
} from "./style";
import Hamburguer from "./hamburguer";
import logo from "../../assets/images/logo-header.jpg";
import Menu from "./menu";
import SuccessBoughtPopup from "../modals/SuccessBought";
import { setModalOpened as setModalSuccessBoughtOpened } from "../../store/modules/modalSuccessBought/actions";

export default function Header() {
  const dispatch = useDispatch();
  const { selectedContract, sites, user } = useSelector(state => state.session);
  const { selectedPage } = useSelector(state => state.header);
  const [selectSiteModalOpened, setSelectSiteModalOpened] = useState(false);
  const [menuActived, setMenuActived] = useState(false);
  const modalSuccessBoughtOpened = useSelector(
    state => state.modalSuccessBought.opened
  );

  function closeSuccessModal() {
    dispatch(setModalSuccessBoughtOpened(false));
  }

  function closeSelectSiteModal() {
    setSelectSiteModalOpened(false);
  }

  function openSelectSiteModal() {
    setSelectSiteModalOpened(true);
  }

  return (
    <>
      <SuccessBoughtPopup
        opened={modalSuccessBoughtOpened}
        onClose={closeSuccessModal}
        closeOnDocumentClick
      />
      <Container>
        <Logo>
          <Link to="/">
            <img src={logo} alt="Netpark" />
          </Link>
        </Logo>
        <Hamburguer
          className="hamburguerMenu"
          active={menuActived}
          setActive={setMenuActived}
        />
        <MenuContainer
          className={menuActived ? "menuContainer showItSelf" : "menuContainer"}
        >
          <Unidade>
            <span>Unidade</span>
            <SelectUnit onClick={sites.length > 0 ? openSelectSiteModal : null}>
              {selectedContract.descriptionSite}
              {sites.length > 0 && <ArrowDropDown />}
            </SelectUnit>
          </Unidade>
          <Nav>
            <ul>
              {selectedContract.prePos === "PRE" && (
                <li className={selectedPage === "/" ? "active" : ""}>
                  <Link to="/">Comprar</Link>
                </li>
              )}
              <li className={selectedPage === "/validate" ? "active" : ""}>
                <Link to="/validate">Validar</Link>
              </li>
              <li className={selectedPage === "/orders" ? "active" : ""}>
                <Link to="/orders">Meus Pedidos</Link>
              </li>
              {selectedContract.prePos === "PRE" && (
                <li className={selectedPage === "/extract" ? "active" : ""}>
                  <Link to="/extract">Extrato</Link>
                </li>
              )}
              {selectedContract.prePos === "POS" && (
                <li className={selectedPage === "/extract" ? "active" : ""}>
                  <Link to="/extract">Fatura</Link>
                </li>
              )}
            </ul>
          </Nav>
          <Menu />
        </MenuContainer>
        <SelectSitePopup
          opened={selectSiteModalOpened}
          onClose={closeSelectSiteModal}
        />
      </Container>
    </>
  );
}
