import produce from "immer";

const INITIAL_STATE = {
  opened: false,
};

export default function modalWaitingBought(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@modal_waiting_bought/SET_MODAL_OPENED":
      return produce(state, draft => {
        draft.opened = action.payload.status;
      });
    case "@cart/CHECKOUT_SUCCESS":
      return produce(state, draft => {
        draft.opened = true;
      });
    default:
      return state;
  }
}
