import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  .select {
    height: 40px;
    margin-left: 15px;
    flex: 1;
  }

  .leftRadius {
    border-radius: 10px 0 0 10px;
    padding-left: 30px;
  }

  .rightRadius {
    border-radius: 0 10px 10px 0;
    padding-right: 30px;
  }

  .leftBox {
  }

  .middleBox {
    flex: 1;
    margin-left: 30px;
    > h2 {
      font-size: 20px;
    }

    > div {
      display: flex;
      width: 100%;
      background-color: ${COLORS.$baseWhiteBackground};
      padding: 18px 20px;
      border-radius: 10px 0 0 10px;
    }
  }

  .rightBox {
    > h2 {
      font-size: 20px;
      color: ${COLORS.$darkBlue};
      padding-left: 20px;
    }

    > div {
      display: flex;
      width: 100%;
      background-color: ${COLORS.$baseWhiteBackground};
      padding: 18px 20px;
      border-radius: 0 10px 10px 0;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;

  .boxSize {
    min-height: 80px;
    height: 80px;
  }
`;

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;

export const ActionButton = styled.span`
  display: flex;
  align-items: center;
  color: ${COLORS.$darkGray};
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 20px;
  cursor: pointer;
  i {
    margin-right: 6px;
    font-size: 16px;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #565656;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

export const MiniBox = styled.div`
  background-color: ${COLORS.$baseLightBackground};
  border-radius: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  h2 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
    color: ${COLORS.$darkBlue};
  }

  span {
    line-height: 1;
    font-size: 15px;
    font-weight: 400;
    color: ${COLORS.$darkBlue};
  }
`;

export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
  > button {
    height: 53px;
    width: 368px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #00205b;
    font-size: 22px;
    font-weight: bold;
    color: #00205b;
    border-radius: 5px;
    background: transparent;
    transition: ease 0.4s;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    &:hover {
      background: ${props => (props.disabled ? "transparent" : "#00205b")};
      color: ${props => (props.disabled ? "#00205b" : "#fff")};
      transition: ease 0.4s;
    }
  }
`;

export const WalletBox = styled.div`
  min-height: 116px;
`;

export const BoxOrder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: #fff;
  margin-top: 20px;
  border-radius: 5px;
  > span {
    font-size: 18px;
    color: ${COLORS.$darkBlue};
  }
`;

export const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  color: ${COLORS.$darkGray};
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
  transform: translateY(-1px);
  i {
    margin-right: 6px;
    font-size: 16px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
