import api from "../api";

export const isThereTermsToAccept = async () => {
  const { data } = await api.get("/terms/is-there-terms-to-accept");
  const { isThereTermsToAccept: response } = data;

  return response;
};

export const getTerms = async () => {
  const { data } = await api.get("/terms/get-terms");
  const { termsKey, uid, termsLink, active, message } = data;

  return {
    termsKey,
    uid,
    termsLink,
    active,
    message,
  };
};

export const acceptTerms = async ({ uid }) => {
  const { data } = await api.post("/terms/accept", { termId: uid });
  return data.accepted;
};

/* export default async cpf => {
  try {
    const { data } = await api.get(`get-user-info?cpf=${cpf}`);
    const { name, phone, email, isUser } = data;

    return { name, phone, email, isUser };
  } catch (e) {
    return {
      isUser: false,
    };
  }
};
*/
