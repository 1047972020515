import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  stamps: [],
  ticket: {},
  error: null,
  totalStampsMinutes: 0,
  hoursMinutesLeft: "0:00",
  minutesLeft: 0,
  processing: false,
};

export default function cartValidate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@cart_validate/ADD_TO_CART":
      return produce(state, draft => {
        const stampIndex = draft.stamps.findIndex(
          stamp => stamp.id === action.payload.stamp.id
        );

        if (stampIndex >= 0) {
          draft.stamps.splice(stampIndex, 1);
        }

        if (action.payload.stamp.quantity > 0) {
          draft.stamps.push(action.payload.stamp);
        }

        draft.totalStampsMinutes = draft.stamps.reduce(
          (total, stamp) =>
            total + stamp.quantity * (stamp.hourValue * 60 + stamp.minuteValue),
          0
        );

        const left = draft.ticket.totalMinutesCost - draft.totalStampsMinutes;
        draft.hoursMinutesLeft = "0:00";
        if (left > 0) {
          draft.hoursMinutesLeft = `${Math.floor(left / 60)}:${(left % 60)
            .toString()
            .padStart(2, "0")}`;
        }
        if (left < 0) {
          const tmp = draft.totalStampsMinutes - draft.ticket.totalMinutesCost;
          draft.hoursMinutesLeft = `${Math.floor(tmp / 60)}:${(tmp % 60)
            .toString()
            .padStart(2, "0")}`;
        }
        draft.minutesLeft = left;

        draft.loading = false;
        draft.error = null;
      });
    case "@cart_validate/SET_TICKET":
      return produce(state, draft => {
        draft.ticket = action.payload.ticket;
        draft.stamps = [];
        draft.totalStampsMinutes = 0;
        draft.hoursMinutesLeft = `${Math.floor(
          draft.ticket.totalMinutesCost / 60
        )}:${(draft.ticket.totalMinutesCost % 60).toString().padStart(2, "0")}`;
      });
    case "@cart_validate/TICKET_PROCESSED":
      return produce(state, draft => {
        draft.processing = false;
      });
    case "@cart_validate/PROCESSING_TICKET":
      return produce(state, draft => {
        draft.processing = true;
      });
    case "@cart_validate/CLEAR":
      return INITIAL_STATE;
    case "@auth/SIGN_OUT":
      return INITIAL_STATE;

    /*
      case "@cart/CHECKOUT_REQUEST":
      return produce(state, draft => {
        draft.loading = true;
      });
    case "@cart/CHECKOUT_SUCCESS":
      return INITIAL_STATE;
    case "@cart/CHECKOUT_FAILURE":
      return produce(state, draft => {
        draft.loading = false;
      });
      */
    default:
      return state;
  }
}
