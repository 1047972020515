import styled from "styled-components";

export const Container = styled.div`
  background: transparent;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  margin-top: 20px;
  margin-bottom: 25px;

  .selected {
    border-radius: 10px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
    transition: ease 0.4s;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    /*grid-gap: 10px;*/
  }

  @media only screen and (max-width: 910px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    /*grid-gap: 10px;*/
  }

  @media only screen and (max-width: 760px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 0;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ContentLoading = styled.div`
  /*background: transparent;*/
  padding-top: 25px;
  height: 330px;
  width: 100%;
  > svg {
    height: 330px;
    max-width: 1200px;
  }
`;
