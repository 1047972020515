import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import session from "./session/sagas";
import cart from "./cart/sagas";
import notification from "./notification/sagas";
import wallet from "./wallet/sagas";
import register from "./register/sagas";
import changePassword from "./changePassword/sagas";
import confirmEmail from "./confirmEmail/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    session,
    cart,
    notification,
    wallet,
    register,
    changePassword,
    confirmEmail,
  ]);
}
