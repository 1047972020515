import styled from "styled-components";

export const Container = styled.div`
  background: transparent;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 25px;
  flex-wrap: wrap;

  .selected {
    border-radius: 10px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
    transition: ease 0.4s;
  }

  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;
