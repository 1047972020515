import { takeLatest, call, put, all } from "redux-saga/effects";
import history from "../../../services/history";
import api from "../../../services/api";
import { signUpCheckSuccess, signUpFailure, signUpSuccess } from "./actions";
import { errorMessage, successMessage } from "../../../util/toastNotification";
import build from "./build";
import { validateForm } from "../../../pages/signUp/validate";

/* export function* signIn({ payload }) {
  const { login, password } = payload;

  try {
    const { data } = yield call(api.post, "sessions", {
      login,
      password,
    });
    const { token, user } = data;

    if (!token) {
      errorMessage({ message: "Falha ao fazer o login" });
      return;
    }

    api.defaults.headers.Authorization = `bearer ${token}`;
    yield put(signInSuccess(token, user));
    successMessage({ message: "Login realizado com sucesso" });

    const selectedContract = user.sites.find(
      item => item.contract === user.mainContract
    );
    if (selectedContract && selectedContract.prePos === "POS") {
      history.push("/validate");
    } else {
      history.push("/");
    }
  } catch ({ response }) {
    yield put(signFailure());
    errorMessage({
      message: "Falha ao fazer o login",
    });
  }
} */

export function* signUpCheck({ payload }) {
  const { cpfOrCnpj } = payload;

  try {
    const { data } = yield call(api.get, `user?cpfOrCnpj=${cpfOrCnpj}`);
    const { user } = data;

    yield put(signUpCheckSuccess(build(user)));
    history.push("/register");
  } catch (e) {
    yield put(signUpFailure());
    errorMessage({ message: "Falha ao acessar registro" });
  }
}

export function* signUpRequest({ payload }) {
  const { user } = payload;

  const tmpErrors = validateForm(user);
  if (tmpErrors !== "") {
    errorMessage({
      message: "Revise os dados preenchidos e tente novamente",
    });
    yield put(signUpFailure());
    return;
  }

  try {
    yield call(api.post, "profile", user);
    // successMessage({ message: "Usuario criado com sucesso." });
    yield put(signUpSuccess());
    history.push("/");
  } catch ({ response }) {
    yield put(signUpFailure());
    errorMessage({
      message:
        response && response.data && response.data.error
          ? response.data.error
          : "Falha ao criar usuario",
    });
  }
}

export default all([
  takeLatest("@register/SIGN_UP_CHECK_REQUEST", signUpCheck),
  takeLatest("@register/SIGN_UP_REQUEST", signUpRequest),
]);
