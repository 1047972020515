import produce from "immer";

const INITIAL_STATE = {
  opened: false,
  title: "",
  text: "",
};

export default function modalSuccess(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@success_modal/SHOW_MODAL":
      return produce(state, draft => {
        draft.opened = true;
        draft.title = action.payload.title;
        draft.text = action.payload.text;
      });
    case "@success_modal/CLOSE_MODAL":
      return INITIAL_STATE;
    default:
      return state;
  }
}
