import React from "react";
import ContentLoader from "react-content-loader";

export default function LoadingStamps() {
  return (
    <ContentLoader
      height={330}
      width={1200}
      speed={2}
      primaryColor="#b2b2b2"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="290" height="196" />
      <rect x="300" y="0" rx="10" ry="10" width="290" height="196" />
      <rect x="600" y="0" rx="10" ry="10" width="290" height="196" />
      <rect x="900" y="0" rx="10" ry="10" width="290" height="196" />
    </ContentLoader>
  );
}
