import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import formatString from "format-string-by-pattern";
import produce from "immer";
import ReactLoading from "react-loading";
import numbersOnly from "../../util/numbersOnly";
import { signUpRequest } from "../../store/modules/register/actions";
import getUserInfo from "../../services/user/getUserInfo";
import Site from "../../components/defaultComponents/SitesSelect";

import {
  validate,
  validateNumberIsPresent,
  validateLowerIsPresent,
  validateUpperIsPresent,
  validatePasswordLength,
  validateConfirmPassword,
} from "./validate";

import {
  Container,
  Content,
  Title,
  Box,
  InputBox,
  Input,
  Error,
  Label,
  LineSeparator,
  SmallText,
  SubTitle,
  Submit,
  StrongPassword,
  Warn,
  SubmitContent,
} from "./style";

const WARN_PASSWORD_TEXT = `Encontramos seu cadastro do Login Netpark e usaremos esses mesmos
dados para seu usuário do portal de selos. Sua senha será a mesma
que você cadastrou no Aplicativo Netpark. Caso não se lembre de sua
senha, basta clicar na opção "Esqueci minha senha", na tela de
login.`;

const INITIAL_STATE = {
  cpf: "",
  cnpj: "",
  fantasy_name: "",
  name: "",
  phone: "",
  sponsor_name: "",
  email: "",
  cep: "",
  address: "",
  number: "",
  complement: "",
  district: "",
  city: "",
  province: "",
  password: "",
  password_confirmation: "",
};

const STRENGTH_TEXT = ["Fraca", "Fraca", "Fraca", "Média", "Forte"];
const STRENGTH_COLOR = ["red", "red", "red", "#FFA200", "green"];

export default function SignUp() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.register);
  const [form, setForm] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_STATE);
  const [passwordStrengthText, setPasswordStrengthText] = useState("");
  const [passwordStrengthColor, setPasswordStrengthColor] = useState("green");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { user } = useSelector(state => state.register);
  const [showCnpj, setShowCnpj] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [canEditEmail, setCanEditEmail] = useState(true);
  const [site, setSite] = useState({
    value: "",
    label: "Selecione o site",
  });

  useEffect(() => {
    if (!user || (!user.cpf && !user.cnpj)) {
      setIsRegisteredUser(false);
      return;
    }

    setShowCnpj(false);
    setForm({
      ...INITIAL_STATE,
      cpf: user.cpf || "",
      cnpj: user.cnpj || "",
      fantasy_name: user.fantasy_name || "",
      name: user.fantasy_name || "",
      email: user.email || "",
      phone: user.phone || "",
      cep: user.cep || "",
      address: user.address || "",
      number: user.number || "",
      city: user.city || "",
      district: user.district || "",
      province: user.province || "",
      is_user: user.is_user,
    });
    if (user.cnpj && user.cnpj !== "") {
      setShowCnpj(true);
    }

    setCanEditEmail(user.email === "");

    setIsRegisteredUser(user.is_user);
  }, [user]);

  function setValue(fieldName, value) {
    const blocked = ["fantasy_name", "cpf", "cnpj"];
    if (!canEditEmail) {
      blocked.push("email");
    }
    if (blocked.indexOf(fieldName) >= 0 && user[fieldName] !== "") {
      return;
    }
    setForm(
      produce(form, draft => {
        draft[fieldName] = value;
      })
    );
  }

  function setError(fieldName, error) {
    setErrors(
      produce(errors, draft => {
        draft[fieldName] = error;
      })
    );
  }

  function validateField(fieldName) {
    const value = form[fieldName];
    const error = validate(fieldName, value);
    setError(fieldName, error);
  }

  function validatePasswordConfirmation() {
    const error = validateConfirmPassword(
      form.password,
      form.password_confirmation
    );
    setError("password_confirmation", error);
  }

  async function handleSubmit() {
    dispatch(
      signUpRequest({
        ...form,
        site: site.value,
      })
    );
  }

  const handleBlurCpf = async cpf => {
    setInternalLoading(true);
    validateField("cpf");
    if (cpf && cpf.length === 11) {
      const { name, phone, email, isUser } = await getUserInfo(cpf);
      setForm(
        produce(form, draft => {
          draft.sponsor_name = name || "";
          draft.phone = phone || "";
          draft.email = email || "";
          draft.is_user = isUser;
        })
      );

      setCanEditEmail(email === "");

      setIsRegisteredUser(isUser);
      setInternalLoading(false);
    }
  };

  useEffect(() => {
    if (form.password === "") {
      setPasswordStrengthText("");
      setPasswordStrengthColor("green");
      setPasswordStrength(0);
      return;
    }
    let strength = 0;
    if (validateLowerIsPresent(form.password)) {
      strength += 1;
    }
    if (validateUpperIsPresent(form.password)) {
      strength += 1;
    }
    if (validatePasswordLength(form.password)) {
      strength += 1;
    }
    if (validateNumberIsPresent(form.password)) {
      strength += 1;
    }

    setPasswordStrengthText(STRENGTH_TEXT[strength]);
    setPasswordStrengthColor(STRENGTH_COLOR[strength]);
    setPasswordStrength(20 * strength);
  }, [form.password]);

  return (
    <Container>
      <Content>
        <Title>Cadastro</Title>
        {showCnpj && (
          <Box>
            <InputBox>
              <Label>CNPJ</Label>
              <Input
                type="text"
                name="cnpj"
                value={formatString("XX.XXX.XXX/XXXX-XX", form.cnpj)}
                onChange={e => setValue("cnpj", numbersOnly(e.target.value))}
                onBlur={() => validateField("cnpj")}
                error={errors.cnpj}
                maxLength="18"
              />
              {errors.cnpj !== "" && <Error>{errors.cnpj}</Error>}
            </InputBox>
          </Box>
        )}
        <Box>
          <InputBox>
            <Label>CPF</Label>
            <Input
              type="text"
              name="cpf"
              value={formatString("XXX.XXX.XXX-XX", form.cpf)}
              onChange={e => setValue("cpf", numbersOnly(e.target.value))}
              onBlur={() => handleBlurCpf(form.cpf)}
              error={errors.cpf}
              maxLength="14"
            />
            {errors.cpf !== "" && <Error>{errors.cpf}</Error>}
          </InputBox>
          <InputBox>
            <Label>Nome fantasia (opcional)</Label>
            <Input
              type="text"
              name="fantasy_name"
              value={form.fantasy_name}
              onChange={e => setValue("fantasy_name", e.target.value)}
              onBlur={() => validateField("fantasy_name")}
              error={errors.fantasy_name}
            />
            {errors.fantasy_name !== "" && <Error>{errors.fantasy_name}</Error>}
          </InputBox>
          <InputBox>
            <Label>{showCnpj ? "Nome da empresa" : "Nome completo"}</Label>
            <Input
              type="text"
              name="name"
              value={form.name}
              onChange={e => setValue("name", e.target.value)}
              onBlur={() => validateField("name")}
              error={errors.name}
            />
            {errors.name !== "" && <Error>{errors.name}</Error>}
          </InputBox>
        </Box>
        <Box>
          <InputBox>
            <Label>Telefone</Label>
            <Input
              type="text"
              name="phone"
              value={
                form.phone.length <= 10
                  ? formatString("(XX)XXXX-XXXX", form.phone)
                  : formatString("(XX)X XXXX-XXXX", form.phone)
              }
              onChange={e => setValue("phone", numbersOnly(e.target.value))}
              onBlur={() => validateField("phone")}
              error={errors.phone}
              maxLength="15"
            />
            {errors.phone !== "" && <Error>{errors.phone}</Error>}
          </InputBox>
          <InputBox>
            <Label>Nome do responsável pela compra</Label>
            <Input
              type="text"
              name="sponsor_name"
              value={form.sponsor_name}
              onChange={e => setValue("sponsor_name", e.target.value)}
              onBlur={() => validateField("sponsor_name")}
              error={errors.sponsor_name}
            />
            {errors.sponsor_name !== "" && <Error>{errors.sponsor_name}</Error>}
          </InputBox>
          <InputBox>
            <Label>E-mail</Label>
            <Input
              type="text"
              name="email"
              value={form.email}
              onChange={e => setValue("email", e.target.value)}
              onBlur={() => validateField("email")}
              error={errors.email}
            />
            {errors.email !== "" && <Error>{errors.email}</Error>}
          </InputBox>
        </Box>
        <Box>
          <div style={{ width: "100%" }}>
            <label
              style={{ fontSize: "12px", fontWeight: "400", color: "#00205b" }}
            >
              Site
            </label>
            <Site valueState={site} setValueState={e => setSite(e)} />
          </div>
        </Box>
        <Box>
          <InputBox>
            <Label>CEP</Label>
            <Input
              type="text"
              name="cep"
              value={formatString("XX.XXX-XXX", form.cep)}
              onChange={e => setValue("cep", numbersOnly(e.target.value))}
              onBlur={() => validateField("cep")}
              error={errors.cep}
              maxLength="10"
            />
            {errors.cep !== "" && <Error>{errors.cep}</Error>}
          </InputBox>
          <InputBox>
            <Label>Endereço de utilização dos selos</Label>
            <Input
              type="text"
              name="address"
              value={form.address}
              onChange={e => setValue("address", e.target.value)}
              onBlur={() => validateField("address")}
              error={errors.address}
            />
            {errors.address !== "" && <Error>{errors.address}</Error>}
          </InputBox>
          <InputBox>
            <Label>Número</Label>
            <Input
              type="text"
              name="number"
              value={form.number}
              onChange={e => setValue("number", e.target.value)}
              onBlur={() => validateField("number")}
              error={errors.number}
            />
            {errors.number !== "" && <Error>{errors.number}</Error>}
          </InputBox>
        </Box>
        <Box>
          <InputBox>
            <Label>Complemento (opcional)</Label>
            <Input
              type="text"
              name="complement"
              value={form.complement}
              onChange={e => setValue("complement", e.target.value)}
              onBlur={() => validateField("complement")}
              error={errors.complement}
            />
            {errors.complement !== "" && <Error>{errors.complement}</Error>}
          </InputBox>
          <InputBox>
            <Label>Bairro</Label>
            <Input
              type="text"
              name="district"
              value={form.district}
              onChange={e => setValue("district", e.target.value)}
              onBlur={() => validateField("district")}
              error={errors.district}
            />
            {errors.district !== "" && <Error>{errors.district}</Error>}
          </InputBox>
          <InputBox>
            <Label>Cidade</Label>
            <Input
              type="text"
              name="city"
              value={form.city}
              onChange={e => setValue("city", e.target.value)}
              onBlur={() => validateField("city")}
              error={errors.city}
            />
            {errors.city !== "" && <Error>{errors.city}</Error>}
          </InputBox>
          <InputBox>
            <Label>Estado</Label>
            <Input
              type="text"
              name="province"
              value={form.province}
              onChange={e => setValue("province", e.target.value)}
              onBlur={() => validateField("province")}
              error={errors.province}
              maxLength="2"
            />
            {errors.province !== "" && <Error>{errors.province}</Error>}
          </InputBox>
        </Box>
        <LineSeparator />

        {isRegisteredUser ? (
          <Warn>{WARN_PASSWORD_TEXT}</Warn>
        ) : (
          <div>
            <SubTitle>Crie uma senha:</SubTitle>
            <SmallText>
              Senha precisa conter no mínimo 6 caracteres, 1 letra maiúscula. 1
              letra minúscula e 1 número
            </SmallText>
          </div>
        )}
        <Box>
          {!isRegisteredUser && (
            <>
              <InputBox>
                <Label>Senha</Label>
                <Input
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={e => setValue("password", e.target.value)}
                  onBlur={() => validateField("password")}
                  error={errors.password}
                />
              </InputBox>
              <InputBox style={{ marginLeft: 10 }}>
                <Label>Confirmar senha</Label>
                <Input
                  type="password"
                  name="password_confirmation"
                  value={form.password_confirmation}
                  onChange={e =>
                    setValue("password_confirmation", e.target.value)
                  }
                  onBlur={() => validatePasswordConfirmation()}
                  error={errors.password_confirmation}
                />
              </InputBox>
              <StrongPassword
                width={passwordStrength}
                color={passwordStrengthColor}
              >
                <span>
                  {passwordStrengthText !== "" &&
                    `Senha ${passwordStrengthText}!`}
                </span>
                <div />
              </StrongPassword>
            </>
          )}
          <SubmitContent>
            <Submit disabled={loading} onClick={handleSubmit}>
              {loading ? (
                <ReactLoading type="bars" color="#fff" height={24} width={24} />
              ) : (
                "Enviar cadastro"
              )}
            </Submit>
          </SubmitContent>
        </Box>
      </Content>
    </Container>
  );
}
