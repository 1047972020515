import Jspdf from "jspdf";
import html2canvas from "html2canvas";

export const print = async tag => {
  const canvas = await html2canvas(tag);
  const imgData = canvas.toDataURL("image/png");
  const pdf = new Jspdf();
  pdf.addImage(imgData, "PNG", 297, 0);
  pdf.output("dataurlnewwindow");
};

export const savePdf = async tag => {
  const canvas = await html2canvas(tag);
  const imgData = canvas.toDataURL("image/png");
  const pdf = new Jspdf("p", "pt", [canvas.width, canvas.height]);
  pdf.addImage(imgData, "PNG", 0, 0);
  pdf.save("extrato.pdf");
};
