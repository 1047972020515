export function checkTokenRequest(token) {
  return {
    type: "@confirm_email/CHECK_TOKEN_REQUEST",
    payload: { token },
  };
}

export function checkTokenSuccess(token) {
  return {
    type: "@confirm_email/CHECK_TOKEN_SUCCESS",
    payload: { token },
  };
}

export function checkTokenFailure() {
  return {
    type: "@confirm_email/CHECK_TOKEN_FAILURE",
    payload: {},
  };
}
