import React, { useState } from "react";
import DaysSelect from "../../components/defaultComponents/DaysFilterSelect";
import OrderFilterSelect from "../../components/defaultComponents/OrderFilterSelect";

import Title from "../../components/defaultComponents/Title";
import List from "../../components/order/List";
import { Container, Header, TitleBox, FilterBox } from "./style";

export default function Order() {
  const [filterDaysSelected, setFilterDaysSelected] = useState({
    value: "60",
    label: "Ultimos 60 dias",
  });

  const [filterSelected, setFilterSelected] = useState({
    value: "",
    label: "Filtrar por",
  });

  const [inputFilter, setInputFilter] = useState("");

  const [filter, setFilter] = useState({ filterName: "", filterValue: "" });

  function handleFilter() {
    setFilter({
      filterName: filterSelected.value,
      filterValue: inputFilter,
    });
  }

  function handleInputEnterClick(e) {
    if (e.which === 13) {
      e.preventDefault();
      handleFilter();
    }
  }

  return (
    <Container>
      <Header>
        <TitleBox>
          <Title>Meus Pedidos</Title>
          <DaysSelect
            valueState={filterDaysSelected}
            setValueState={e => setFilterDaysSelected(e)}
            className="select"
          />
        </TitleBox>
        <FilterBox>
          <OrderFilterSelect
            valueState={filterSelected}
            setValueState={e => setFilterSelected(e)}
            className="select"
          />
          <input
            type="text"
            placeholder="Digite aqui"
            value={inputFilter}
            onChange={e => setInputFilter(e.target.value)}
            onKeyDown={handleInputEnterClick}
          />
          <input type="button" value="Ok" onClick={handleFilter} />
        </FilterBox>
      </Header>
      <List
        filterDays={filterDaysSelected.value}
        filterName={filter.filterName}
        filterValue={filter.filterValue}
      />
    </Container>
  );
}
