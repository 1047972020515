import React from "react";
import PropTypes from "prop-types";

import { Select as SelectInput } from "./style";

export default function Select({
  id,
  name,
  required,
  multiple,
  items,
  className,
  placeholder,
  value,
  setValue,
}) {
  return (
    <SelectInput
      id={id}
      name={name}
      required={required ? "required" : null}
      multiple={multiple ? "multiple" : null}
      className={className}
      onChange={setValue}
    >
      {placeholder && (
        <option value="" disabled selected={!value}>
          {placeholder}
        </option>
      )}
      {items.map(item => (
        <option key={item.id} value={item.id} selected={value === item.id}>
          {item.text}
        </option>
      ))}
    </SelectInput>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

Select.defaultProps = {
  id: Math.random()
    .toString(36)
    .substring(9),
  name: "",
  required: false,
  multiple: false,
  className: "",
  placeholder: "",
};
