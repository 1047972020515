import styled, { css } from "styled-components";
import Phone from "react-phone-input-2";
import DefaultModal from "../DefaultModal";
import { LinksBox as DefaltLinksBox } from "../DefaultModal/style";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    margin-bottom: 10px;
    color: ${props => (props.error ? "#dd0022" : "#000")};
  }
  > div {
    margin-top: 12px;
    > input {
      width: 100% !important;
      &:hover {
        border-color: #cacaca !important;
      }
      background-color: rgb(243, 243, 245) !important;
    }
    > div {
      &:before {
        content: "Celular" !important;
        background-color: rgb(243, 243, 245) !important;
        width: 53px !important;
        top: -10px !important;
        z-index: 2;
      }
    }
  }
`;

export const PhoneInput = styled(Phone)``;

export const Modal = styled(DefaultModal)`
  .warning {
    color: #ffde00;
  }
`;

export const LinksBox = styled(DefaltLinksBox)`
  margin-top: 30px;
  > span {
    color: #707070;
    font-size: 14px;
  }
`;
