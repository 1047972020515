import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-top: 60px;
  h2 {
    margin-bottom: 60px;
    margin-left: 35px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 30px 40px;

  width: 100%;
  background-color: #fff;
  border-radius: 10px;

  > div {
    margin: 0 20px 40px 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    margin-bottom: 0;
    color: ${COLORS.$darkGray};
  }
  > span {
    font-size: 10px;
    margin-top: 25px;
  }
  input {
    height: 35px;
    border-radius: 30px;
    padding-left: 15px;
  }
  input[type="submit"] {
    display: none;
  }
`;

export const Input = styled.input`
  border: ${props => (props.error ? "1px solid red" : "1px solid #bababa")};
`;

export const MiniBox = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(6, 1fr);
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${props => (props.span ? `auto / span ${props.span}` : "auto")};
  margin-bottom: 18px;
`;

export const Button = styled.button`
  align-self: flex-end;
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: fit-content;
  margin-top: 35px;
  height: 40px;
  transition: background-color 1s ease;
  background-color: #00205b;
  color: #fff;
  border-radius: 5px;
  padding: 0 30px;
  border: 0;
  cursor: pointer;
  &:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
`;
