import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  background: ${COLORS.$baseGrayBackground};
`;
