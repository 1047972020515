import numbersOnly from "../../util/numbersOnly";
import validateCpf from "../../util/validations/cpf";
import validateCnpj from "../../util/validations/cnpj";

export function validateUpperIsPresent(password) {
  return /(?=.*[A-Z])/.test(password);
}

export function validateLowerIsPresent(password) {
  return /(?=.*[a-z])/.test(password);
}

export function validateNumberIsPresent(password) {
  return /(?=.*[0-9])/.test(password);
}

export function validatePasswordLength(password) {
  return password.length >= 6;
}

export function validateCpfCnpj(value) {
  if (value === "") {
    return "Campo Cpf/Cnpj é obrigatório";
  }
  if (value.length !== 11 && value.length !== 14) {
    return "Cpf ou Cnpj inválido";
  }
  if (value.length === 11 && !validateCpf(value)) {
    return "Cpf inválido";
  }
  if (value.length === 14 && !validateCnpj(value)) {
    return "Cnpj inválido";
  }
  return "";
}
function validateFantasy(value) {
  return "";
}
function validateName(value) {
  if (value === "") {
    return "Campo nome é obrigatório";
  }
  if (value.length < 5) {
    return "Mínimo de cinco caracteres";
  }
  if (value.split(" ").length < 2) {
    return "Informe o nome completo";
  }
  return "";
}
function validatePhone(value) {
  if (value === "") {
    return "Campo telefone é obrigatório";
  }
  if (
    numbersOnly(value).toString().length !== 11 &&
    numbersOnly(value).toString().length !== 10
  ) {
    return "Número inválido";
  }
  return "";
}
function validateResponsibleName(value) {
  if (value === "") {
    return "Campo responsável pela compra é obrigatório";
  }
  if (value.length < 5) {
    return "Mínimo de cinco caracteres";
  }
  if (value.split(" ").length < 2) {
    return "Informe o nome completo";
  }
  return "";
}
function validateEmail(value) {
  if (value === "") {
    return "Campo e-mail é obrigatório";
  }
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(value)) {
    return "Email inválido";
  }
  return "";
}
function validateCep(value) {
  if (value === "") {
    return "Campo CEP é obrigatório";
  }
  if (numbersOnly(value).toString().length !== 8) {
    return "Cep inválido";
  }
  return "";
}
function validateAddress(value) {
  if (value === "") {
    return "Campo endereço é obrigatório";
  }
  return "";
}
function validateNumber(value) {
  if (value === "") {
    return "Campo número é obrigatório (S/N caso não possua número)";
  }
  return "";
}
function validateComplement(value) {
  return "";
}
function validateDistrict(value) {
  if (value === "") {
    return "Campo bairro é obrigatório";
  }
  return "";
}
function validateCity(value) {
  if (value === "") {
    return "Campo cidade é obrigatório";
  }
  return "";
}
function validateProvince(value) {
  if (value === "") {
    return "Campo estado é obrigatório";
  }
  return "";
}
function validatePassword(value) {
  if (value === "") {
    return "Campo senha é obrigatório";
  }
  return "";
}
export function validateConfirmPassword(value1, value2) {
  if (value1 !== value2) {
    return "Campo confirmação de senha é precisa ser igual a senha";
  }
  return "";
}
// <p>Senha precisa conter no mínimo 6 caracteres, 1 letra maiúscula. 1 letra minúscula e 1 número</p>
export const validate = (fieldName, value) => {
  let error = "";
  switch (fieldName) {
    case "cpf_cnpj":
      error = validateCpfCnpj(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "fantasy_name":
      error = validateFantasy(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "name":
      error = validateName(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "phone":
      error = validatePhone(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "sponsor_name":
      error = validateResponsibleName(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "email":
      error = validateEmail(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "cep":
      error = validateCep(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "address":
      error = validateAddress(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "number":
      error = validateNumber(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "complement":
      error = validateComplement(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "district":
      error = validateDistrict(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "city":
      error = validateCity(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "province":
      error = validateProvince(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "password":
      error = validatePassword(value);
      if (error !== "") {
        return error;
      }
      return "";
    case "password_confirmation":
      error = validateConfirmPassword(value);
      if (error !== "") {
        return error;
      }
      return "";
    default:
      return "";
  }
};

export const validateForm = form => {
  const arr = Object.keys(form).map(key => {
    return { key, value: form[key] };
  });

  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    if (item.key !== "password_confirmation" && item.key !== "password") {
      const error = validate(item.key, item.value);
      if (error !== "") {
        return error;
      }
    }
  }

  if (!form.is_user) {
    let passError = validate("password", form.password);
    if (passError !== "") {
      return passError;
    }
    passError = validateConfirmPassword(
      form.password,
      form.password_confirmation
    );
    if (passError !== "") {
      return passError;
    }
  }

  return "";
};
