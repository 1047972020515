import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  @media only screen and (max-width: 760px) {
    width: 298px;
    flex: 0 0 auto;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  cursor: pointer;

  border-radius: ${props => (props.bestOption ? "10px 10px 0 0" : "10px")};

  @media only screen and (min-width: 761px) {
    box-shadow: ${props =>
      props.selected
        ? "0 0 10px 10px rgba(0, 0, 0, 0.3)"
        : "0 0 10px 10px rgba(0, 0, 0, 0)"};
  }
  transition: ease 0.4s;

  @media only screen and (max-width: 760px) {
    flex: 0 0 auto;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    border-radius: ${props => (props.bestOption ? "10px 10px 0 0" : "10px")};
    @media only screen and (min-width: 761px) {
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    }
    transition: ease 0.4s;
  }

  .box {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .left {
      margin-right: 12px;
      margin-left: 10px;
      margin-top: 20px;
      font-size: 24px;
      color: ${props => (props.selected ? "#92c020" : "#cccccc")};
      width: 24px;
      i {
        color: ${props => (props.selected ? "#92c020" : "#cccccc")};
      }
    }

    .center {
      margin-top: 20px;
      h1 {
        font-size: 20px;
        font-weight: bold;
        color: #1d3475;
      }

      h2 {
        margin-top: 0;
        margin-left: 0;
        font-size: 17px;
        font-weight: normal;
      }

      h3 {
        margin-top: 8px;
        color: #5e5e5e;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .right {
      font-size: 16px;
      color: #bbbbbb;
      margin: 10px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      i {
        cursor: pointer;
        z-index: 3;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    margin-top: 26px;
    padding-top: 18px;
    margin-bottom: 20px;
    padding-left: 46px;
    border-top: 1px dashed #bababf;
    font-size: 18px;
  }
`;

export const EspecialBox = styled.div`
  display: flex;
  background-color: #00205b;
  width: 100%;
  height: 40px;
  align-items: center;
  border-radius: 0 0 10px 10px;
  padding-left: 15px;
  span {
    color: #fff;
    margin-left: 12px;
  }
  svg {
    width: 18px;
    color: #fff;
  }
`;
