import React from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { Input } from "./style";

export default function Button({
  value,
  className,
  onClick,
  type,
  disabled,
  loading,
}) {
  return (
    <Input
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {loading ? (
        <ReactLoading type="bars" color="#fff" height={24} width={24} />
      ) : (
        value
      )}
    </Input>
  );
}

Button.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["submit", "button"]),
  disabled: PropTypes.oneOf([true, false, null]),
};

Button.defaultProps = {
  className: "",
  type: "button",
  onClick: () => {},
  disabled: null,
};
