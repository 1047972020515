import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdNotifications } from "react-icons/md";
import { setModalOpened as setModalSuccessBoughtOpened } from "../../store/modules/modalSuccessBought/actions";
import { showModal as showSuccessModal } from "../../store/modules/modalSuccess/actions";
import { showModal as showTicketSmsModal } from "../../store/modules/modalTicketSendSms/actions";
import { loadNotifications } from "../../store/modules/notification/actions";
import api from "../../services/api";

import {
  Container,
  Badge,
  NotificationList,
  Notification,
  Scroll,
} from "./style";

export default function Notifications({ className }) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { hasUnread, notifications } = useSelector(state => state.notification);

  async function handleNotificationClick(notification) {
    setVisible(false);

    const { type, processed, message, read, id, content } = notification;
    switch (type) {
      case "ticket":
        if (processed) {
          dispatch(
            showSuccessModal({
              title: "Ticket validado com sucesso",
              text: message,
            })
          );
          break;
        }
        dispatch(
          showTicketSmsModal({
            title: "Falha ao validar Ticket",
            text: message,
            notificationId: id,
            request: content,
          })
        );
        break;
      default:
        dispatch(setModalSuccessBoughtOpened(true, processed));
        break;
    }

    if (!read) {
      await api.put(`notifications/${id}`);
      dispatch(loadNotifications());
    }
  }

  return (
    <Container className={className}>
      <Badge onClick={() => setVisible(!visible)} hasUnread={hasUnread}>
        <MdNotifications color="#fff" size={20} />
      </Badge>
      <NotificationList visible={visible}>
        <Scroll>
          {!notifications ||
            (notifications.length === 0 && (
              <Notification unread={false} onClick={() => {}}>
                <p>Não há notificações</p>
              </Notification>
            ))}
          {notifications.map(notification => (
            <Notification
              key={notification.id}
              unread={!notification.read}
              onClick={() => handleNotificationClick(notification)}
            >
              <p>{notification.title}</p>
              <time>{notification.timeDistance}</time>
            </Notification>
          ))}
        </Scroll>
      </NotificationList>
    </Container>
  );
}
