import React from "react";
import PropTypes from "prop-types";

import { H2 } from "./style";

export default function Title({ children, className }) {
  return <H2 className={className}>{children}</H2>;
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: "",
};
