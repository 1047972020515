import styled from "styled-components";
import MaterialCheckBox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import DefaultModal from "../DefaultModal";
import { LinksBox as DefaultLinksBox } from "../DefaultModal/style";

export const LinksBox = styled(DefaultLinksBox)`
  justify-content: flex-end;
  > button {
    width: 100px;
  }
`;

export const Modal = styled(DefaultModal)`
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-top: 10px;
  height: 60px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
`;

export const Checkbox = withStyles({
  root: {
    color: grey[250],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})(MaterialCheckBox);

export const FavoriteCheckbox = styled(MaterialCheckBox)``;

export const FavoriteBox = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const Sites = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 320px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;
