import styled from "styled-components";
import { COLORS } from "../../styles/defaults";
import DefaultInput from "../../components/defaultComponents/Input";
import DefaultTitle from "../../components/defaultComponents/Title";
import DefaultButton from "../../components/defaultComponents/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 100%;
  max-width: 1280px;
`;

export const StatusProcessing = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > span {
    font-size: 18px;
    text-align: center;
    color: ${COLORS.$darkBlue};
  }
`;

export const DefaultInputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 30px;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);

  input {
    border: none;
  }

  input[type="text"] {
    border-radius: 30px;
    margin: 4px 0;
    padding-left: 18px;
    width: 100%;
  }
`;

export const TicketInput = styled(DefaultInputBox)`
  width: 400px;

  input {
    border: none;
  }

  button {
    background: ${COLORS.$darkBlue};
    color: #fff;
    width: 60px;
    border-radius: 0 30px 30px 0;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="text"] {
    border-radius: 30px 0 0 30px;
    width: 300px;
  }
`;

export const Input = styled(DefaultInput)`
  width: 100%;
`;

export const Filters = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & + div {
    flex: 1;
    margin-left: 25px;
  }
  > span {
    font-size: 14px;
    color: ${COLORS.$darkGray};
  }
`;

export const Title = styled(DefaultTitle)`
  margin-bottom: 15px;
`;

export const LeftBox = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  background-color: #fff;
  border-radius: 8px;
  padding: 23px 5px;
  width: 400px;
  height: 140px;
`;

export const MiniBox = styled.div`
  & + div {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #565656;
  > span {
    font-size: 14px;
    line-height: 1.1;
    text-align: center;
    font-weight: 500;
    max-width: 80px;
  }
  > h3 {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const RightBox = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  border-radius: 8px;
  margin-left: 25px;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
`;

export const WalletBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
`;

export const TotalBox = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 30px;
  padding-right: 30px;
  height: 90px;
  justify-content: space-between;
  > span {
    color: ${COLORS.$darkBlue};
    font-weight: 500;
  }
  > div {
    display: flex;
    justify-content: space-between;
    color: ${COLORS.$darkBlue};
    > div {
      display: flex;
      flex-direction: column;
      & + div {
        margin-left: 40px;
      }
      > span {
        font-size: 14px;
        line-height: 1.1;
        text-align: center;
        font-weight: 400;
        max-width: 80px;
      }
      > h3 {
        font-size: 32px;
        line-height: 1.2;
        font-weight: 500;
      }
    }
  }
`;

export const StampsSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 45px;
  > h2 {
    color: ${COLORS.$darkGray};
    font-size: 20px;
    font-weight: 500;
  }
`;

export const FooterContent = styled(Content)`
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  padding: 0;
  > span {
    text-transform: uppercase;
    color: ${props => (props.extra ? "green" : `${COLORS.$darkBlue}`)};
    margin-right: 10px;
    font-weight: 500;
    line-height: 1;
    display: block;
    text-align: end;
    font-size: ${props => (props.extra ? "14px" : "12px")};
    text-align: center;
    width: 120px;
    margin-right: ${props => (props.extra ? "5px" : "10px")};
  }
  h2 {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
  }
  > b {
    margin-right: 35px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 90px;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

export const Button = styled(DefaultButton)`
  font-size: 15px;
  width: fit-content;
  height: 40px;
  transition: background-color 1s ease;
  color: #fff;
  &:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
`;
