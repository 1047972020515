export function setSelectedUser(user) {
  return {
    type: "@extract/SET_SELECTED_USER",
    payload: { user },
  };
}

export function setSelectedDaysFilter(filter) {
  return {
    type: "@extract/SET_SELECTED_DAYS_FILTER",
    payload: { filter },
  };
}
