import React from "react";

import { Switch } from "react-router-dom";
import Route from "./Route";

import Main from "../pages/main";
import Wrong from "../pages/wrong";

import Boleto from "./Boleto";
import Cards from "./Cards";
import Contact from "./Contact";
import Extract from "./Extract";
import Orders from "./Orders";
import QrCode from "./QrCode";
import SignInUp from "./SignInUp";
import Users from "./Users";
import Validate from "./Validate";

const routesArray = [
  Boleto,
  Cards,
  Contact,
  Extract,
  Orders,
  QrCode,
  SignInUp,
  Users,
  Validate,
];

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Main} type="pre" />
    {routesArray.map(componentArray =>
      componentArray.map(component => (
        <Route
          exact
          path={component.path}
          component={component.component}
          accessPage={component.accessPage}
          isPrivate={component.isPrivate !== false}
          special={component.special === true}
          type={component.type ? component.type : "all"}
        />
      ))
    )}
    <Route path="/" component={Wrong} />
  </Switch>
);

export default Routes;
