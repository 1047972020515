export function addToCartValidateSuccess(stamp) {
  return {
    type: "@cart_validate/ADD_TO_CART",
    payload: { stamp },
  };
}

export function setTicket(ticket) {
  return {
    type: "@cart_validate/SET_TICKET",
    payload: { ticket },
  };
}

export function clear() {
  return {
    type: "@cart_validate/CLEAR",
    payload: {},
  };
}

export function ticketProcessed() {
  return {
    type: "@cart_validate/TICKET_PROCESSED",
    payload: {},
  };
}

export function processingTicket() {
  return {
    type: "@cart_validate/PROCESSING_TICKET",
    payload: {},
  };
}

/* export function checkoutRequest(order) {
  return {
    type: "@cart/CHECKOUT_REQUEST",
    payload: { order },
  };
}

export function checkoutSuccess() {
  return {
    type: "@cart/CHECKOUT_SUCCESS",
    payload: {},
  };
}



export function checkoutFailure(message) {
  return {
    type: "@cart/CHECKOUT_FAILURE",
    payload: { message },
  };
}
*/
