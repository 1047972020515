const buildUserFromRequest = data => ({
  cpf: data.cpf || "",
  cnpj: data.cnpj || "",
  fantasy_name: data.customerName || "",
  name: data.name || "",
  email: data.email || "",
  phone: data.phone || "",
  is_user: typeof data.isUser === "boolean" ? data.isUser : null,
  cep: data.zipCode || "",
  address: data.address || "",
  number: data.num || "",
  city: data.city || "",
  district: data.county || "",
  province: data.state || "",
});

export default buildUserFromRequest;
