import React, { useEffect, useState, useCallback } from "react";

import formatString from "format-string-by-pattern";
import numbersOnly from "../../../util/numbersOnly";
import { Container, ProtectedBox, ButtonBox, Button, Warn } from "./style";
import api from "../../../services/api";
import getUserInfo from "../../../services/user/getUserInfo";
import history from "../../../services/history";
import { successMessage, errorMessage } from "../../../util/toastNotification";

const WARN_PASSWORD_TEXT = `Encontramos seu cadastro do Login Netpark e usaremos esses mesmos
dados para seu usuário do portal de selos. Sua senha será a mesma
que você cadastrou no Aplicativo Netpark. Caso não se lembre de sua
senha, basta clicar na opção "Esqueci minha senha", na tela de
login.`;

export default function Profile() {
  const [inputCpf, setInputCpf] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputMobile, setInputMobile] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputSection, setInputSection] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const [validForm, setValidForm] = useState(false);

  const validateForm = useCallback(() => {
    if (inputName === "") {
      setValidForm(false);
      return;
    }
    if (inputEmail === "") {
      setValidForm(false);
      return;
    }
    if (inputPhone === "") {
      setValidForm(false);
      return;
    }
    if (inputSection === "") {
      setValidForm(false);
      return;
    }
    if (inputCpf === "") {
      setValidForm(false);
      return;
    }
    if (!isRegisteredUser && inputPassword === "") {
      setValidForm(false);
      return;
    }

    setValidForm(true);
  }, [
    inputCpf,
    inputEmail,
    inputName,
    inputPassword,
    inputPhone,
    inputSection,
    isRegisteredUser,
  ]);

  useEffect(() => {
    validateForm();
  }, [inputName, inputPhone, inputMobile, validateForm]);

  async function handleSaveData() {
    if (!validForm) {
      return;
    }

    try {
      setLoading(true);

      const request = {
        cpf: inputCpf,
        name: inputName,
        email: inputEmail,
        phone: inputPhone,
        password: inputPassword,
        section: inputSection,
        is_user: isRegisteredUser,
      };

      const {
        data: { inserted, message },
      } = await api.post("users", request);
      if (inserted) {
        const info = message || "Usuário adicionado com sucesso!";
        successMessage({ message: info });
        history.push("/users");
        return;
      }
      const error = message || "Falha ao adicionar usuário";
      errorMessage({ message: error });
    } catch ({ response }) {
      errorMessage({
        message:
          response &&
          response.data &&
          response.data.error &&
          typeof response.data.error === "string"
            ? response.data.error
            : "Falha ao criar usuário",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleBlurCpf = async cpf => {
    setInternalLoading(true);
    if (cpf && cpf.length === 11) {
      const { name, phone, email, isUser } = await getUserInfo(cpf);
      if (name) setInputName(name);
      if (email) setInputEmail(email);
      if (phone) setInputPhone(phone);
      setIsRegisteredUser(isUser || false);
      setInternalLoading(false);
    }
  };

  return (
    <Container>
      <ProtectedBox className="box">
        <div>
          <span>Cpf</span>
          <input
            type="text"
            value={formatString("XXX.XXX.XXX-XX", inputCpf)}
            onChange={e => setInputCpf(numbersOnly(e.target.value))}
            onBlur={() => handleBlurCpf(inputCpf)}
            maxLength={14}
          />
        </div>
        <div>
          <span>Nome Completo</span>
          <input
            type="text"
            value={inputName}
            onChange={e => setInputName(e.target.value)}
          />
        </div>

        {!isRegisteredUser && (
          <div>
            <span>Senha</span>
            <input
              type="password"
              value={inputPassword}
              onChange={e => setInputPassword(e.target.value)}
            />
          </div>
        )}

        <div>
          <span>E-mail</span>
          <input
            type="text"
            value={inputEmail}
            onChange={e => setInputEmail(e.target.value)}
          />
        </div>
        <div>
          <span>Telefone</span>
          <input
            type="text"
            value={
              inputPhone.length <= 10
                ? formatString("(XX) XXXX-XXXX", inputPhone)
                : formatString("(XX) X XXXX-XXXX", inputPhone)
            }
            onChange={e => setInputPhone(numbersOnly(e.target.value))}
            maxLength={16}
          />
        </div>
        <div>
          <span>Departamento</span>
          <input
            type="text"
            value={inputSection}
            onChange={e => setInputSection(e.target.value)}
          />
        </div>
      </ProtectedBox>

      {isRegisteredUser && <Warn>{WARN_PASSWORD_TEXT}</Warn>}

      <ButtonBox>
        <Button
          onClick={handleSaveData}
          value="Salvar alterações"
          disabled={validForm ? null : true}
          className="actionButton"
          loading={loading}
        />
      </ButtonBox>
    </Container>
  );
}
