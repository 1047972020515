import axios from "axios";

// const prefix = "api/v1";
const prefix = "";
const baseURL = process.env.REACT_APP_API_HOST;

const api = axios.create({
  baseURL: `${baseURL}/${prefix}`, // `https://netselos-node.herokuapp.com/${prefix}`,
});

export default api;
