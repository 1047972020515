import produce from "immer";

const INITIAL_STATE = {
  selectedContract: {},
  sites: [],
  user: {},
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@session/SET_SELECTED_CONTRACT":
      return produce(state, draft => {
        draft.selectedContract = action.payload.contract;
      });
    case "@session/SET_SITES":
      return produce(state, draft => {
        draft.sites = action.payload.sites;
      });
    case "@session/SET_USER":
      return produce(state, draft => {
        draft.user = action.payload.user;
      });
    default:
      return state;
  }
}
