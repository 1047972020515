import styled from "styled-components";

export const TableBox = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #bababa;
  flex: 1;
`;

export const Table = styled.table`
  width: 100%;

  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;

  thead {
    background: #eeeff0;
  }

  th {
    color: #565656;
    padding: 10px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
    border-radius: 2px;
    vertical-align: middle;
    display: table-cell;
  }
  tbody {
    tr {
      border-top: 1px solid #bababa;
    }
    td {
      color: #656565;
      text-align: right;
      padding: 10px;
      font-size: 12px;
      vertical-align: middle;
      border-radius: 2px;
      display: table-cell;
    }
  }

  /*tr {
    td:nth-child(4) {
      display: flex;
      justify-content: flex-end;
      padding-right: 25px;
    }
  }*/
`;

export const ActionBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: ${props => (props.loading ? "20px" : "0")};
  > label {
    margin-right: 0;
  }
`;
