export function signUpCheckRequest(cpfOrCnpj) {
  return {
    type: "@register/SIGN_UP_CHECK_REQUEST",
    payload: { cpfOrCnpj },
  };
}

/* user: {cpf, cnpj} || user: {cpf, cnpj, customerName, zipCode, address, num, city, county, state, country} */
export function signUpCheckSuccess(user) {
  return {
    type: "@register/SIGN_UP_CHECK_SUCCESS",
    payload: { user },
  };
}

export function signUpRequest(user) {
  return {
    type: "@register/SIGN_UP_REQUEST",
    payload: { user },
  };
}

export function signUpSuccess() {
  return {
    type: "@register/SIGN_UP_SUCCESS",
    payload: {},
  };
}

export function signUpFailure() {
  return {
    type: "@register/SIGN_UP_FAILURE",
    payload: {},
  };
}

export function setSuccessAccountCreatedOpened(status) {
  return {
    type: "@register/SUCESS_MODAL_OPENED",
    payload: { status },
  };
}
