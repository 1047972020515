import React, { useState } from "react";
import PropTypes from "prop-types";

import EyeIcon from "../../../assets/images/eye.png";
import { Container, Box, Error, Img, Cut } from "./style";

export default function PasswordInput({
  id,
  name,
  value,
  placeholder,
  label,
  onBlur,
  onChange,
  onFocus,
  className,
  error,
  maxLength,
}) {
  const [type, setType] = useState("password");

  function handleEyeClick() {
    if (type === "password") {
      setType("text");
      return;
    }
    setType("password");
  }

  return (
    <Box className={className}>
      <Container name={label}>
        <input
          className={error ? "invalid" : ""}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          id={id}
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          onFocus={onFocus}
        />
        <label htmlFor={id}>{label}</label>
        <Img src={EyeIcon} alt="Password view" onClick={handleEyeClick} />
        <Cut closed={type === "text"} onClick={handleEyeClick} />
      </Container>
      {error && <Error>{error}</Error>}
    </Box>
  );
}

PasswordInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PasswordInput.defaultProps = {
  id: null,
  name: null,
  value: "",
  placeholder: "",
  label: "",
  className: "",
  error: "",
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  maxLength: null,
};
