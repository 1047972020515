import React from "react";
import ContentLoader from "react-content-loader";

export default function LoadingStamps({ width }) {
  return (
    <ContentLoader
      speed={2}
      width={width - 80}
      height={190}
      viewBox={`0 0 ${width - 80} 190`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width={width - 110} height="60" />
      <rect x="0" y="70" rx="10" ry="10" width={width - 110} height="28" />
      <rect x="0" y="109" rx="10" ry="10" width={width - 110} height="28" />
      <rect x="0" y="147" rx="10" ry="10" width={width - 110} height="28" />
    </ContentLoader>
  );
}
