import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { COLORS } from "../../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;

  > span {
    color: #565656;
    font-size: 28px;
    margin-left: 10px;
    margin-right: 30px;
    width: 180px;
  }
`;

export const TableBox = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #bababa;
  flex: 1;
`;

export const Table = styled.table`
  width: 100%;

  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;

  thead {
    background: #eeeff0;
  }

  th {
    color: #565656;
    padding: 10px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
    border-radius: 2px;
    vertical-align: middle;
    display: table-cell;
  }
  tbody {
    tr {
      border-top: 1px solid #bababa;
    }
    td {
      color: #656565;
      text-align: right;
      padding: 10px;
      font-size: 12px;
      vertical-align: middle;
      border-radius: 2px;
      display: table-cell;
    }
  }

  tr {
    td:nth-child(1),
    th:nth-child(1) {
      width: 60px;
    }
    td:nth-child(2),
    th:nth-child(2),
    td:nth-child(3),
    th:nth-child(3) {
      width: 135px;
    }
    td:last-child,
    th:last-child {
      width: 270px;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > svg {
    margin-left: 5px;
    width: 20px;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  padding: 7px;
  h4 {
    font-size: 13px;
    font-weight: normal;
    color: ${COLORS.$darkGray};
    margin-top: 2px;
    margin-bottom: 2px;
    text-decoration: underline;
    text-align: center;
  }
  span {
    font-size: 12px;
    color: ${COLORS.$darkGray};
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f4f4f4",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const EmptyContent = styled.div`
  width: 100%;
  max-width: 1320px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 25px;
  > span {
    color: ${COLORS.$darkBlue};
    font-size: 22px;
  }
`;
