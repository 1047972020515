import styled, { css } from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: ${props =>
    props.opened ? "block" : "none "}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1001; /* Sit on top */
  /*padding-top: 100%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: unset; /*auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const Content = styled.div`
  > span {
    position: absolute;
    top: 8px;
    right: 21px;
    color: ${COLORS.$darkBlue};
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
  }
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 12px 12px 12px 12px;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: animateModal 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  @keyframes animateModal {
    0% {
      transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  > h2 {
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    border-radius: 50px;
    padding: 20px 90px;
    transform: translateY(-63%);
    background-color: ${COLORS.$darkBlue};
  }
  @media only screen and (max-width: 720px) {
    height: 45px;
    > h2 {
      font-size: 14px;
      padding: 12px 60px;
      transform: translateY(-115%);
    }
  }

  @media only screen and (max-width: 460px) {
    > h2 {
      padding: 12px 35px;
    }
  }
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  align-items: center;
`;

export const CloseButton = styled.span``;
