import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import history from "../../../services/history";
import { Container, Content, Box, AddNewCard, ContentLoading } from "./style";
import Title from "../../../components/defaultComponents/Title";
import Card from "../../../components/CreditCard";
import api from "../../../services/api";
import numbersOnly from "../../../util/numbersOnly";
import { errorMessage } from "../../../util/toastNotification";
import DeleteCardModal from "../../../components/modals/DeleteCard";
import LoadingCards from "../../../components/CreditCard/Loading";

export default function List() {
  const [cards, setCards] = useState([]);
  const { master } = useSelector(state => state.session.user);
  const [selectedCard, setSelectedCard] = useState({});
  const [modalDeleteOpened, setModalDeleteOpened] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!refresh) {
      return;
    }
    async function loadCards() {
      setLoading(true);
      const { data } = await api.get("cards");
      if (data.cards) {
        const result = data.cards.map(card => ({
          ...card,
          lastFourDigits: numbersOnly(card.cardNumber),
          expiry: `${card.month.toString().padStart(2, "0")}/${card.year}`,
        }));

        setCards(result);
      }
      setRefresh(false);
      setLoading(false);
    }

    loadCards();
  }, [refresh]);

  function handleAddNewCart() {
    if (master === "FALSE" || master === false) {
      errorMessage({
        message: "Apenas usuários Master podem adicionar novos cartões",
      });
      return;
    }

    history.push("cards/new");
  }

  function handleDeleteCard(card) {
    if (master === "FALSE" || master === false) {
      errorMessage({
        message: "Apenas usuários Master podem remover cartões",
      });
      return;
    }
    setSelectedCard(card);
    setModalDeleteOpened(true);
  }

  function onCloseModal() {
    setModalDeleteOpened(false);
    setRefresh(true);
  }

  return (
    <Container>
      <Content>
        <Title>Meus Cartões de Crédito</Title>
        <Box>
          {loading ? (
            <ContentLoading>
              <LoadingCards />
            </ContentLoading>
          ) : (
            <>
              {cards.map(card => (
                <div
                  className="creditCard"
                  onClick={() => handleDeleteCard(card)}
                  key={`div-${card.id}`}
                >
                  <Card
                    key={card.id}
                    lastNumbers={card.lastFourDigits}
                    name={card.name}
                    expiry={card.expiry}
                    brand={card.brand}
                  />
                </div>
              ))}
              <AddNewCard onClick={handleAddNewCart}>
                <AddIcon />
                <span>Cadastrar novo cartão de crédito</span>
              </AddNewCard>
            </>
          )}
        </Box>
      </Content>
      <DeleteCardModal
        card={selectedCard}
        opened={modalDeleteOpened}
        onClose={onCloseModal}
      />
    </Container>
  );
}
