import styled from "styled-components";
import DefaultModal from "../DefaultModal/index2";
import DefaultPasswordInput from "../../defaultComponents/PasswordInput";
import { LinksBox as DefaultLinksBox } from "../DefaultModal/style";
import { COLORS } from "../../../styles/defaults";
import DefaultButton from "../../defaultComponents/Button";

export const LinksBox = styled(DefaultLinksBox)`
  justify-content: flex-end;
`;

export const LinksRecover = styled(DefaultLinksBox)`
  justify-content: space-around;
  max-width: 70%;
  @media only screen and (max-width: 540px) {
    max-width: 100%;
    justify-content: space-between;
  }
`;

export const Modal = styled(DefaultModal)`
  max-width: 630px;
  @media only screen and (max-width: 720px) {
    padding: 30px 0;
  }
  input[type="button"] {
    margin-top: 25px;
  }

  .regulationLink {
    align-self: flex-start;
    margin-top: 15px;
    color: blue;
  }

  z-index: 1001;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    color: #707070;
    font-size: 18px;
    /*Helvetica*/
  }
`;

export const Input = styled(DefaultPasswordInput)`
  margin-top: 20px;
  margin-bottom: 6px;
  > div {
    width: 430px;
  }
  label {
    background-color: #fff;
  }
  > div {
    border: 1px solid #909090;
    label {
      background-color: #fff;
      color: #454545;
    }
    input {
      color: #404040;
    }
  }
  @media only screen and (max-width: 720px) {
    > div {
      width: 100%;
    }
  }
`;

export const Button = styled(DefaultButton)`
  width: 120px;
  height: 35px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: normal;
    font-size: 12px;
  }
`;
