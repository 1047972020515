import produce from "immer";

const INITIAL_STATE = {
  user: {},
  loading: false,
  successModalOpened: false,
};

export default function register(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@register/SIGN_UP_CHECK_REQUEST":
      return produce(state, draft => {
        draft.loading = true;
      });
    case "@register/SIGN_UP_CHECK_SUCCESS":
      return produce(state, draft => {
        draft.loading = false;
        draft.user = action.payload.user;
      });
    case "@register/SIGN_UP_REQUEST":
      return produce(state, draft => {
        draft.loading = true;
      });
    case "@register/SIGN_UP_SUCCESS":
      return {
        user: {},
        loading: false,
        successModalOpened: true,
      };
    case "@register/SIGN_UP_FAILURE":
      return produce(state, draft => {
        draft.loading = false;
      });
    case "@register/SUCESS_MODAL_OPENED":
      return produce(state, draft => {
        draft.successModalOpened = action.payload.status;
      });
    default:
      return state;
  }
}
