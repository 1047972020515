import styled from "styled-components";

export const Content = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 90px;

  h3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    line-height: 15px;
  }

  h2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
    span {
      line-height: 1;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;
