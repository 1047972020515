import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkTokenRequest } from "../../store/modules/changePassword/actions";
import Loading from "../../components/pageLoading";
import history from "../../services/history";
import { errorMessage } from "../../util/toastNotification";

export default function RecoverPassword({ location }) {
  const dispatch = useDispatch();
  useEffect(() => {
    function getTokenBy(params) {
      return new URLSearchParams(params).get("token");
    }
    const token = getTokenBy(location.search);
    if (!token || token === "") {
      errorMessage({ message: "Token inválido ou não informado" });
      history.push("/login");
    }
    dispatch(checkTokenRequest(token));
  }, [dispatch, location]);

  return <Loading />;
}
