import styled from "styled-components";
import MaterialCheckBox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import DefaultModal from "../DefaultModal";

export const Modal = styled(DefaultModal)`
  h2 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-top: 10px;
  height: 60px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
`;

export const Checkbox = withStyles({
  root: {
    color: grey[250],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})(MaterialCheckBox);

export const CardBox = styled.div`
  transform: scale(0.8);
  cursor: pointer;
  transition: ease 0.4s;
  border-radius: 16px;
  box-shadow: ${props =>
    props.selected
      ? "0 0 10px 10px rgba(0, 0, 0, 0.3)"
      : "0 0 10px 10px rgba(0, 0, 0, 0)"};
  flex: 0 0 auto;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

export const List = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  &::-webkit-scrollbar {
    /*display: none;*/
  }
`;

export const Span = styled.span`
  font-size: 12px;
  color: #707070;
  margin: 0 25px 15px 25px;
  text-align: center;
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
