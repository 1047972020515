import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px;
  min-width: 182px;
  width: fit-content;
  max-width: 300px;
  background: #fff;
  margin-top: 12px;

  border-radius: 10px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0);
  transition: ease 0.4s;

  @media only screen and (max-width: 760px) {
    flex: 0 0 auto;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    @media only screen and (min-width: 761px) {
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    }
    transition: ease 0.4s;
    .header {
      color: ${COLORS.$darkBlue};
    }
  }

  .header {
    font-size: 50px;
    font-weight: bolder;
    color: #565656;
  }

  .box {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 24px;

    input {
      border: none;
      font-size: 30px;
      width: ${({ length }) =>
        length > 2 ? `${45 + (length - 2) * 15}px` : "45px"};
      max-width: 150px;
      color: #bababa;
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
    }

    button {
      width: 25%;
      border: none;
      background: transparent;
      font-size: 26px;
      cursor: pointer;
      transition: all 0.1s;
      transform: scale(1.2);
      svg {
        fill: #00205b;
        transition: all 0.1s;
      }
      &:active {
        svg {
          fill: #1891f2;
          transform: scale(0.8);
        }
      }
    }
  }

  span {
    margin-bottom: 15px;
    font-size: 16px;
    color: #565656;
  }

  .footer {
    border-top: 1px dashed #bababf;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
    margin-top: 2px;
    line-height: 1;
    color: #565656;
    transition: ease 0.4s;
    padding-top: 10px;
  }
`;
