import React, { useEffect, useState, useCallback } from "react";
import formatString from "format-string-by-pattern";

import {
  Container,
  ProtectedBox,
  AllowedBox,
  PasswordBox,
  SubTitle,
  LineSeparator,
  ButtonBox,
} from "./style";
import Button from "../defaultComponents/Button";
import api from "../../services/api";
import history from "../../services/history";
import { successMessage } from "../../util/toastNotification";

export default function Profile() {
  const [user, setUser] = useState({});
  const [inputName, setInputName] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputMobile, setInputMobile] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordConfirmation, setInputPasswordConfirmation] = useState(
    ""
  );
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    async function loadData() {
      const { data } = await api.get("profile");
      setUser(data.user);
    }

    loadData();
  }, []);

  const validateForm = useCallback(() => {
    if (inputPassword !== "") {
      if (inputPassword !== inputPasswordConfirmation) {
        setValidForm(false);
        return;
      }
      setValidForm(true);
      return;
    }
    if (inputName !== "") {
      setValidForm(true);
      return;
    }
    if (inputPhone !== "") {
      setValidForm(true);
      return;
    }
    if (inputMobile !== "") {
      setValidForm(true);
      return;
    }

    setValidForm(false);
  }, [
    inputMobile,
    inputName,
    inputPassword,
    inputPasswordConfirmation,
    inputPhone,
  ]);

  useEffect(() => {
    validateForm();
  }, [
    inputName,
    inputPhone,
    inputMobile,
    inputPassword,
    inputPasswordConfirmation,
    validateForm,
  ]);

  async function handleSaveData() {
    if (!validForm) {
      return;
    }

    const request = {
      name: inputName,
      phone: inputPhone,
      second_phone: inputMobile,
      password: inputPassword,
    };

    const { data } = await api.put("profile", request);
    successMessage({ message: data.message });
    history.push("/");
  }

  return (
    <Container>
      <ProtectedBox className="box">
        <div>
          <span>CPF</span>
          <input
            type="text"
            disabled
            value={formatString("XXX.XXX.XXX-XX", user.cpf) || ""}
          />
        </div>
        <div>
          <span>Nome Completo</span>
          <input type="text" disabled value={user.name || ""} />
        </div>
        <div>
          <span>Unidade</span>
          <input type="text" disabled value={user.mainContract || ""} />
        </div>
        <div>
          <span>E-mail</span>
          <input type="text" disabled value={user.email || ""} />
        </div>
      </ProtectedBox>
      <SubTitle>Dados de Acesso</SubTitle>
      <AllowedBox className="box">
        <div>
          <span>Usuário (Nome completo)</span>
          <input
            type="text"
            value={inputName}
            onChange={e => setInputName(e.target.value)}
          />
        </div>
        <div>
          <span>Telefone</span>
          <input
            type="text"
            value={inputPhone}
            onChange={e => setInputPhone(e.target.value)}
          />
        </div>
        <div>
          <span>Telefone Celular (Opcional)</span>
          <input
            type="text"
            value={inputMobile}
            onChange={e => setInputMobile(e.target.value)}
          />
        </div>
      </AllowedBox>
      <LineSeparator />
      <PasswordBox className="box">
        <div>
          <span>Senha</span>
          <input
            type="password"
            value={inputPassword}
            onChange={e => setInputPassword(e.target.value)}
          />
        </div>
        <div>
          <span>Confirmar Senha</span>
          <input
            type="password"
            value={inputPasswordConfirmation}
            onChange={e => setInputPasswordConfirmation(e.target.value)}
          />
        </div>
      </PasswordBox>

      <ButtonBox>
        <Button
          onClick={handleSaveData}
          value="Salvar alterações"
          disabled={validForm ? null : true}
          className="actionButton"
        />
      </ButtonBox>
    </Container>
  );
}
