import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import formatString from "format-string-by-pattern";
import api from "../../services/api";
import ListOfStamps from "../../components/stamp/List";
import { formatPrice } from "../../util/format";
import numbersOnly from "../../util/numbersOnly";
import Wallet from "../../components/Wallet";
import {
  addToCartValidateSuccess,
  setTicket,
  clear,
  processingTicket,
} from "../../store/modules/cartValidate/actions";

import { reload } from "../../store/modules/wallet/actions";

import {
  Container,
  Content,
  Filters,
  Body,
  TicketInput,
  DefaultInputBox,
  InputBox,
  Input,
  Title,
  LeftBox,
  RightBox,
  MiniBox,
  WalletBox,
  TotalBox,
  StampsSection,
  FooterContent,
  Footer,
  Button,
  StatusProcessing,
} from "./style";
import { errorMessage, successMessage } from "../../util/toastNotification";

export default function Validate() {
  const dispatch = useDispatch();
  const [stamps, setStamps] = useState([]);
  const { totalHours, totalStamps } = useSelector(state => state.wallet);
  const [ticketInput, setTicketInput] = useState("");
  const [loadingTicket, setLoadingTicket] = useState(false);
  const { wallet } = useSelector(state => state.wallet);
  const {
    ticket: ticketInfo,
    hoursMinutesLeft,
    minutesLeft,
    stamps: cartStamps,
    processing,
  } = useSelector(state => state.cartValidate);
  const [observationInput, setObservationInput] = useState("");
  const [loadingValidating, setLoadingValidating] = useState(false);

  // Zerar consulta na troca de contrato

  useEffect(() => {
    setStamps([]);
    setStamps(
      wallet.map(item => {
        return {
          partNo: item.stampId,
          partTime: item.hourValue * 60 + item.minuteValue,
          partValue: false,
          formattedPrice: false,
          price: false,
          id: item.stampId,
          hourValue: item.hourValue,
          minuteValue: item.minuteValue,
          limit: item.quantity,
        };
      })
    );
  }, [wallet]);

  async function getTicketInfo() {
    setLoadingTicket(true);
    try {
      const { data } = await api.get(`ticketValidate?ticket=${ticketInput}`);
      const { ticketInfo: info } = data;
      const {
        entryTime: entranceTime,
        currentTime,
        totalHours: hours,
        minutes,
        totalMinutes: totalMinutesCost,
      } = info;
      const costTime = `${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

      dispatch(
        setTicket({
          entranceTime,
          currentTime,
          costTime,
          totalMinutesCost,
        })
      );
    } catch ({ response }) {
      errorMessage({
        message:
          response &&
          response.data &&
          response.data.error &&
          typeof response.data.error === "string"
            ? response.data.error
            : "Falha ao obter informações do Ticket",
      });
    }

    setLoadingTicket(false);
  }

  const cartQuantity = useSelector(state =>
    state.cartValidate.stamps.reduce((quantity, stamp) => {
      quantity[stamp.id] = stamp.quantity;

      return quantity;
    }, {})
  );

  const cartTotalStamps = useSelector(state =>
    state.cartValidate.stamps.reduce((total, stamp) => {
      total[stamp.id] = formatPrice(stamp.total);

      return total;
    }, {})
  );

  const handleAddStamp = useCallback(
    (stamp, quantity) => {
      if (!ticketInfo || !ticketInfo.entranceTime) {
        errorMessage({ message: "Por favor carregue o ticket primeiro" });
        return;
      }
      /* if (hoursMinutesLeft === "0:00") {
        errorMessage({ message: "As horas do ticket já foram zeradas" });
        return;
      } */
      if (quantity > stamp.limit) {
        return;
      }
      const data = {
        id: stamp.id,
        quantity,
        hourValue: stamp.hourValue,
        minuteValue: stamp.minuteValue,
        price: stamp.price,
        total: quantity * stamp.price,
      };

      dispatch(addToCartValidateSuccess(data));
    },
    [dispatch, ticketInfo]
  );

  const handleRemoveStamp = useCallback(
    (stamp, quantity) => {
      if (!ticketInfo || !ticketInfo.entranceTime) {
        errorMessage({ message: "Por favor carregue o ticket primeiro" });
        return;
      }
      if (quantity < 0) {
        return;
      }
      const data = {
        id: stamp.id,
        quantity,
        hourValue: stamp.hourValue,
        minuteValue: stamp.minuteValue,
        price: stamp.price,
        total: quantity * stamp.price,
      };

      dispatch(addToCartValidateSuccess(data));
    },
    [dispatch, ticketInfo]
  );

  async function handleValidate() {
    setLoadingValidating(true);
    try {
      const request = {
        ticketNumber: ticketInput,
        observation: observationInput,
        stamps: cartStamps.map(stamp => ({
          stamp: stamp.id,
          qtd: stamp.quantity,
        })),
      };
      const { data } = await api.post("ticketValidate", request);
      successMessage({ message: data.message });
      dispatch(clear());
      setTimeout(() => {
        dispatch(processingTicket());
      }, 500);
      setObservationInput("");
      setTicketInput("");
    } catch ({ response }) {
      errorMessage({
        message:
          response && response.data && response.data.error
            ? response.data.error
            : "Falha ao validar ticket",
      });
    } finally {
      setLoadingValidating(false);
      setTimeout(() => {
        dispatch(reload());
      }, 700);
    }
  }

  return (
    <Container>
      <Content>
        <Title>Validar selo digital</Title>
        <Filters>
          <InputBox>
            <span>Número do ticket</span>
            <TicketInput>
              <Input
                type="text"
                placeholder="0000000000-00"
                value={formatString("XXXXXXXXXX-XX", ticketInput)}
                onChange={e => setTicketInput(numbersOnly(e.target.value))}
                maxLength={13}
              />
              <button type="button" onClick={getTicketInfo}>
                {!loadingTicket ? (
                  "Ok"
                ) : (
                  <ReactLoading
                    type="bars"
                    color="#fff"
                    height={24}
                    width={24}
                  />
                )}
              </button>
            </TicketInput>
          </InputBox>
          <InputBox>
            <span>Justificativa (opcional)</span>
            <DefaultInputBox>
              <Input
                type="text"
                value={observationInput}
                onChange={e => setObservationInput(e.target.value)}
              />
            </DefaultInputBox>
          </InputBox>
        </Filters>
        <Body>
          <LeftBox>
            <MiniBox>
              <span>Hora de entrada</span>
              <h3>{ticketInfo.entranceTime || "-"}</h3>
            </MiniBox>
            <MiniBox>
              <span>Hora atual</span>
              <h3>{ticketInfo.currentTime || "-"}</h3>
            </MiniBox>
            <MiniBox>
              <span>Tempo de estadia</span>
              <h3>{ticketInfo.costTime || "-"}</h3>
            </MiniBox>
          </LeftBox>
          <RightBox>
            <WalletBox>
              <Wallet />
            </WalletBox>
            <TotalBox>
              <span>Saldo atual</span>
              <div>
                <div>
                  <h3>{totalStamps}</h3>
                  <span>selos</span>
                </div>
                <div>
                  <h3>{totalHours}</h3>
                  <span>horas</span>
                </div>
              </div>
            </TotalBox>
          </RightBox>
        </Body>
        <StampsSection>
          <h2>Selecione abaixo como deseja abonar o tempo de estadia</h2>
          <ListOfStamps
            stamps={stamps}
            handleAddStamp={handleAddStamp}
            handleRemoveStamp={handleRemoveStamp}
            cartQuantity={cartQuantity}
            cartTotal={cartTotalStamps}
          />
        </StampsSection>
      </Content>
      <Footer>
        {processing ? (
          <StatusProcessing>
            <span>
              Há um ticket sendo processado no momento. Aguarde alguns
              instantes.
            </span>
          </StatusProcessing>
        ) : (
          <FooterContent extra={minutesLeft < 0}>
            {minutesLeft >= 0 ? (
              <span>Tempo restante para validação</span>
            ) : (
              <span>Tempo Extra</span>
            )}
            <h2>{hoursMinutesLeft}</h2>
            <b>min</b>
            <Button
              onClick={handleValidate}
              value="Validar"
              loading={loadingValidating}
            />
          </FooterContent>
        )}
      </Footer>
    </Container>
  );
}
