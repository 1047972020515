import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import pt from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";

import api from "../../../services/api";

import { successMessage, errorMessage } from "../../../util/toastNotification";
import Button from "../../defaultComponents/Button";
import { closeModal } from "../../../store/modules/modalTicketSendSms/actions";
import { Modal, Box, PhoneInput, LinksBox } from "./style";

export default function TicketSendSms() {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [smsStatus, setSmsStatus] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [smsError, setError] = useState(false);
  const [smsMessage, setMessage] = useState("");
  const { opened, title, text, notificationId, request } = useSelector(
    state => state.modalTicketSendSms
  );
  const { site, ticketNumber } = request;

  useEffect(() => {
    if (!site || !ticketNumber) {
      return;
    }

    async function load() {
      setPageLoading(true);
      setCanSend(false);
      setError(false);
      setMessage("");
      const { data } = await api.get(
        `ticket/get-sms-status?site=${site}&ticketNumber=${ticketNumber}`
      );
      if (data.smsStatus) {
        setSmsStatus(data.smsStatus);
      }
      setPageLoading(false);
    }

    load();
  }, [site, ticketNumber]);

  useEffect(() => {
    if (!smsStatus) return;
    const { needToResend, alreadySent, message, error } = smsStatus;
    if (!alreadySent || needToResend) {
      setCanSend(true);
    }
    if (alreadySent) {
      setMessage(message);
      setError(error === true);
    }
  }, [smsStatus]);

  function onClose() {
    dispatch(closeModal());
  }

  async function sendSms() {
    try {
      setLoading(true);

      const { data } = await api.post("ticket/send-sms", {
        notificationId,
        request,
        phone,
      });

      const { message } = data;
      successMessage({ message });

      setTimeout(() => {
        onClose();
        setLoading(false);
      }, 500);
    } catch (e) {
      errorMessage({
        message: "Falha ao enviar sms. Por favor tente novamente em instantes",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal opened={opened} onClose={onClose} closeOnDocumentClick>
      <i className="fas fa-exclamation warning" />
      <h2>{title}</h2>
      <p>{text}</p>
      {smsMessage && (
        <Box error={smsError}>
          <p>{smsMessage}</p>
        </Box>
      )}
      {canSend && (
        <Box>
          <>
            <p>Deseja enviar ao cliente os códigos de barras via SMS</p>
            <PhoneInput
              country="br"
              value={phone}
              onChange={e => setPhone(e)}
              localization={pt}
              inputProps={{
                name: "Celular",
              }}
            />
          </>
        </Box>
      )}
      <LinksBox>
        {pageLoading ? (
          <span>Aguarde enquanto o status do sms é consultado...</span>
        ) : (
          <>
            <Button className="light" value="Cancelar" onClick={onClose} />
            <Button
              className="light"
              value="Ok"
              onClick={() => sendSms()}
              loading={loading}
            />
          </>
        )}
      </LinksBox>
    </Modal>
  );
}
