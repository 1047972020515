export const Cartao = {
  id: 2,
  title: "Cartão de crédito",
  token: "CARTAO",
};

export const Boleto = {
  id: 1,
  title: "Boleto",
  token: "BOLETO",
};
