import styled, { css } from "styled-components";
import { COLORS } from "../../../../styles/defaults";

import arrowTop from "../../../../assets/images/arrow-top.svg";
import arrowTopGrey from "../../../../assets/images/arrow-top-grey.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  margin-top: 35px;
`;

export const AccordionContainer = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  margin-top: 10px;
`;

export const AccordionContent = styled.div`
  padding: 10px;
`;

export const ListValuesTitle = styled.div`
  width: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  color: ${COLORS.$darkGray};
  position: relative;
  > div {
    display: flex;
    align-items: center;
    > h3 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      margin-right: 15px;
    }

    > h4 {
      margin-right: 35px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &:after {
    content: url(${({ grey }) => (grey ? arrowTopGrey : arrowTop)});
    transition: transform 150ms ease;
    transform: rotate(180deg);
    margin-left: 20px;
    position: absolute;
    right: 10px;
  }

  > span {
    margin-right: 40px;
    text-transform: uppercase;
    font-size: 14px;
    strong {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const AccordionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 47px;
  cursor: pointer;
`;

export const ListValuesTitleWrapper = styled(AccordionTitleWrapper)`
  ${ListValuesTitle} {
  }

  ${({ open }) =>
    open &&
    css`
      ${ListValuesTitle} {
        &:after {
          transform: rotate(0);
        }
      }
    `}
`;

export const Total = styled.span`
  margin-right: 40px;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
  strong {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const EmptyContent = styled.div`
  width: 100%;
  max-width: 1320px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 25px;
  > span {
    color: ${COLORS.$darkBlue};
    font-size: 22px;
  }
`;
