import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  height: 70px;
`;

export const Logo = styled.div`
  color: blue;
  display: flex;
  justify-content: center;
  width: 250px;
  background-color: #00205b;
  z-index: 1;
  width: 100%;
`;
