import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid #000;
  position: relative;
  height: 38px;
  width: 300px;
  z-index: 1;
  /*flex: 1;*/

  .invalid {
    border: 2px solid red;
    border-radius: 4px;
  }

  /*input:focus {
    border: 3px solid #1a73e8;
    border-radius: 4px;
  }*/

  input {
    border: none;
    /*border-bottom: 1px solid #ccc;*/
    color: #202124;
    font-size: 16px;
    /*height: 100%;*/
    width: 100%;
    height: 100%;
    position: relative;
    padding-left: 17px;
    padding-top: 2px;
    z-index: 2;
    background: ${props =>
        props.backgroundImage ? `url(${props.backgroundImage})` : ""}
      no-repeat right 10px center;
  }

  label {
    font-size: 16px;
    position: absolute;
    left: 10px;
    background: #fff;
    transition: all 0.2s;
    cursor: text;
    top: -30%;
    z-index: 2;
    padding: 0 7px;
    font-size: 12px;
  }
`;

export const Error = styled.span`
  color: red;
`;
