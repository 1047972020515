export default user => {
  const result = {
    name: user.name || "",
    firstName: user.name !== "" ? user.name.split(" ")[0] : "",
    email: user.email || "",
    cpf: user.cpf || "",
    id: user.id || "",
    customerNo: user.customerNo || "",
    customerOwnerNo: user.customerOwnerNo || "",
    active: user.active || "",
    master: user.master || "",
    mainPhone: user.mainPhone || "",
  };

  return result;
};
