import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
  > button {
    height: 53px;
    width: 368px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #00205b;
    font-size: 22px;
    font-weight: bold;
    color: #00205b;
    border-radius: 5px;
    background: transparent;
    transition: ease 0.4s;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    &:hover {
      background: ${props =>
        props.disabled ? "transparent" : COLORS.$darkBlue};
      color: ${props => (props.disabled ? COLORS.$darkBlue : "#fff")};
      transition: ease 0.4s;
    }
  }
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 25px;
  > span {
    color: ${COLORS.$darkBlue};
    font-size: 22px;
  }
`;

export const BoxLoading = styled.div`
  background-color: #fff;
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  > div {
    width: 100%;
    max-width: 1320px;
  }
`;
