import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./style";
import Header from "../../../components/SpecialHeader";

export default function DefaultLayout({ children }) {
  return (
    <>
      <Header />
      <Wrapper>{children}</Wrapper>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
