import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemText from "@material-ui/core/ListItemText";
import { CreditCard, ExitToApp, GroupAdd, Person } from "@material-ui/icons";
import { signOut } from "../../../store/modules/auth/actions";
import history from "../../../services/history";

import { Container, UserBox, User, ListItemIcon, Notifications } from "./style";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    /* "&:focus": {
      backgroundColor: "#00205b",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    }, */
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuClick(menu) {
    history.push(menu);
    handleClose();
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  /* <img
            src="https://avatars3.githubusercontent.com/u/12786786?v=4"
            alt="Avatar"
          /> */

  return (
    <Container>
      <Notifications />
      <UserBox onClick={handleClick}>
        <User>
          <div>
            <span>
              {user && user.firstName ? user.firstName.substr(0, 1) : ""}
            </span>
          </div>
          <span>
            {user.firstName}
            <i className="fas fa-chevron-down" />
          </span>
        </User>
      </UserBox>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => handleMenuClick("/profile")}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Meus Dados" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleMenuClick("/cards")}>
          <ListItemIcon>
            <CreditCard fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Meus Cartões de Crédito" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleMenuClick("/users")}>
          <ListItemIcon>
            <GroupAdd fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Gerenciar Usuários" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </StyledMenuItem>
      </StyledMenu>
    </Container>
  );
}
