import React, { useState } from "react";

import ReactLoading from "react-loading";
import { Container, Button } from "./style";
import api from "../../services/api";
import history from "../../services/history";
import { successMessage } from "../../util/toastNotification";

export default function Contact() {
  const [inputSubject, setInputSubject] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSendMessage() {
    if (inputMessage === "" || inputSubject === "") {
      return;
    }

    setLoading(true);
    const request = {
      subject: inputSubject,
      content: inputMessage,
    };

    const { data } = await api.post("contact-us", request);
    successMessage({ message: data.message });
    setLoading(false);
    history.push("/");
  }
  return (
    <Container>
      <span>Assunto</span>
      <input
        type="text"
        onChange={e => setInputSubject(e.target.value)}
        value={inputSubject}
      />
      <span className="marginTop">Mensagem</span>
      <textarea
        rows="10"
        onChange={e => setInputMessage(e.target.value)}
        value={inputMessage}
      />
      <Button
        onClick={loading ? () => {} : handleSendMessage}
        disabled={inputSubject !== "" && inputMessage !== "" ? null : true}
        loading={loading}
      >
        {loading ? (
          <ReactLoading type="bars" color="#fff" height={24} width={24} />
        ) : (
          "Enviar mensagem"
        )}
      </Button>
    </Container>
  );
}
