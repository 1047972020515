import React from "react";
import PropTypes from "prop-types";

import StarIcon from "@material-ui/icons/Star";
import { ChakraProvider, Box, Tooltip } from "@chakra-ui/react";
import { Container, Content, EspecialBox } from "./style";

export default function Shipment({ selected, shipment, handleSelectShipment }) {
  let toolTipLabel;
  const pdfWarn = "Observação: Essa opção NÃO gera PDF!";

  if (shipment.description === "Selo Digital") {
    toolTipLabel =
      `Para abonar o valor integral ou parcial do ticket nesta opção, você deve realizar a validação dos selos através do nosso portal na aba "VALIDAR" . Esta opção não gera selos PDF.`;
  } else if (shipment.description === "Arquivo PDF") {
    toolTipLabel =
      `Para opção PDF você receberá os selos via e-mail, para utilização basta imprimir a página, recortar no pontilhado e anexar o selo ao ticket que deseja abonar.`
  }  else {
    toolTipLabel =
    `Nessa opção você receberá selos adesivos que deverão ser colados no ticket a ser abonado.`
      // "Dá a liberdade da empresa colar o selo juntamente ao ticket do cliente e no guichê da garagem se isentado de pagamento de toda a estadia ou parte dela.";
  }

  return (
    <ChakraProvider>
      <Container>
        <Content
          selected={selected}
          bestOption={shipment.bestOption}
          onClick={() => handleSelectShipment(shipment)}
        >
          <div className="box">
            <div className="left">
              {selected ? (
                <i className="far fa-check-circle" />
              ) : (
                <i className="far fa-circle" />
              )}
            </div>
            <div className="center">
              <h1>{shipment.description}</h1>
              <h2>{shipment.type}</h2>
              <h3>{shipment.deliveryTime}</h3>
              {shipment.description === "Selo Digital" && selected ? (
                <Box mt={15} p={5} bg="yellow.100" borderRadius={13}>
                  DIsponível <strong>apenas dentro do portal de selos
                  Netpark.</strong> Caso deseje o arquivo PDF, selecione a opção "Arquivo
                  PDF
                </Box>
              ) : (
                <></>
              )}
            </div>
            <div className="right">
              <Tooltip
                hasArrow
                label={toolTipLabel}
                color="black"
                width={300}
                placement="top"
                p={3}
                borderRadius={13}
                background="Highlight"
              >
                <div>
                  <i className="fas fa-question-circle" />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="footer">{shipment.formattedPrice}</div>
        </Content>
        {shipment.bestOption && (
          <EspecialBox>
            <StarIcon />
            <span>Melhor opção</span>
          </EspecialBox>
        )}
      </Container>
    </ChakraProvider>
  );
}

Shipment.propTypes = {
  selected: PropTypes.bool,
  shipment: PropTypes.shape({
    description: PropTypes.string,
    type: PropTypes.string,
    deliveryTime: PropTypes.string,
    formattedPrice: PropTypes.string,
    bestOption: PropTypes.bool,
  }).isRequired,
  handleSelectShipment: PropTypes.func.isRequired,
};

Shipment.defaultProps = {
  selected: false,
};
