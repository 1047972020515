import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import AuthLayout from "../pages/_layouts/auth";
import DefaultLayout from "../pages/_layouts/default";
import BarcodeLayout from "../pages/_layouts/special";

import { store } from "../store";
import { selectPage } from "../store/modules/header/actions";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  special,
  path,
  type,
  ...rest
}) {
  const { signed } = store.getState().auth;

  if (!signed && isPrivate && !special) {
    return <Redirect to="/login" />;
  }

  if (signed && !isPrivate && !special) {
    return <Redirect to="/" />;
  }

  store.dispatch(selectPage(path));

  let Layout = signed ? DefaultLayout : AuthLayout;
  if (path === "/barcode") {
    Layout = BarcodeLayout;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: true,
};
