import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MaterialCheckBox from "@material-ui/core/Checkbox";
import { green, grey } from "@material-ui/core/colors";
import { COLORS } from "../../../styles/defaults";

export const Checkbox = withStyles({
  root: {
    color: grey[250],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})(MaterialCheckBox);

export const ContentModal = styled.ul`
  display: flex;
  flex-direction: column;
  h3 {
    color: ${COLORS.$darkBlue};
    font-size: 20px;
    margin-bottom: 14px;
    text-align: center;
  }
  p {
    color: ${COLORS.$darkGray};
    font-size: 14px;
    margin-bottom: 8px;
    text-align: justify;
  }
  a {
    color: ${COLORS.$darkBlue};
    margin-top: 20px;
  }
  .wrapper-checkbox {
    margin-top: 20px;
  }
`;
