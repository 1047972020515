import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Container, ContentLoading } from "./style";
import { formatPrice } from "../../../util/format";
import LoadingStamps from "../LoadingStamps";

import Stamp from "../Stamp";

export default function List({
  stamps,
  handleAddStamp,
  handleRemoveStamp,
  loading,
  cartQuantity,
  cartTotal,
}) {
  return (
    <>
      {loading ? (
        <ContentLoading>
          <LoadingStamps />
        </ContentLoading>
      ) : (
        <Container>
          {stamps.map(stamp => (
            <Stamp
              key={stamp.id}
              stamp={stamp}
              quantity={cartQuantity[stamp.id]}
              total={cartTotal[stamp.id]}
              handleAddStamp={handleAddStamp}
              handleRemoveStamp={handleRemoveStamp}
            />
          ))}
        </Container>
      )}
    </>
  );
}

List.propTypes = {
  stamps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      hourValue: PropTypes.number,
      formattedPrice: PropTypes.string,
    })
  ).isRequired,
  handleAddStamp: PropTypes.func.isRequired,
  handleRemoveStamp: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

List.defaultProps = {
  loading: false,
};
