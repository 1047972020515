import styled from "styled-components";
import { COLORS } from "../../../styles/defaults";
import arrowDown from "../../../assets/images/chevron-down-solid.svg";

export const Select = styled.select`
  border: none;
  border-radius: 30px;
  padding: 0 60px 0 18px;
  background: url(${arrowDown}) no-repeat right center;
  background-size: 30% 45%;
  background-color: ${COLORS.$baseWhiteBackground};
  appearance: none;
`;
