import styled from "styled-components";

export const ContentLoading = styled.div`
  /*background: transparent;*/
  padding-top: 25px;
  height: 330px;
  width: 100%;
  > svg {
    height: 330px;
    max-width: 1200px;
  }
`;

export const Container = styled.div`
  background: transparent;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(6, 1fr);
  /*grid-gap: 20px;*/

  @media only screen and (max-width: 1180px) {
    grid-template-columns: repeat(5, 1fr);
    /*grid-gap: 20px;*/
  }

  @media only screen and (max-width: 998px) {
    grid-template-columns: repeat(4, 1fr);
    /*grid-gap: 5px;*/
  }

  margin-top: 20px;
  margin-bottom: 25px;
  @media only screen and (max-width: 760px) {
    display: flex;
    justify-content: space-between;
    margin: 0 0;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    max-width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
