import styled from "styled-components";

export const Container = styled.table`
  width: 100%;

  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;

  border-collapse: separate;
  border: solid #ccc 1px;
  border-radius: 8px;

  thead {
  }

  th {
    color: #565656;
    padding: 10px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
    border-radius: 2px;
    vertical-align: middle;
    display: table-cell;
    background: #eeeff0;
    &:first-child {
    }
     {
      border-radius: 8px 0 0 0;
    }
    &:last-child {
      border-radius: 0 8px 0 0;
    }
  }
  tbody {
    tr {
      border-top: 1px solid #bababa;
    }
    td {
      color: #656565;
      text-align: right;
      padding: 10px;
      font-size: 12px;
      vertical-align: middle;
      border-radius: 2px;
      display: table-cell;
    }
  }

  tr {
    td:nth-child(1),
    th:nth-child(1) {
      width: 160px;
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 160px;
    }
    td:nth-child(3),
    th:nth-child(3) {
      width: 150px;
    }
    td:last-child,
    th:last-child {
      width: 270px;
    }
  }
`;
