import styled from "styled-components";
import DefaultModal from "../DefaultModal/index2";
import { LinksBox as DefaultLinksBox } from "../DefaultModal/style";
import DefaultButton from "../../defaultComponents/Button";

export const LinksBox = styled(DefaultLinksBox)`
  justify-content: flex-end;
  margin-top: 25px;
`;

export const LinksRecover = styled(DefaultLinksBox)`
  justify-content: space-around;
  max-width: 70%;
  @media only screen and (max-width: 540px) {
    max-width: 100%;
    justify-content: space-between;
  }
`;

export const Modal = styled(DefaultModal)`
  max-width: 700px;
  @media only screen and (max-width: 720px) {
    padding: 30px 0;
  }
  input[type="button"] {
    margin-top: 25px;
  }

  .regulationLink {
    align-self: flex-start;
    margin-top: 15px;
    color: blue;
  }

  ._body_modal {
    padding: 10px 30px;
  }

  z-index: 1001;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    color: #707070;
    font-size: 18px;
    /*Helvetica*/
  }
`;

export const Button = styled(DefaultButton)`
  width: 120px;
  height: 35px;
`;
