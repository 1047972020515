import React, { useState, useEffect, useCallback } from "react";
import { FormControlLabel } from "@material-ui/core";
import { useSelector } from "react-redux";
import { successMessage, errorMessage } from "../../../util/toastNotification";

import DefaultInfoModal from "../DefaultInfoModal";

import {
  isThereTermsToAccept,
  getTerms,
  acceptTerms,
} from "../../../services/user/terms";

import { ContentModal, Checkbox } from "./style";

const TermsModal = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [terms, setTerms] = useState(null);
  const [checked, setChecked] = useState(false);
  const { user } = useSelector(state => state.session);
  const [loading, setLoading] = useState(false);

  const onClose = useCallback(async () => {
    setLoading(true);
    try {
      if (!checked) {
        errorMessage({
          message: "Você precisa aceitar os termos para continuar",
        });
        return;
      }
      const { termsKey, uid } = terms;
      const accepted = await acceptTerms({
        termsKey,
        uid,
        email: user.email,
      });
      if (accepted) {
        successMessage({
          message: "Você aceitou os termos de uso do Portal de Selos Netpark",
        });
        setModalOpened(false);
        return;
      }
      errorMessage({
        message:
          "Falha ao aceitar os termos. Por favor tente novamente ou entre em contato com o suporte",
      });
    } catch (e) {
      errorMessage({
        message:
          "Falha ao aceitar os termos. Por favor tente novamente ou entre em contato com o suporte",
      });
    } finally {
      setLoading(false);
    }
  }, [checked, terms, user.email]);

  useEffect(() => {
    if (!user || !user.email) return;

    const load = async () => {
      const isThereATermToAccept = await isThereTermsToAccept(user.email);
      if (isThereTermsToAccept) {
        const tmpTerms = await getTerms(user.email);
        setTerms(tmpTerms);
      }
      setModalOpened(isThereATermToAccept);
    };

    load();
  }, [user]);

  if (!terms) return null;

  return (
    <DefaultInfoModal
      opened={modalOpened}
      onClose={onClose}
      title="Termos de uso Portal de Selos Netpark"
      closeOnDocumentClick={false}
      loading={loading}
    >
      <ContentModal>
        <h3>Seja bem vindo ao Portal de Selos Netpark!</h3>
        <p>
          Informamos que alteramos nossos termos de uso de dados do Portal de
          Selos NetPark. Para continuar você precisa aceitar nosso novo termo.
        </p>
        <a href={terms.termsLink} target="_blank" rel="noopener noreferrer">
          Clique aqui para visualizar os termos de uso.
        </a>
        <div className="wrapper-checkbox">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            }
            label="Li e aceito os termos de uso."
          />
        </div>
      </ContentModal>
    </DefaultInfoModal>
  );
};

export default TermsModal;
