import styled from "styled-components";

export const Container = styled.div`
  label {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  input {
    display: none;
    &:checked ~ span {
      background: #00205b;
      transform: rotate(-45deg) translate(4px, 4px);
    }
    &:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:checked ~ span:nth-last-child(2) {
      transform: rotate(45deg) translate(8px, 8px);
    }
  }
  span {
    display: block;
    width: 33px;
    height: 4px;
    background: #cdcdcd;
    border-radius: 3px;
    margin-bottom: 5px;
    transform-origin: 4px 0;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 100% 0%;
    }
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
`;
