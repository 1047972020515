import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
import api from "../../../services/api";

import { print, savePdf } from "../../../util/pdf";
import Wallet from "../../../components/Wallet";
import ExtractList from "../../../components/extract/pre/List";
import Title from "../../../components/defaultComponents/Title";
import UsersSelect from "../../../components/defaultComponents/UsersSelect";
import DaysFilterSelect from "../../../components/defaultComponents/DaysFilterSelect";
import {
  setSelectedUser,
  setSelectedDaysFilter,
} from "../../../store/modules/extract/actions";
import {
  Container,
  Content,
  Header,
  Body,
  Box,
  WalletBox,
  ActionBox,
  ActionButton,
  FilterBox,
  TitleBox,
  MiniBox,
  LoadMore,
  BoxOrder,
  DownloadButton,
} from "./style";
import { errorMessage } from "../../../util/toastNotification";

export default function Pre() {
  const dispatch = useDispatch();
  const { order } = useParams();
  const { selectedContract } = useSelector(state => state.session);
  const { selectedUser, selectedDaysFilter } = useSelector(
    state => state.extract
  );
  const { totalHours, totalStamps } = useSelector(state => state.wallet);
  const [loadingExtract, setLoadingExtract] = useState(false);
  const [page, setPage] = useState(1);
  const [extract, setExtract] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const loadExtract = useCallback(async function load(
    pageDesired,
    userId,
    days
  ) {
    setLoadingExtract(true);
    let url = `extract?page=${pageDesired}`;
    if (userId) {
      url += `&customerNo=${userId}`;
    }
    url += `&days=${days || 60}`;
    const { data } = await api.get(url);
    setLoadingExtract(false);
    if (data && data.extract) {
      return data.extract;
    }

    return [];
  },
  []);

  const loadOrder = useCallback(async function load(orderNo) {
    setLoadingExtract(true);
    const { data } = await api.get(`extract/${orderNo}`);
    setLoadingExtract(false);
    if (data && data.extract) {
      return data.extract;
    }
    return [];
  }, []);

  async function loadMore() {
    const newItems = await loadExtract(
      page + 1,
      selectedUser.value > -1 ? selectedUser.value : false,
      selectedDaysFilter.value
    );
    setPage(page + 1);
    setExtract([...extract, ...newItems]);
    if (!newItems || newItems.length === 0) {
      setHasMore(false);
    }
  }

  useEffect(() => {
    if (order) return;

    if (!selectedContract || !selectedContract.contract) {
      return;
    }
    setExtract([]);

    async function initExtract() {
      const items = await loadExtract(
        1,
        selectedUser.value > -1 ? selectedUser.value : false,
        selectedDaysFilter.value
      );
      setExtract(items);
      setPage(1);
      setHasMore(true);
    }

    initExtract();
  }, [
    loadExtract,
    order,
    selectedContract,
    selectedDaysFilter.value,
    selectedUser.value,
  ]);

  useEffect(() => {
    if (!order) {
      return;
    }
    setExtract([]);

    async function internalLoadOrder() {
      const items = await loadOrder(order);
      if (!items || items.length === 0) {
        errorMessage({ message: "Extrato não encontrado" });
        return;
      }
      setExtract(items);
    }
    internalLoadOrder();
  }, [loadOrder, order]);

  function setUser(user) {
    dispatch(setSelectedUser(user));
  }

  function setFilter(user) {
    dispatch(setSelectedDaysFilter(user));
  }

  async function download() {
    setDownloading(true);
    const response = await api.get("extractdownload", {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "extrato.xlsx");
    document.body.appendChild(link);
    link.click();

    setDownloading(false);
  }

  const extractRef = useRef(null);

  return (
    <Container>
      <Content>
        <Header>
          <Box className="leftBox">
            <TitleBox>
              <Title>Extrato</Title>
              <DaysFilterSelect
                valueState={selectedDaysFilter}
                setValueState={e => setFilter(e)}
              />
            </TitleBox>
            <FilterBox>
              <span>Filtrar por</span>
              <UsersSelect
                valueState={selectedUser}
                setValueState={e => setUser(e)}
              />
            </FilterBox>
            <ActionBox>
              <ReactToPrint
                trigger={() => (
                  <ActionButton>
                    <i className="fas fa-print" /> Imprimir
                  </ActionButton>
                )}
                content={() => extractRef.current}
              />
              <ActionButton
                onClick={() => {
                  savePdf(extractRef.current);
                }}
              >
                <i className="far fa-file" />
                Salvar pdf
              </ActionButton>
              <DownloadButton
                onClick={download}
                type="button"
                disabled={downloading}
              >
                <i className="fa fa-download" />
                {!downloading ? "Salvar excel" : "Aguarde ..."}
              </DownloadButton>
            </ActionBox>
          </Box>
          <Box className="middleBox">
            <Title className="">Saldo restante</Title>
            <WalletBox>
              <Wallet className="boxSize" />
            </WalletBox>
          </Box>
          <Box className="rightBox">
            <Title className="">Saldo total</Title>
            <div>
              <MiniBox className="leftRadius">
                <h2>{totalStamps}</h2>
                <span>selos</span>
              </MiniBox>
              <MiniBox className="rightRadius">
                <h2>{totalHours}</h2>
                <span>horas</span>
              </MiniBox>
            </div>
          </Box>
        </Header>
        <Body ref={extractRef}>
          {order && (
            <BoxOrder>
              <span>Ordem {order}</span>
            </BoxOrder>
          )}
          <ExtractList loading={loadingExtract} extract={extract} />
          {!order && (
            <LoadMore disabled={!hasMore || loadingExtract}>
              <button
                value="Load"
                onClick={loadMore}
                type="button"
                disabled={!hasMore || loadingExtract}
              >
                {loadingExtract ? (
                  <ReactLoading
                    type="bars"
                    color="blue"
                    height={24}
                    width={24}
                  />
                ) : (
                  "Carregar mais resultados"
                )}
              </button>
            </LoadMore>
          )}
        </Body>
      </Content>
    </Container>
  );
}
