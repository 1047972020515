import React from "react";

import { Container, Logo } from "./style";

import logo from "../../assets/images/logo-header.jpg";

export default function SpecialHeader() {
  return (
    <>
      <Container>
        <Logo>
          <img src={logo} alt="Netpark" />
        </Logo>
      </Container>
    </>
  );
}
