import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import ReactLoading from "react-loading";

import { Table as TableComponent, TableBox, ActionBox } from "./style";

export default function Table({ users, handleChangeUser }) {
  return (
    <TableBox>
      <TableComponent>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Departamento</th>
            <th>Ativo?</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, key) => (
            <tr key={user.customerNo}>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.department}</td>
              <td>
                <ActionBox loading={user.loading}>
                  {user.loading ? (
                    <ReactLoading
                      type="spin"
                      color="blue"
                      height={20}
                      width={20}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user.actived}
                          onChange={() => {
                            handleChangeUser(user.customerNo, !user.actived);
                          }}
                          color="primary"
                        />
                      }
                    />
                  )}
                </ActionBox>
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    </TableBox>
  );
}
