const isRepeatingNumber = str => /^(\d)(\1){13}$/.test(str);

const verifierDigit = digits => {
  let index = 2;
  const reverse = digits.split("").reduce((buffer, number) => {
    return [parseInt(number, 10)].concat(buffer);
  }, []);

  const sum = reverse.reduce((buffer, number) => {
    buffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
};

export default input => {
  if (!input) {
    return false;
  }
  const stripped = input.replace(/[^\d]+/g, "");

  // CNPJ must be defined
  if (!stripped) {
    return false;
  }

  // CNPJ must have 14 chars
  if (stripped.length !== 14 || isRepeatingNumber(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};
