import styled from "styled-components";
import { ActionBox as DefaultActionBox } from "../Pre/style";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1320px;
  padding-top: 60px;
  align-items: center;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterBox = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;

  > span {
    margin-right: 12px;
    color: ${COLORS.$darkGray};
    font-size: 14px;
  }

  > div {
    display: flex;
    padding: 0 20px;
    background: #fff;
    border-radius: 30px;

    input {
      border: none;
    }

    input[type="button"] {
      background: transparent;
      color: ${COLORS.$lightGreen};
      cursor: pointer;
    }

    input[type="text"] {
      margin: 8px 0;
      padding-left: 12px;
      width: 200px;
    }
  }
`;

export const ActionBox = styled(DefaultActionBox)`
  margin-top: 0;
`;
