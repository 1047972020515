import React from "react";

import ProfileForm from "../../components/Profile";
import { Container, Content, ContactBox } from "./style";
import Title from "../../components/defaultComponents/Title";

export default function Profile() {
  return (
    <Container>
      <Content>
        <Title>Meus dados</Title>
        <ContactBox>
          <ProfileForm />
        </ContactBox>
      </Content>
    </Container>
  );
}
