import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import CloseIcon from "@material-ui/icons/Close";
import { Container, Content, Header, Body, CloseButton } from "./style2";

export default function DefaultModal({
  opened,
  title,
  children,
  onClose,
  closeOnDocumentClick,
  className,
  showCloseButton,
}) {
  function onEscape({ key }) {
    if (closeOnDocumentClick && key === "Escape") {
      onClose();
    }
  }

  const modal = useRef(null);

  useEffect(() => {
    if (modal && modal.current) {
      modal.current.focus();
    }
  }, [opened]);

  function onModalClick(e) {
    if (closeOnDocumentClick && e.target === modal.current) {
      onClose();
    }
  }

  return (
    <Container
      ref={modal}
      onClick={onModalClick}
      opened={opened}
      onKeyDown={e => onEscape(e)}
      tabIndex="0"
    >
      <Content className={className} showCloseButton={showCloseButton}>
        {title && title !== "" && (
          <Header>
            <h2>{title}</h2>
          </Header>
        )}
        <Body className="_body_modal">{children}</Body>
        {showCloseButton && <CloseButton onClick={onClose}>X</CloseButton>}
      </Content>
    </Container>
  );
}

DefaultModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

DefaultModal.defaultProps = {
  closeOnDocumentClick: true,
  title: false,
  className: "",
  showCloseButton: false,
};
