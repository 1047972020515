import styled from "styled-components";
import AsyncSelect from "react-select-async-paginate";

export const Select = styled(AsyncSelect)`
  > div {
    /*border: 1px solid #000;
    border-radius: 4px;
    height: 35px;
    cursor: pointer;
    font-size: 13px;
    z-index: 3;
    &:hover {
      border: 1px solid #000;
    }*/
    /*background-color: transparent;*/
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    font-size: 16px;
    position: absolute;
    left: 5px;
    background: #f4f4f4;
    color: #454545;
    transition: all 0.2s;
    padding-left: 10px;
    cursor: text;
    top: -30%;
    z-index: 4;
    padding: 0 7px;
    font-size: 12px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
`;
