import React from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";

import {
  Container,
  TableBox,
  Table,
  Field,
  HtmlTooltip,
  InfoBox,
  Item,
  EmptyContent,
} from "./style";

export default function List({ extract, loading, ref }) {
  return (
    <Container ref={ref}>
      {(!extract || extract.length === 0) && !loading && (
        <EmptyContent>
          <span>Não há extrato para o site/filtro selecionado</span>
        </EmptyContent>
      )}
      {extract.map(item => (
        <Item key={`item${item.validateDate}`}>
          <span>{item.validateDate}</span>
          <TableBox>
            <Table>
              <thead>
                <tr>
                  <th>Hora</th>
                  <th>No Ticket</th>
                  <th>Tipo de Selo</th>
                  <th>Observações</th>
                  <th>Usuário</th>
                </tr>
              </thead>
              <tbody>
                {item.orders.map(order => (
                  <tr key={`${order.time}${order.ticket}`}>
                    <td>{order.time}</td>
                    <td>{order.ticket}</td>
                    {order.quantityStamps === 1 ? (
                      <td>{order.types[0]}</td>
                    ) : (
                      <td>
                        <Field>
                          {`${order.quantityStamps} selos`}
                          <HtmlTooltip
                            title={
                              <InfoBox>
                                <h4>Selos Utilizados</h4>
                                {order.stamps.map((stamp, key) => (
                                  <span key={`${stamp}:${key}`}>{stamp}</span>
                                ))}
                              </InfoBox>
                            }
                            placement="right"
                          >
                            <InfoIcon />
                          </HtmlTooltip>
                        </Field>
                      </td>
                    )}
                    <td>{order.obs}</td>
                    <td>{order.user}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableBox>
        </Item>
      ))}
      {loading && <ReactLoading type="spin" color="blue" />}
    </Container>
  );
}

List.propTypes = {
  extract: PropTypes.arrayOf(
    PropTypes.shape({
      validateDate: PropTypes.string,
      orders: PropTypes.array,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  ref: PropTypes.element,
};

List.defaultProps = {
  ref: null,
};
