import produce from "immer";

const INITIAL_STATE = {
  selectedUser: { value: -1, label: "Todos os usuários" },
  selectedDaysFilter: { value: "60", label: "Ultimos 60 dias" },
};

export default function extract(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@extract/SET_SELECTED_USER":
      return produce(state, draft => {
        draft.selectedUser = action.payload.user;
      });
    case "@extract/SET_SELECTED_DAYS_FILTER":
      return produce(state, draft => {
        draft.selectedDaysFilter = action.payload.filter;
      });
    default:
      return state;
  }
}
