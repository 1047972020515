import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import formatString from "format-string-by-pattern";
import { signInRequest, setLoading } from "../../store/modules/auth/actions";
import {
  signUpCheckRequest,
  setSuccessAccountCreatedOpened,
} from "../../store/modules/register/actions";
import numbersOnly from "../../util/numbersOnly";
import ChangePasswordModal from "../../components/modals/RecoverPasswordByToken";
import SuccessAccountCreatedModal from "../../components/modals/SuccessAccountCreate";
import RecoverPasswordTokenRequestModal from "../../components/modals/RecoverPasswordTokenRequest";

import { errorMessage } from "../../util/toastNotification";

import { validateCpfCnpj } from "../signUp/validate";

import logo from "../../assets/images/logo-header.jpg";
import history from "../../services/history";

import {
  Container,
  Content,
  Box,
  SignInBox,
  Header,
  FormSignIn,
  SignUpBox,
  FormSignUp,
  Submit,
} from "./style";
import Input from "../../components/defaultComponents/Input";

export default function Login() {
  const dispatch = useDispatch();

  const [inputLogin, setInputLogin] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputCpfOrCnpj, setInputCpfOrCnpj] = useState("");
  const [forgetPasswordModalOpened, setForgetPasswordModalOpened] = useState(
    false
  );
  const { loading } = useSelector(state => state.auth);
  const { successModalOpened } = useSelector(state => state.register);
  const loadingSignUp = useSelector(state => state.register.loading);

  useEffect(() => {
    dispatch(setLoading(false));
  }, [dispatch]);

  function closeSuccessModal() {
    dispatch(setSuccessAccountCreatedOpened(false));
  }

  function handleSignIn(e) {
    e.preventDefault();
    if (loading) {
      return;
    }
    dispatch(signInRequest(inputLogin, inputPassword));
  }

  function handleSignUp(e) {
    e.preventDefault();
    const error = validateCpfCnpj(numbersOnly(inputCpfOrCnpj));
    if (error) {
      errorMessage({ message: error });
      return;
    }
    dispatch(signUpCheckRequest(numbersOnly(inputCpfOrCnpj)));
  }

  return (
    <Container>
      <Box>
        <Content>
          <Header>
            <h2>Entrar</h2>
            <img src={logo} alt="Google Logo" />
          </Header>
          <SignInBox className="teste">
            <FormSignIn onSubmit={handleSignIn} autoComplete="off">
              <span>Cpf</span>
              <Input
                name="login"
                type="text"
                required
                value={
                  inputLogin.length > 11
                    ? formatString("XX.XXX.XXX/XXXX-XX", inputLogin)
                    : formatString("XXX.XXX.XXX-XX", inputLogin)
                }
                onChange={e => setInputLogin(numbersOnly(e.target.value))}
                maxLength={18}
              />
              <span>Senha</span>
              <div className="inputs">
                <Input
                  className="inputText"
                  name="pass"
                  type="password"
                  required
                  value={inputPassword}
                  onChange={e => {
                    setInputPassword(e.target.value);
                  }}
                />
                <input type="submit" value="Entrar" />
                <Submit disabled={loading}>
                  {loading ? (
                    <ReactLoading
                      type="bars"
                      color="#fff"
                      height={24}
                      width={24}
                    />
                  ) : (
                    "Entrar"
                  )}
                </Submit>
              </div>
              <button
                type="button"
                onClick={() => {
                  setForgetPasswordModalOpened(true);
                }}
              >
                Esqueceu sua senha?
              </button>
            </FormSignIn>
          </SignInBox>
          <SignUpBox>
            <h2>Ainda não sou cadastrado</h2>
            <FormSignUp onSubmit={handleSignUp} autoComplete="off">
              <span>CPF ou CNPJ</span>
              <div className="inputs">
                <Input
                  className="inputText"
                  name="cpf"
                  type="text"
                  required
                  value={
                    inputCpfOrCnpj.length > 11
                      ? formatString("XX.XXX.XXX/XXXX-XX", inputCpfOrCnpj)
                      : formatString("XXX.XXX.XXX-XX", inputCpfOrCnpj)
                  }
                  onChange={e => setInputCpfOrCnpj(numbersOnly(e.target.value))}
                />
                <input type="submit" value="Iniciar" />
                <Submit disabled={loadingSignUp}>
                  {loadingSignUp ? (
                    <ReactLoading
                      type="bars"
                      color="#fff"
                      height={24}
                      width={24}
                    />
                  ) : (
                    "Iniciar"
                  )}
                </Submit>
              </div>
            </FormSignUp>
          </SignUpBox>
        </Content>
      </Box>
      <ChangePasswordModal />
      <RecoverPasswordTokenRequestModal
        opened={forgetPasswordModalOpened}
        onClose={() => setForgetPasswordModalOpened(false)}
      />
      <SuccessAccountCreatedModal
        opened={successModalOpened}
        onClose={closeSuccessModal}
      />
    </Container>
  );
}
