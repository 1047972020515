import styled from "styled-components";

export const Input = styled.button`
  background-color: #00205b;
  color: #fff;
  height: 42px;
  border-radius: 5px;
  font-size: 20px;
  padding: 0 30px;
  border: 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
