import React from "react";
import { useSelector } from "react-redux";

import Pre from "./Pre";
import Pos from "./Pos";

export default function Extract() {
  const { prePos } = useSelector(({ session }) => session.selectedContract);

  return prePos === "POS" ? <Pos /> : <Pre />;
}
