import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import {
  ListValuesTitleWrapper,
  ListValuesTitle,
  AccordionContainer,
  AccordionContent,
} from "./style";

function Accordion({ title, children, orderNo, date, total }) {
  const [open, isOpen] = useState(false);

  const toggleAccordion = useCallback(() => {
    isOpen(!open);
  }, [open]);

  return (
    <AccordionContainer>
      <ListValuesTitleWrapper onClick={toggleAccordion} open={open}>
        <ListValuesTitle>
          <div>
            {!!orderNo && <h3>{orderNo}</h3>}
            {!!date && <h4>{date}</h4>}
            <h3>{title}</h3>
          </div>
          <span>
            {!open && (
              <>
                total do pedido <strong>{total}</strong>
              </>
            )}
          </span>
        </ListValuesTitle>
      </ListValuesTitleWrapper>
      {open && <AccordionContent>{children}</AccordionContent>}
    </AccordionContainer>
  );
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  orderNo: PropTypes.string,
  date: PropTypes.string,
  total: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  orderNo: "",
  date: "",
};

export default Accordion;
