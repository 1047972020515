import styled from "styled-components";

export const COLORS = {
  $darkBlue: "#00205b",
  $lightBlue: "#00205b",
  $secondDarkBlue: "#1d3475",
  $lightGreen: "#92c020",
  $baseGrayBackground: "#f3f3f5",
  $baseLightBackground: "#f0f0f4",
  $baseWhiteBackground: "#fff",
  $darkGray: "#243044",

  $gray: {
    $baseBackground: "#f3f3f5",
    $baseLightBackground: "#f0f0f4",
    $borderLine: "#BABABA",
  },
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
`;

export const Content = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1400px;
`;
