import React from "react";
import PropTypes from "prop-types";

import { LinksBox, Modal } from "./style";
import Button from "../../defaultComponents/Button";

export default function SuccessAccountCreate({ opened, onClose }) {
  return (
    <Modal opened={opened} onClose={onClose} closeOnDocumentClick>
      <i className="far fa-check-circle" />

      <h2>Usuario criado com sucesso!</h2>
      <p>
        Seu usuário está no processo de aprovação interna para utilizar o
        portal. Assim que terminarmos, você será avisado no email cadastrado.
      </p>
      <ul>
        <li>
          Certifique-se de verificar sua caixa de spam ou seu lixo eletrônico.
        </li>
        <li>
          Qualquer dúvida entre em contato pelo e-mail: selos@netpark.com.br
        </li>
      </ul>
      <LinksBox>
        <Button className="dark" value="Ok" onClick={onClose} />
      </LinksBox>
    </Modal>
  );
}

SuccessAccountCreate.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
