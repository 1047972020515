import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import LisfOfStamps from "../../components/stamp/List";
import ListOfShipments from "../../components/shipment/List";
import ListOfPayments from "../../components/payment/List";
import {
  Container,
  Content,
  Stamps,
  Shipments,
  Payments,
  Total,
  BoxOrder,
  Title,
  SubmitButton,
  StatusProcessing,
} from "./style";
import api from "../../services/api";
import { formatPrice } from "../../util/format";
import {
  addToCartSuccess,
  checkoutRequest,
  clear,
} from "../../store/modules/cart/actions";
import { setModalOpened } from "../../store/modules/modalWaitingBought/actions";

import WaitingPopup from "../../components/modals/WaitingBought";

import { errorMessage } from "../../util/toastNotification";

import history from "../../services/history";

export default function Main() {
  const [stamps, setStamps] = useState([]);
  const [observation, setObservation] = useState("");
  const { selectedContract } = useSelector(state => state.session);
  const cart = useSelector(state => state.cart);
  const { processing } = useSelector(state => state.cart);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [loadingStamps, setLoadingStamps] = useState(false);

  const dispatch = useDispatch();

  const modalWaitingBoughtOpened = useSelector(
    state => state.modalWaitingBought.opened
  );
  const cartTotal = useSelector(state => {
    const totalStamps = state.cart.stamps.reduce(
      (total, item) => total + item.total,
      0
    );
    const shipmentPrice = state.cart.shipmentSelected.price || 0;
    return formatPrice(totalStamps + shipmentPrice).replace("R$", "");
  });

  const cartQuantity = useSelector(state =>
    state.cart.stamps.reduce((quantity, stamp) => {
      quantity[stamp.id] = stamp.quantity;

      return quantity;
    }, {})
  );

  const cartTotalStamps = useSelector(state =>
    state.cart.stamps.reduce((total, stamp) => {
      total[stamp.id] = formatPrice(stamp.total);

      return total;
    }, {})
  );

  useEffect(() => {
    async function loadStamps() {
      if (!selectedContract || !selectedContract.contract) {
        return;
      }

      if (selectedContract.prePos === "POS") {
        history.push("/validate");
      }

      setLoadingStamps(true);
      let { data } = await api.get("stamps");

      if (data.stamps) {
        data = data.stamps.map(stamp => ({
          ...stamp,
          formattedPrice: formatPrice(stamp.partValue),
          price: stamp.partValue,
          id: stamp.partNo,
          hourValue: Math.floor(stamp.partTime / 60),
          minuteValue: stamp.partTime % 60,
          limit: false,
        }));
      }

      setStamps(data || []);
      setLoadingStamps(false);
    }

    loadStamps();
  }, [selectedContract]);

  const handleAddStamp = useCallback(
    (stamp, quantity) => {
      const data = {
        id: stamp.id,
        quantity,
        price: stamp.price,
        total: quantity * stamp.price,
      };

      dispatch(addToCartSuccess(data));
    },
    [dispatch]
  );

  const handleRemoveStamp = useCallback(
    (stamp, quantity) => {
      if (quantity < 0) {
        return;
      }
      const data = {
        id: stamp.id,
        quantity,
        price: stamp.price,
        total: quantity * stamp.price,
      };

      dispatch(addToCartSuccess(data));
    },
    [dispatch]
  );

  function openSuccessModal() {
    dispatch(setModalOpened(true));
  }

  function closeSuccessModal() {
    dispatch(setModalOpened(false));
  }

  function handleCheckout() {
    if (!acceptedTerms) {
      errorMessage({
        message: "Você precisa aceitar os termos para prosseguir",
      });
      return;
    }

    if (!cart.stamps || cart.stamps.length < 1) {
      errorMessage({ message: "Adicione ao menos um item ao carrinho" });
      return;
    }

    if (!cart.paymentSelected || !cart.paymentSelected.token) {
      errorMessage({ message: "Selecione um meio de pagamento" });
      return;
    }

    if (!cart.shipmentSelected || !cart.shipmentSelected.id) {
      errorMessage({ message: "Selecione um meio de recebimento" });
      return;
    }

    if (Number(cartTotal) <= 100) {
      errorMessage({ message: "Valor mínimo de compra é R$100" });
      return;
    }

    const stampsMap = cart.stamps.map(item => ({
      id: item.id,
      quantity: item.quantity,
    }));
    const request = {
      payment: {
        type: cart.paymentSelected.token,
        id: cart.paymentSelected.selectedCard
          ? cart.paymentSelected.selectedCard.token
          : "",
      },
      taxId: cart.shipmentSelected.id,
      stamps: stampsMap,
      text: observation,
    };
    dispatch(checkoutRequest(request));
  }

  return (
    <Container>
      <Content>
        <Stamps>
          <Title>Comprar Selos</Title>
          <LisfOfStamps
            stamps={stamps}
            handleAddStamp={handleAddStamp}
            handleRemoveStamp={handleRemoveStamp}
            loading={loadingStamps}
            cartQuantity={cartQuantity}
            cartTotal={cartTotalStamps}
          />
        </Stamps>
        <Shipments>
          <Title>Escolha a forma de recebimento</Title>
          <ListOfShipments />
        </Shipments>
        <Payments>
          <Title>Escolha a forma de pagamento</Title>
          <ListOfPayments />
        </Payments>
        <BoxOrder>
          <div className="order-number">
            <span>
              Texto ou número do pedido para NF <i>(opcional)</i>
            </span>
            <div className="box-inputs">
              <input
                type="text"
                value={observation}
                onChange={e => setObservation(e.target.value)}
              />
              <div className="policy-check">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={acceptedTerms}
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                    />
                  }
                  label={
                    <span>
                      Li e aceito os termos e a{" "}
                      <a href="https://npk-assets.s3.sa-east-1.amazonaws.com/politicadeprivacidade.pdf">
                        Política de Privacidade
                      </a>
                    </span>
                  }
                />
              </div>
            </div>
          </div>
        </BoxOrder>
      </Content>
      <Total>
        {processing ? (
          <StatusProcessing>
            <span>
              Há uma venda sendo processada no momento. Aguarde alguns
              instantes.
            </span>
          </StatusProcessing>
        ) : (
          <div>
            <div className="totalValues">
              <span>Total</span>
              <strong>R$</strong>
              <h2>{cartTotal}</h2>
            </div>
            <SubmitButton value="Finalizar Pedido" onClick={handleCheckout} />
          </div>
        )}
      </Total>
      <WaitingPopup
        opened={modalWaitingBoughtOpened}
        onClose={closeSuccessModal}
        closeOnDocumentClick
      />
    </Container>
  );
}
