import { takeLatest, call, put, all } from "redux-saga/effects";
import { setWallet } from "./actions";
import api from "../../../services/api";

export function* reload() {
  try {
    const { data } = yield call(api.get, "wallet");
    if (data && data.wallet) {
      const wallet = data.wallet || [];
      yield put(setWallet(wallet));
    }
  } catch (e) {
    // Do nothing
  }
}

export default all([takeLatest("@wallet/RELOAD", reload)]);
