import styled from "styled-components";
import DefaultMiniBox from "../MiniBox";

export const MiniBox = styled(DefaultMiniBox)`
  height: 80px;
  h3 {
    color: #565656;
  }
  h2 {
    color: #565656;
  }
  span {
    color: #565656;
  }
`;
