import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  stamps: [],
  error: null,
  shipmentSelected: {},
  paymentSelected: {},
  processing: false,
};

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@cart/ADD_TO_CART":
      return produce(state, draft => {
        const stampIndex = draft.stamps.findIndex(
          stamp => stamp.id === action.payload.stamp.id
        );

        if (stampIndex >= 0) {
          draft.stamps.splice(stampIndex, 1);
        }

        if (action.payload.stamp.quantity > 0) {
          draft.stamps.push(action.payload.stamp);
        }

        draft.loading = false;
        draft.error = null;
      });
    case "@cart/SELECT_SHIPMENT":
      return produce(state, draft => {
        draft.shipmentSelected = action.payload.shipment;
      });
    case "@cart/SELECT_PAYMENT":
      return produce(state, draft => {
        draft.paymentSelected = action.payload.payment;
      });
    case "@cart/CHECKOUT_REQUEST":
      return produce(state, draft => {
        draft.loading = true;
        draft.processing = true;
      });
    case "@cart/CHECKOUT_SUCCESS":
      return { ...INITIAL_STATE, processing: true };
    case "@cart/CHECKOUT_FAILURE":
      return produce(state, draft => {
        draft.loading = false;
        draft.processing = false;
      });
    case "@cart/CLEAR":
      return { ...INITIAL_STATE, processing: state.processing };
    case "@cart/ORDER_PROCESSED":
      return produce(state, draft => {
        draft.processing = false;
      });
    case "@cart/PROCESSING_ORDER":
      return produce(state, draft => {
        draft.processing = true;
      });
    case "@auth/SIGN_OUT":
      return INITIAL_STATE;
    default:
      return state;
  }
}
