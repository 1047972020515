import styled from "styled-components";
import DefaultListItemIcon from "@material-ui/core/ListItemIcon";
import DefaultNotifications from "../../Notifications";
import { COLORS } from "../../../styles/defaults";

export const User = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  img {
    border: 1px solid #00205b;
    border-radius: 20%;
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  > div {
    border: 1px solid white;
    border-radius: 20%;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);

    span {
      color: #fff;
    }
  }
  span {
    font-size: 16px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    i {
      margin-left: 6px;
      font-size: 14px;
    }
    &:hover {
      opacity: 1;
      transition: ease 0.2s;
    }
  }
`;

export const Menu = styled.ul`
  /*width: 2px;
  opacity: 0;*/
  height: 1px;
  border: 1px solid blue;

  /* Hover
      height: 77px;
      border: 1px solid blue;
      transform: translateY(0px);
      padding: 20px;
  */
`;

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 860px) {
    align-self: flex-start;
  }
  margin-right: 25px;
`;

export const ListItemIcon = styled(DefaultListItemIcon)`
  min-width: 40px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Notifications = styled(DefaultNotifications)`
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 10px;
  margin-right: 10px;
`;
