import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => (props.selected && props.card ? "384px" : "290px")};
  margin: 10px;
  padding: 16px;
  align-items: center;
  background: #fff;
  cursor: pointer;

  @media only screen and (max-width: 670px) {
    width: 95%;
    margin: 0;
    margin-bottom: 15px;
    height: 75px;
  }

  i {
    color: ${props => (props.selected ? "#92c020" : "#cccccc")};
    font-size: 24px;
  }

  span {
    margin-left: 12px;
    color: #5e5e5e;
    font-size: 18px;
    font-weight: bold;
  }

  border-radius: 10px;
  @media only screen and (min-width: 761px) {
    box-shadow: ${props =>
      props.selected
        ? "0 0 10px 10px rgba(0, 0, 0, 0.3)"
        : "0 0 10px 10px rgba(0, 0, 0, 0.09)"};
  }

  transition: ease 0.4s;

  &:hover {
    border-radius: 10px;
    @media only screen and (min-width: 761px) {
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    }
    transition: ease 0.4s;
  }
`;

export const BoxSelected = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 10px;
    font-weight: 500;
    color: grey;
    text-transform: uppercase;
    margin-left: 6px;
    svg {
      width: 20px;
    }
  }
`;
