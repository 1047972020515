import styled from "styled-components";
import { COLORS } from "../../styles/defaults";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  input {
    height: 38px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    input {
      height: 44px;
      margin-bottom: 15px;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    padding-left: 15px;
  }

  .inputs {
    display: flex;

    input[type="submit"] {
      display: none;
    }
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 501px) {
      .inputText {
        flex: 1;
        margin-right: 16px;
      }
    }
  }
`;

export const Submit = styled.button`
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00fbe2;
  color: #00205b;
  font-weight: bold;
  width: 120px;
  border-radius: 8px;
  border: 0;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.6" : "1")};
  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const Box = styled.div`
  background-color: #00205b;
  border: 1px solid #dadce0;
  border-radius: 8px;
  width: 450px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    flex: 1;
    height: auto;
    width: 100%;
    border-radius: 0;
    border: none;
    background: linear-gradient(
        to bottom,
        rgba(30, 54, 121, 1) 15%,
        rgba(30, 54, 121, 0.5) 50%
      )
      no-repeat !important;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

export const SignInBox = styled.div`
  padding: 0 35px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px 0 35px;
  h2 {
    color: #00fbe2;
    font-weight: 500;
    font-size: 24px;
  }

  @media only screen and (max-width: 500px) {
    background-color: #00205b;
    h2 {
      display: none;
    }
    justify-content: center;
  }
`;

export const FormSignIn = styled.form`
  display: flex;
  flex-direction: column;

  input[type="email"] {
    margin-bottom: 13px;
  }

  > span {
    color: #fff;
    font-size: 12px;
  }

  > button {
    color: #00fbe2;
    font-size: 10px;
    margin-top: 2px;
    border: none;
    background: transparent;
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px solid #00fbe2;
    @media only screen and (max-width: 500px) {
      align-self: flex-end;
      justify-self: flex-end;
      margin-top: 15px;
      font-size: 12px;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }
`;

export const SignUpBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  > h2 {
    padding-left: 35px;
    color: #fff;
    font-size: 18px;
  }
`;

export const FormSignUp = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 35px;

  > span {
    color: #fff;
    font-size: 12px;
  }
`;
