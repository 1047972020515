import React from "react";
import PropTypes from "prop-types";

import { Container, Logo, BoxInfo } from "./style";

export default function CreditCard({
  lastNumbers,
  name,
  expiry,
  brand,
  number,
  cvc,
  focused,
  callback,
  className,
}) {
  return (
    <Container
      locale={{ valid: "validade" }}
      placeholders={{ name: "Seu nome aqui" }}
      number={lastNumbers ? `************${lastNumbers}` : number}
      name={name}
      expiry={expiry}
      cvc={cvc}
      focused={focused}
      preview
      issuer={brand}
      callback={callback}
      className={className}
    />
  );
}

CreditCard.propTypes = {
  lastNumbers: PropTypes.string,
  number: PropTypes.string,
  name: PropTypes.string.isRequired,
  expiry: PropTypes.string.isRequired,
  brand: PropTypes.string,
  cvc: PropTypes.string,
  focused: PropTypes.string,
  callback: PropTypes.func,
  className: PropTypes.string,
};

CreditCard.defaultProps = {
  lastNumbers: "",
  number: "",
  brand: "",
  cvc: "",
  focused: "",
  callback: () => {},
  className: "",
};
