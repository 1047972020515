import { takeLatest, call, put, all } from "redux-saga/effects";
import { setSelectedContract, setSites, setUser } from "./actions";
import { orderProcessed, processingOrder } from "../cart/actions";
import { ticketProcessed, processingTicket } from "../cartValidate/actions";
import { signOut } from "../auth/actions";
import api from "../../../services/api";
import buildUser from "./buildUser";
import { errorMessage } from "../../../util/toastNotification";
import { ioConnect } from "../../../services/socket";

/* export function* setContractsFromSignIn({ payload }) {
  yield put(setSites(payload.user.sites));
  const selectedContract = payload.user.sites.find(
    item => item.contract === payload.user.mainContract
  );
  yield put(setSelectedContract(selectedContract));
} */

export function* rebuildSession() {
  try {
    const { data } = yield call(api.get, "profile");
    const { user } = data;

    const selectedContract = user.sites.find(
      item => item.contract === user.mainContract
    );
    const { data: response } = yield call(
      api.get,
      `contracts/${selectedContract.contract}`
    );
    const { contract } = response;

    // Check if is there any order being processed
    const { data: responseQueue } = yield call(api.get, "queue");
    const { processing } = responseQueue;
    if (processing) {
      yield put(processingOrder());
    } else {
      yield put(orderProcessed());
    }

    // Check if is there any ticket being processed
    const { data: responseTicketQueue } = yield call(api.get, "ticket/queue");
    const { processing: ticketProcessing } = responseTicketQueue;
    if (ticketProcessing) {
      yield put(processingTicket());
    } else {
      yield put(ticketProcessed());
    }

    yield put(setUser(buildUser(user)));
    yield put(setSites(user.sites));
    yield put(setSelectedContract({ ...selectedContract, ...contract }));
    ioConnect(user.customerNo);
  } catch (e) {
    errorMessage({ message: "Sessão expirada" });
    yield put(signOut());
  }
}

export default all([
  takeLatest("@auth/SIGN_IN_SUCCESS", rebuildSession),
  takeLatest("@session/REBUILD_SESSION", rebuildSession),
]);
