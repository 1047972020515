import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  max-width: 1280px;
  @media only screen and (max-width: 450px) {
    padding-top: 25px;
  }
  h2 {
    margin-bottom: 60px;
    margin-left: 35px;
    @media only screen and (max-width: 860px) {
      margin-bottom: 10px;
    }
  }
`;

export const ContactBox = styled.div`
  margin: 0 35px 20px 35px;
  justify-self: flex-start;
`;
