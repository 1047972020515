import styled from "styled-components";
import DefaultTitle from "../../components/defaultComponents/Title";
import DefaultSubmitButton from "../../components/defaultComponents/Button";
import { COLORS } from "../../styles/defaults";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    /*align-items: center;*/
  }
`;

export const Stamps = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-left: 12px;
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    max-width: 100%;
    h2 {
      text-align: center;
    }
  }
`;

export const Shipments = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  h2 {
    margin-left: 12px;
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    max-width: 100%;
    h2 {
      text-align: center;
    }
  }
`;

export const Payments = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  h2 {
    margin-left: 12px;
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    width: 100%;
    padding: 10px;
    h2 {
      text-align: center;
    }
  }
`;

export const Total = styled.div`
  > div {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .totalValues {
      display: flex;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      .totalValues {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  height: 90px;
  margin-top: 20px;
  width: 100%;
  span {
    color: #1d3475;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    margin-top: 10px;
  }
  strong {
    color: #565656;
    font-size: 16px;
    font-weight: bold;
    margin-right: 6px;
    margin-top: 10px;
  }
  h2 {
    color: #565656;
    font-size: 28px;
    font-weight: bolder;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1400px) {
    padding-right: 10px;
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    padding-bottom: 20px;
    input[type="button"] {
      margin-top: 20px;
      width: 100%;
      margin-left: 10px;
    }
  }
`;

export const BoxOrder = styled.div`
  display: flex;
  justify-content: space-between;
  .box-inputs {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-top: 10px;

    input {
      margin-right: 35px;
      height: 50px;
      border-radius: 25px;
      font-size: 20px;
      text-align: center;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      max-width: 90%;
      flex: 1;
    }
    .policy-check {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        margin-top: 15px;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      /*justify-content: center; */
      input {
        margin-right: 0;
        max-width: 100%;
        flex: auto;
      }
    }
  }
  .order-number {
    display: flex;
    flex-direction: column;
    flex: 1;
    span {
      font-size: 16px;
      font-weight: bold;
      i {
        font-weight: normal;
        font-style: italic;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

export const Title = styled(DefaultTitle)`
  margin-top: 50px;
`;

export const SubmitButton = styled(DefaultSubmitButton)`
  height: 50px;
`;

export const StatusProcessing = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > span {
    font-size: 18px;
    text-align: center;
    color: ${COLORS.$darkBlue};
  }
`;
