import React from "react";
import Props from "prop-types";
import { Content } from "./style";

export default function MiniBox({ title, content, description, className }) {
  return (
    <Content className={className}>
      <h3>{title}</h3>
      <h2>
        {content}
        <span>{description}</span>
      </h2>
    </Content>
  );
}

MiniBox.propTypes = {
  title: Props.string.isRequired,
  content: Props.string,
  description: Props.string,
  className: Props.string,
};

MiniBox.defaultProps = {
  content: "-",
  description: "",
  className: "",
};
