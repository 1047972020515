import { takeLatest, call, put, all } from "redux-saga/effects";
import { parseISO, formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";
import { setNotifications } from "./actions";
import api from "../../../services/api";

export function* getNotifications() {
  try {
    const { data } = yield call(api.get, "notifications");
    let { notifications } = data;

    const hasUnread = notifications.find(item => item.read === false);
    notifications = notifications.map(n => ({
      ...n,
      timeDistance: formatDistance(parseISO(n.fullDate), new Date(), {
        addSuffix: true,
        locale: pt,
      }),
    }));

    yield put(setNotifications(!!hasUnread, notifications));
  } catch (e) {
    /* Do nothing */
  }
}

export default all([
  takeLatest("@auth/SIGN_IN_SUCCESS", getNotifications),
  takeLatest("@session/REBUILD_SESSION", getNotifications),
  takeLatest("@notification/LOAD_NOTIFICATIONS", getNotifications),
]);
