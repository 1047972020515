import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import api from "../../../services/api";
import ContentLoading from "./Loading";

import { Container, EmptyContent, LoadMore, BoxLoading } from "./style";
import Order from "../Order";

export default function List({ filterDays, filterName, filterValue }) {
  const { selectedContract } = useSelector(state => state.session);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadOrders = useCallback(async function load(
    pageDesired,
    days,
    iFilterName,
    iFilterValue
  ) {
    setLoading(true);
    let url = `order?page=${pageDesired}`;
    url += `&days=${days || 60}`;
    if (iFilterName && iFilterValue) {
      url += `&filterName=${iFilterName}&filterValue=${iFilterValue}`;
    }
    const { data } = await api.get(url);
    setLoading(false);
    if (data && data.orders) {
      return data.orders;
    }

    return [];
  },
  []);

  async function loadMore() {
    const newItems = await loadOrders(
      page + 1,
      filterDays,
      filterName,
      filterValue
      // selectedDaysFilter.value
    );
    setPage(page + 1);
    setOrders([...orders, ...newItems]);
    if (!newItems || newItems.length === 0) {
      setHasMore(false);
    }
  }

  useEffect(() => {
    if (!selectedContract || !selectedContract.contract) {
      return;
    }
    setOrders([]);

    async function initOrders() {
      const items = await loadOrders(
        1,
        filterDays,
        filterName,
        filterValue
        // selectedDaysFilter.value
      );
      setOrders(items);
      setPage(1);
      setHasMore(true);
    }

    initOrders();
  }, [selectedContract, loadOrders, filterDays, filterName, filterValue]);

  return (
    <Container>
      {(!orders || orders.length === 0) && !loading && (
        <EmptyContent>
          <span>Não há pedidos para o site/filtro selecionado</span>
        </EmptyContent>
      )}
      {loading && (!orders || orders.length === 0) && (
        <BoxLoading>
          <div>
            <ContentLoading />
          </div>
        </BoxLoading>
      )}
      {orders.map(order => (
        <Order key={order.id} order={order} />
      ))}
      {orders && orders.length > 0 && (
        <LoadMore disabled={!hasMore || loading}>
          <button
            value="Load"
            onClick={loadMore}
            type="button"
            disabled={!hasMore || loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="blue" height={24} width={24} />
            ) : (
              "Carregar mais resultados"
            )}
          </button>
        </LoadMore>
      )}
    </Container>
  );
}
