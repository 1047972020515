import { takeLatest, call, put, all } from "redux-saga/effects";
import history from "../../../services/history";
import api from "../../../services/api";
import { signInSuccess, signFailure } from "./actions";
import { rebuildSession } from "../session/actions";
import { successMessage, errorMessage } from "../../../util/toastNotification";
import { ioDisconnect } from "../../../services/socket";

export function* signIn({ payload }) {
  const { login, password } = payload;

  try {
    const { data } = yield call(api.post, "sessions", {
      login,
      password,
    });
    const { token, user } = data;

    if (!token) {
      errorMessage({ message: "Falha ao fazer o login" });
      return;
    }

    api.defaults.headers.Authorization = `bearer ${token}`;
    yield put(signInSuccess(token, user));
    successMessage({ message: "Login realizado com sucesso" });

    const selectedContract = user.sites.find(
      item => item.contract === user.mainContract
    );
    if (selectedContract && selectedContract.prePos === "POS") {
      history.push("/validate");
    } else {
      history.push("/");
    }
  } catch ({ response }) {
    yield put(signFailure());
    errorMessage({
      message:
        response && response.data && response.data.message
          ? response.data.message
          : "Falha ao fazer o login",
    });
  }
}

export function* setSession({ payload }) {
  const token = payload && payload.auth ? payload.auth.token : false;
  if (token) {
    api.defaults.headers.Authorization = `bearer ${token}`;
    yield put(rebuildSession());
  }
}

const signOut = () => {
  ioDisconnect();
  api.delete("sessions");
  setTimeout(() => {
    history.push("/");
  }, 800);
};

export default all([
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("persist/REHYDRATE", setSession),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
