import React from "react";
import PropTypes from "prop-types";
import { Container, Box, Select } from "./style";
import api from "../../../services/api";

const styles = {
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    color: "#565656",
    fontSize: "14px",
  }),
  menu: provided => ({
    ...provided,
    marginTop: "0",
    zIndex: "10",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "180px",
    backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
    borderRadius: "4px",
    paddingTop: "0",
    paddingBottom: "0",
  }),
  valueContainer: provided => ({
    ...provided,
    border: "none",
    paddingLeft: "20px",
    borderRadius: "30px 0 0 30px",
    backgroundColor: "#fff",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    border: "none",
    borderRadius: "0 30px 30px 0",
    backgroundColor: "#fff",
    cursor: "pointer",
  }),
  control: provided => ({
    ...provided,
    background: "#fff",
    borderRadius: "30px",
    border: "none",
    boxShadow: "none",
  }),
  singleValue: provided => ({
    ...provided,
    color: "#565656",
    fontSize: "14px",
  }),
};

export default function UsersSelect({ valueState, setValueState, className }) {
  const defaultAdditional = { page: 1 };

  async function getUsers() {
    const { data } = await api.get(`users`);
    const { users } = data;

    const mappedResponse = users.map(item => ({
      value: item.customerNo,
      label: item.fullName,
    }));
    return [{ value: "-1", label: "Todos os usuários" }, ...mappedResponse];
  }

  async function loadOptions() {
    const options = await getUsers();

    const hasMore = false;

    return {
      options,
      hasMore,
      additional: { page: 2 },
    };
  }

  return (
    <Box className={className}>
      <Container>
        <Select
          styles={styles}
          additional={defaultAdditional}
          value={valueState}
          loadOptions={loadOptions}
          onChange={setValueState}
          debounceTimeout={400}
          placeholder="Todos os usuários"
        />
      </Container>
    </Box>
  );
}

UsersSelect.propTypes = {
  valueState: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  setValueState: PropTypes.func.isRequired,
  className: PropTypes.string,
};

UsersSelect.defaultProps = {
  className: "",
};
