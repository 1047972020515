import styled from "styled-components";
import DefaultInput from "../../components/defaultComponents/Input";
import { COLORS } from "../../styles/defaults";
import DefaultTitle from "../../components/defaultComponents/Title";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 100%;
  max-width: 840px;
  padding: 30px 0;
  background-color: #fff;
  border-radius: 12px;
`;

export const Title = styled(DefaultTitle)`
  color: ${COLORS.$darkBlue};
  margin-bottom: 12px;
  font-size: 28px;
  margin-left: 15px;
`;

export const SubTitle = styled(DefaultTitle)`
  color: ${COLORS.$darkBlue};
  font-size: 20px;
  margin-left: 15px;
`;

export const SmallText = styled(DefaultTitle)`
  color: ${COLORS.$darkBlue};
  font-size: 10px;
  margin-left: 15px;
`;

export const Warn = styled(DefaultTitle)`
  color: ${COLORS.$darkBlue};
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 6px;
  margin-left: 15px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  input[name="cpf_cnpj"],
  input[name="phone"] {
    width: 190px;
  }

  input[name="fantasy_name"],
  input[name="name"],
  input[name="sponsor_name"],
  input[name="email"] {
    width: 272px;
  }

  input[name="cep"],
  input[name="complement"] {
    width: 145px;
  }

  input[name="address"] {
    width: 490px;
  }

  input[name="number"],
  input[name="province"] {
    width: 100px;
  }

  input[name="district"],
  input[name="city"] {
    width: 245px;
  }

  input[name="password"],
  input[name="password_confirmation"] {
    width: 180px;
  }
`;

export const Input = styled(DefaultInput)`
  height: 32px;
  padding-left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.$darkBlue};
`;

export const Error = styled.span`
  font-size: 9px;
  color: red;
  position: absolute;
  transform: translateY(50px) translateX(5px);
`;

export const LineSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.$gray.$borderLine};
  margin: 30px 0 20px 0;
`;

export const Submit = styled.button`
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.$darkBlue};
  color: #fff;
  font-weight: 500;
  height: 36px;
  width: 180px;
  border-radius: 8px;
  border: 0;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.6" : "1")};
  align-self: flex-end;
`;

export const SubmitContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const StrongPassword = styled.div`
  display: flex;
  width: 220px;
  flex-direction: column;
  justify-content: center;
  span {
    font-size: 11px;
    color: ${props => (props.color ? props.color : "green")};
  }
  > div {
    transition: all 0.3s;
    height: 4px;
    width: ${props => (props.width ? `${props.width}px` : "0px")};
    background-color: ${props => (props.color ? props.color : "green")};
  }
`;
