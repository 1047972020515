import styled from "styled-components";
import DefaultButton from "../../../components/defaultComponents/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 450px) {
    padding-top: 25px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  color: #565656;
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 25px;
`;

export const Button = styled(DefaultButton)`
  font-size: 15px;
  width: fit-content;
  height: 40px;
  transition: background-color 1s ease;
  color: #fff;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 60px;

  > h2 {
    @media only screen and (max-width: 860px) {
      margin-bottom: 10px;
    }
  }
`;
