import React from "react";
import PropTypes from "prop-types";
import { FaPlus, FaMinus } from "react-icons/fa";
import numbersOnly from "../../../util/numbersOnly";

import { Container } from "./style";

export default function Stamp({
  stamp,
  quantity,
  total,
  handleAddStamp,
  handleRemoveStamp,
}) {
  function handleChange(stp, value) {
    if (value >= quantity) {
      handleAddStamp(stamp, value);
      return;
    }
    handleRemoveStamp(stamp, value);
  }

  return (
    <Container length={quantity.toString().length}>
      <div className="header">
        {stamp.hourValue > 0 ? `${stamp.hourValue}h` : `${stamp.minuteValue}m`}
      </div>
      <div className="box">
        <button type="button" onClick={() => handleChange(stamp, quantity - 1)}>
          <FaMinus size={20} />
        </button>
        <input
          type="text"
          value={quantity}
          onChange={e =>
            handleChange(stamp, parseInt(numbersOnly(e.target.value), 0))
          }
          onFocus={e => e.target.select()}
        />
        <button type="button" onClick={() => handleChange(stamp, quantity + 1)}>
          <FaPlus size={20} />
        </button>
      </div>
      {stamp.formattedPrice !== false && (
        <>
          <span>{stamp.formattedPrice} cada </span>
          <div className="footer" style={quantity ? { color: "green" } : {}}>
            {total || "R$ 00,00"}
          </div>
        </>
      )}
    </Container>
  );
}

Stamp.propTypes = {
  stamp: PropTypes.shape({
    id: PropTypes.string,
    hourValue: PropTypes.number,
    minuteValue: PropTypes.number,
    formattedPrice: PropTypes.string,
  }).isRequired,
  quantity: PropTypes.number,
  total: PropTypes.string,
  handleAddStamp: PropTypes.func.isRequired,
  handleRemoveStamp: PropTypes.func.isRequired,
};

Stamp.defaultProps = {
  quantity: 0,
  total: "R$ 00,00",
};
