import styled from "styled-components";
import trashIcon from "../../../assets/images/trash-icon.svg";
import { COLORS } from "../../../styles/defaults";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-top: 60px;
  h2 {
    margin-bottom: 60px;
    margin-left: 35px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-start;

  padding: 30px 40px;
  flex-wrap: wrap;

  width: 100%;
  background-color: #fff;
  border-radius: 10px;

  > div {
    margin: 0 20px 40px 0;
  }

  .creditCard {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      &::before {
        content: "";
        background: rgba(30, 54, 121, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 290px;
        height: 183px;
        border-radius: 14.5px;
        position: absolute;
        z-index: 3;
      }
      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 290px;
        height: 183px;
        border-radius: 14.5px;
        position: absolute;
        content: "";
        background-image: url(${trashIcon});
        /*background-size: 75px 75px;*/
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(100%) sepia(0%) saturate(2878%) hue-rotate(346deg)
          brightness(104%) contrast(97%);
        z-index: 4;
        animation: growth 0.4s linear 0s 1 normal forwards;
      }
    }
  }

  @keyframes growth {
    0% {
      background-size: 10px 10px;
    }
    50% {
      background-size: 45px 45px;
    }
    100% {
      background-size: 75px 75px;
    }
  }
`;

export const AddNewCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 2px dashed #000;
  width: 290px;
  height: 182px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 50px;
    color: ${COLORS.$darkBlue};
  }
  span {
    text-align: center;
    font-size: 12px;
    color: ${COLORS.$darkGray};
  }
`;

export const ContentLoading = styled.div`
  width: 300px;
  height: 190px;
`;
