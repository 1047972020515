import React from "react";
import PropTypes from "prop-types";

import Order from "./Accordion";
import Table from "./Table";
import { Container, Total, EmptyContent } from "./style";

function List({ extract, loading }) {
  const orders = [
    {
      title: "Fatura em Aberto",
      id: "order_1",
      total: "R$ 78,30",
      isClosed: false,
      orderNo: "160055",
      date: "02/11/2018",
      data: [
        {
          id: "1",
          time: "08/11/2018 - 12:34",
          ticket: "0662018347",
          accreditedTime: "01:44",
          obs: "Lorem ipsum dolor set amet",
          user: "Joseane dos Santos",
          total: "R$ 26,10",
        },
      ],
    },
    {
      title: "Fatura Fechada",
      id: "order_1",
      total: "R$ 78,30",
      isClosed: true,
      orderNo: "160050",
      date: "02/11/2018",
      data: [
        {
          id: "1",
          time: "08/11/2018 - 12:34",
          ticket: "0662018347",
          accreditedTime: "01:44",
          obs: "Lorem ipsum dolor set amet",
          user: "Joseane dos Santos",
          total: "R$ 26,10",
        },
      ],
    },
  ];
  return (
    <Container>
      {(!extract || extract.length === 0) && !loading && (
        <EmptyContent>
          <span>Não há Faturas para o site/filtro selecionado</span>
        </EmptyContent>
      )}
      {extract.map(item => (
        <Order
          title={item.title}
          key={item.id}
          total={item.total}
          date={item.isClosed ? item.date : ""}
          orderNo={item.isClosed ? item.orderNo : ""}
        >
          <Table data={item.data} />
          <Total>
            total do pedido <strong>{item.total}</strong>
          </Total>
        </Order>
      ))}
    </Container>
  );
}

List.propTypes = {
  extract: PropTypes.arrayOf(
    PropTypes.shape({
      validateDate: PropTypes.string,
      orders: PropTypes.array,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

List.defaultProps = {};

export default List;
