import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../DefaultModal";
import Button from "../../defaultComponents/Button";
import { closeModal } from "../../../store/modules/modalSuccess/actions";
import { Links } from "./style";

export default function DefaultSuccess() {
  const dispatch = useDispatch();
  const { opened, title, text } = useSelector(state => state.successModal);

  function onClose() {
    dispatch(closeModal());
  }

  return (
    <Modal opened={opened} onClose={onClose} closeOnDocumentClick>
      <i className="far fa-check-circle" />
      <h2>{title}</h2>
      <p>{text}</p>
      <Links>
        <Button className="light" value="Ok" onClick={onClose} />
      </Links>
    </Modal>
  );
}
