import { takeLatest, call, put, all } from "redux-saga/effects";
import history from "../../../services/history";
import api from "../../../services/api";
import {
  checkTokenSuccess,
  changePasswordSucess,
  changePasswordFailure,
} from "./actions";
import { successMessage, errorMessage } from "../../../util/toastNotification";

export function* checkToken({ payload }) {
  const { token } = payload;

  try {
    /* const { data } = yield call(
      api.get,
      `checkToken?token=${token}`
    ); */
    /* if (!data.token) {
      errorMessage({ message: "Erro ao processar requisição" });
      history.push("/login");
      return;
    } */

    yield put(checkTokenSuccess(token));
    history.push("/login");
  } catch ({ response: error }) {
    errorMessage({
      message: "Falha ao consultar token",
    });
    history.push("/login");
  }
}

export function* changePasswordRequest({ payload }) {
  const { token, password } = payload;

  try {
    const { data } = yield call(api.put, `change-password`, {
      token,
      password,
    });
    yield put(changePasswordSucess());
    successMessage({
      message: data.message ? data.message : "Senha alterada com sucesso",
    });
    history.push("/login");
  } catch ({ response: error }) {
    yield put(changePasswordFailure());
    errorMessage({
      message:
        error && error.data && error.data.message
          ? error.data.message
          : "Falha ao processar alteração de senha",
    });
    history.push("/login");
  }
}

export default all([
  takeLatest("@change_password/CHECK_TOKEN_REQUEST", checkToken),
  takeLatest("@change_password/CHANGE_PASSWORD_REQUEST", changePasswordRequest),
]);
