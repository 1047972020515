import styled from "styled-components";
import { COLORS } from "../../styles/defaults";
import Select from "../../components/defaultComponents/Select";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1320px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1320px;
  padding-top: 60px;
`;

export const TitleBox = styled.div`
  display: flex;
`;

export const FilterBox = styled.div`
  display: flex;
  background: #fff;
  border-radius: 30px;
  padding: 0 20px;

  input {
    border: none;
  }

  input[type="button"] {
    background: transparent;
    color: ${COLORS.$lightGreen};
    cursor: pointer;
  }

  input[type="text"] {
    border-left: 1px solid ${COLORS.$gray.$borderLine};
    margin: 8px 0;
    padding-left: 12px;
    width: 300px;
  }
`;

export const LastDaysSelect = styled(Select)`
  width: 250px;
  margin-left: 25px;
`;

export const FilterSelect = styled(Select)`
  border-radius: 0;
  border: none;
`;
