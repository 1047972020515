import React from "react";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";

import { Container, PopupBox } from "./style";

export default function DefaultModal({
  opened,
  children,
  onClose,
  closeOnDocumentClick,
  className,
}) {
  return (
    <PopupBox className={className}>
      <Popup
        contentStyle={{ background: "#f3f3f5", border: 0 }}
        open={opened}
        modal
        closeOnDocumentClick={closeOnDocumentClick}
        onClose={onClose}
      >
        <Container>{children}</Container>
      </Popup>
    </PopupBox>
  );
}

DefaultModal.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
  className: PropTypes.string,
};

DefaultModal.defaultProps = {
  opened: false,
  closeOnDocumentClick: true,
  className: "",
};
