export function showModal({ title, text }) {
  return {
    type: "@success_modal/SHOW_MODAL",
    payload: { title, text },
  };
}

export function closeModal() {
  return {
    type: "@success_modal/CLOSE_MODAL",
    payload: {},
  };
}
