import api from "../api";

export default async cpf => {
  try {
    const { data } = await api.get(`get-user-info?cpf=${cpf}`);
    const { name, phone, email, isUser } = data;

    return { name, phone, email, isUser };
  } catch (e) {
    return {
      isUser: false,
    };
  }
};
