export function showModal({ title, text, notificationId, request }) {
  return {
    type: "@ticket_send_sms_modal/SHOW_MODAL",
    payload: { title, text, notificationId, request },
  };
}

export function closeModal() {
  return {
    type: "@ticket_send_sms_modal/CLOSE_MODAL",
    payload: {},
  };
}
