import api from "./api";

export const getBoleto = async ({ company, invoiceId }) => {
  const response = { boleto: {} };

  try {
    const {
      data: { boleto },
    } = await api.get(`boleto?invoiceId=${invoiceId}&company=${company}`);

    response.boleto = boleto;
  } catch {
    response.boleto = {};
  }

  return response;
};
