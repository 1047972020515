/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Box,
  Image,
  Button,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
// import { Boleto } from 'components/home/types';
// import { createDecipheriv } from 'crypto';
import BarCode from "react-barcode";
import { linhaDigitavel2CodBarras } from "@mrmgomes/boleto-utils";
import history from "../../services/history";
import { getBoleto } from "../../services/boleto";

const Title = props => {
  const { children } = props;
  return <Text fontSize="10px">{children}</Text>;
};

const Description = props => {
  const { children } = props;
  return (
    <Text fontSize="12px" fontWeight="bold">
      {children}
    </Text>
  );
};

const DescriptionAlignedToRight = props => {
  const { children } = props;
  return (
    <Text fontSize="12px" fontWeight="bold" textAlign="right">
      {children}
    </Text>
  );
};

export default function Barcode({ location }) {
  const [boleto, setBoleto] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [barcodeBackground, setBarcodeBackground] = useState("#f3f3f5");
  const componentRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    function getInvoiceIdBy({ search }) {
      return new URLSearchParams(search).get("invoiceId");
    }
    function getCompanyBy({ search }) {
      return new URLSearchParams(search).get("company");
    }
    const invoiceId = getInvoiceIdBy(location);
    const company = getCompanyBy(location);

    if (!company || !invoiceId) {
      history.push("/");
    }

    getBoleto({ invoiceId, company })
      .then(response => {
        if (!response || !response.boleto || !response.boleto.cedente) {
          throw new Error();
        }
        setBoleto(response.boleto);
      })
      .catch(() => setLoadingText("Falha ao carregar boleto"))
      .finally(() => setLoading(false));
  }, [location]);

  const reactToPrintTrigger = useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return (
      <Flex justifyContent="center" paddingX="16px" paddingY="48px">
        <Flex direction="column" w="100%" maxW="900px" position="relative">
          <Flex>
            <Flex direction="column">
              <Heading textAlign="center" paddingBottom="16px" fontSize="16px">
                Instruçoes de pagamento
              </Heading>
              <Text textAlign="center" fontSize="12px" paddingTop="8px">
                Lembramos que esse boleto estará disponível somente até dois
                dias após a data de vencimento.
              </Text>
              <Text textAlign="center" fontSize="12px" paddingTop="8px">
                Por limitações bancárias após o vencimento o mesmo somente
                poderá ser pago no banco emissor.
              </Text>
              <Text textAlign="center" fontSize="12px" paddingTop="8px">
                Após esse prazo seu contrato será cancelado e a renovação do
                mesmo estará sujeito a existência de vagas disponíveis e a
                tabela de preços vigente na ocasião
              </Text>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              right="0"
              top="50%"
              transform="translateY(-50%)"
            >
              <Image
                w="45px"
                src="https://i.ibb.co/mcRf2z1/Screenshot-2021-11-18-14-21-04-404.png"
              />
              <Text fontSize="12px">Imprimir</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }, []);

  const {
    cedente,
    dataVencimento,
    valorTotal,
    valorDesconto,
    dataDocumento,
    numeroDocumento,
    carteira,
    nossoNumero,
    valorMulta,
    sacado,
    agenciaCodigoCedente,
    valorCobrado,
    codigoDeBarras,
    aceite,
    dataProcessamento,
    quantidade,
    outrasDeducoes,
    outrosAcrescimos,
    instrucoes,
    address,
    address1,
    numeroBanco,
    nomeBanco,
  } = boleto || { instrucoes: [] };

  if (loading) {
    return (
      <Flex
        h="100vh"
        w="100%"
        align="center"
        justifyContent="center"
        background="rgba(0,0,0,0.3)"
        position="relative"
      >
        <Text>Carregando boleto</Text>
        {loadingText === "" && (
          <Flex
            position="absolute"
            left="0"
            top="0"
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
            background="rgba(0,0,0,0.3)"
            zIndex="99999"
          >
            <Button
              isLoading
              border="none"
              background="transparent"
              w="120px"
              h="120px"
              fontSize="48px"
              _focus={{
                background: "transparent",
              }}
              _hover={{
                background: "transparent",
              }}
              _active={{
                background: "transparent",
              }}
            />
          </Flex>
        )}
      </Flex>
    );
  }

  return (
    <div>
      <ReactToPrint
        content={() => componentRef.current}
        documentTitle="AwesomeFileName"
        removeAfterPrint
        trigger={reactToPrintTrigger}
        onBeforeGetContent={() => {
          setBarcodeBackground("#fff");
        }}
        onAfterPrint={() => {
          setBarcodeBackground("#f3f3f5");
        }}
      />
      <Flex ref={componentRef} justifyContent="center" padding="16px">
        <Flex direction="column" w="100%" maxW="900px">
          {/* Start Grid Top */}
          <Grid templateColumns="3fr 1fr 1fr 1fr">
            {/* Start Heading */}
            <GridItem colSpan={4} borderBottom="1px solid #000">
              <Flex justifyContent="space-between">
                <Flex>
                  <Heading paddingLeft="16px" fontSize="20px">
                    Banco {nomeBanco}
                  </Heading>
                  <Heading paddingLeft="24px" fontSize="20px">
                    {numeroBanco}
                  </Heading>
                </Flex>
                <Heading fontSize="16px">Recibo do Sacado</Heading>
              </Flex>
            </GridItem>
            {/* End Heading */}

            {/* Start Line 1 */}
            <>
              <Flex direction="column" border="1px solid #000" borderX="none">
                <Title>Cedente</Title>
                <Description>{cedente}</Description>
              </Flex>

              <Flex direction="column" border="1px solid #000">
                <Title>Vencimento</Title>
                <Description>{dataVencimento}</Description>
              </Flex>

              <Flex
                direction="column"
                border="1px solid #000"
                borderLeft="none"
              >
                <Title>(x) Valor do Documento</Title>
                <Description>{valorTotal}</Description>
              </Flex>

              <Flex direction="column" border="1px solid #000" borderX="none">
                <Title>(-) Desconto/Abatimento</Title>
                <Description>{valorDesconto}</Description>
              </Flex>
            </>
            {/* End Line 1 */}

            {/* Start Line 2 */}
            <>
              <Grid templateColumns="1fr 2fr" borderBottom="1px solid #000">
                <Flex direction="column">
                  <Title>Data do documento</Title>
                  <Description>{dataDocumento}</Description>
                </Flex>
                <Flex direction="column" borderLeft="1px solid #000">
                  <Title>N do Documento</Title>
                  <Description>{numeroDocumento}</Description>
                </Flex>
              </Grid>

              <Grid
                templateColumns="1fr 1fr"
                border="1px solid #000"
                borderTop="none"
              >
                <Flex direction="column">
                  <Title>Espécie</Title>
                  <Description>REAL</Description>
                </Flex>
                <Flex direction="column" borderLeft="1px solid #000">
                  <Title>Carteira</Title>
                  <Description>{carteira}</Description>
                </Flex>
              </Grid>

              <Flex direction="column" borderBottom="1px solid #000">
                <Title>Nosso Número</Title>
                <Description>{nossoNumero}</Description>
              </Flex>

              <Flex
                direction="column"
                border="1px solid #000"
                borderRight="none"
                borderTop="none"
              >
                <Title>(+) Mora/Mulra</Title>
                <Description>{valorMulta}</Description>
              </Flex>
            </>
            {/* End Line 2 */}

            {/* Start Line 3 */}
            <>
              <GridItem colSpan={2} borderBottom="1px solid #000">
                <Flex direction="column">
                  <Title>Sacado</Title>
                  <Description>{sacado}</Description>
                </Flex>
              </GridItem>

              <Flex direction="column" border="1px solid #000" borderTop="none">
                <Title>Agência / Código cedente</Title>
                <Description>{agenciaCodigoCedente}</Description>
              </Flex>

              <Flex direction="column" borderBottom="1px solid #000">
                <Title>(=) Valor Cobrado</Title>
                <Description>{valorCobrado}</Description>
              </Flex>
            </>
            {/* End Line 3 */}

            {/* Start Line 4 */}
            <GridItem
              colSpan={4}
              paddingTop="16px"
              borderTop="1px solid #000"
              borderBottom="1px dotted #000"
            >
              <Flex>
                <Title>Corte na linha pontilhada</Title>
              </Flex>
            </GridItem>
            {/* End Line 4 */}
          </Grid>
          {/* End Grid top */}

          {/* Start Grid Bottom */}
          <Grid templateColumns="1fr 1fr 1fr 1fr 2fr">
            {/* Start Heading */}
            <GridItem
              colSpan={5}
              borderBottom="1px solid #000"
              paddingTop="16px"
            >
              <Flex justifyContent="space-between">
                <Flex>
                  <Heading paddingLeft="16px" fontSize="20px">
                    Banco {nomeBanco}
                  </Heading>
                  <Heading paddingLeft="24px" fontSize="20px">
                    {numeroBanco}
                  </Heading>
                </Flex>
                <Heading fontSize="16px">{codigoDeBarras}</Heading>
              </Flex>
            </GridItem>
            {/* End Heading */}

            {/* Start Line 1 */}
            <>
              <GridItem colSpan={4}>
                <Flex direction="column" border="1px solid #000" borderX="none">
                  <Title>Local de pagamento</Title>
                  <Description>
                    Até o vencimento, pagar em qualquer rede bancária
                  </Description>
                </Flex>
              </GridItem>

              <Flex
                direction="column"
                border="1px solid #000"
                borderRight="none"
              >
                <Title>Vencimento</Title>
                <DescriptionAlignedToRight>
                  {dataVencimento}
                </DescriptionAlignedToRight>
              </Flex>
            </>
            {/* End Line 1 */}

            {/* Start Line 2 */}
            <>
              <GridItem colSpan={4}>
                <Flex direction="column" borderBottom="1px solid #000">
                  <Title>Cedente</Title>
                  <Description>{cedente}</Description>
                </Flex>
              </GridItem>

              <Flex
                direction="column"
                borderLeft="1px solid #000"
                borderBottom="1px solid #000"
              >
                <Title>Agência / Código cedente</Title>
                <DescriptionAlignedToRight>
                  {agenciaCodigoCedente}
                </DescriptionAlignedToRight>
              </Flex>
            </>
            {/* End Line 2 */}

            {/* Start Line 3 */}
            <>
              <Flex direction="column" borderBottom="1px solid #000">
                <Title>Data do documento</Title>
                <Description>{dataDocumento}</Description>
              </Flex>

              <Flex
                direction="column"
                borderBottom="1px solid #000"
                borderLeft="1px solid #000"
              >
                <Title>No documento</Title>
                <Description>{numeroDocumento}</Description>
              </Flex>

              <Grid
                templateColumns="1fr 1fr"
                borderX="1px solid #000"
                borderBottom="1px solid #000"
              >
                <Flex direction="column">
                  <Title>Espécie doc.</Title>
                  <Description>R$</Description>
                </Flex>

                <Flex direction="column" borderLeft="1px solid #000">
                  <Title>Aceite</Title>
                  <Description>{aceite}</Description>
                </Flex>
              </Grid>

              <Flex direction="column" borderBottom="1px solid #000">
                <Title>Data processamento</Title>
                <Description>{dataProcessamento}</Description>
              </Flex>

              <Flex
                direction="column"
                borderLeft="1px solid #000"
                borderBottom="1px solid #000"
              >
                <Title>Nosso número</Title>
                <DescriptionAlignedToRight>
                  {nossoNumero}
                </DescriptionAlignedToRight>
              </Flex>
            </>
            {/* End Line 3 */}

            {/* Start Line 4 */}
            <>
              <Flex direction="column" borderBottom="1px solid #000">
                <Title>Uso do banco</Title>
                <Description> </Description>
              </Flex>

              <Grid
                templateColumns="1fr 1fr"
                borderX="1px solid #000"
                borderBottom="1px solid #000"
              >
                <Flex direction="column">
                  <Title>Carteira</Title>
                  <Description>{carteira}</Description>
                </Flex>

                <Flex direction="column" borderLeft="1px solid #000">
                  <Title>Espécie</Title>
                  <Description>REAL</Description>
                </Flex>
              </Grid>

              <Flex
                direction="column"
                borderBottom="1px solid #000"
                borderLeft="1px solid #000"
              >
                <Title>Quantidade</Title>
                <Description>{quantidade}</Description>
              </Flex>

              <Flex direction="column" borderBottom="1px solid #000">
                <Title>(x) Valor</Title>
                <Description> </Description>
              </Flex>

              <Flex
                direction="column"
                borderLeft="1px solid #000"
                borderBottom="1px solid #000"
              >
                <Title>(=) Valor documento</Title>
                <DescriptionAlignedToRight>
                  {valorTotal}
                </DescriptionAlignedToRight>
              </Flex>
            </>
            {/* End Line 4 */}

            {/* Start Line 5 */}
            <GridItem
              colSpan={4}
              rowSpan={5}
              borderBottom="1px solid #000"
              borderRight="1px solid #000"
            >
              <Title>Instruções (Texto de responsabilidade do cedente)</Title>
              {instrucoes &&
                instrucoes.map(item => (
                  <Description key={item}>{item}</Description>
                ))}
            </GridItem>

            <Flex direction="column" borderBottom="1px solid #000">
              <Title>(-) Desconto / Abatimentos</Title>
              <DescriptionAlignedToRight>
                {valorDesconto}
              </DescriptionAlignedToRight>
            </Flex>

            <Flex direction="column" borderBottom="1px solid #000">
              <Title>(-) Outras deduções</Title>
              <DescriptionAlignedToRight>
                {outrasDeducoes}
              </DescriptionAlignedToRight>
            </Flex>

            <Flex direction="column" borderBottom="1px solid #000">
              <Title>(+) Mora / Multa</Title>
              <DescriptionAlignedToRight>
                {valorMulta}
              </DescriptionAlignedToRight>
            </Flex>

            <Flex direction="column" borderBottom="1px solid #000">
              <Title>(+) Outros acréscimos</Title>
              <DescriptionAlignedToRight>
                {outrosAcrescimos}
              </DescriptionAlignedToRight>
            </Flex>

            <Flex direction="column" borderBottom="1px solid #000">
              <Title>(=) Valor cobrado</Title>
              <DescriptionAlignedToRight>
                {valorCobrado}
              </DescriptionAlignedToRight>
            </Flex>

            {/* End Line 5 */}

            {/* Start Line 6 */}
            <GridItem colSpan={5} borderBottom="1px solid #000">
              <Flex direction="column">
                <Title>Sacado</Title>
                <Description>{sacado}</Description>
                <Description>{address}</Description>
                <Description>{address1}</Description>
              </Flex>
            </GridItem>
            {/* End Line 6 */}

            {/* Start Line 7 */}
            <GridItem colSpan={3} borderBottom="1px solid #000">
              <Title>Sacador / Avalista</Title>
            </GridItem>
            <GridItem colSpan={2} borderBottom="1px solid #000">
              <Flex alignItems="baseline">
                <Title>Autenticação mecânica - </Title>
                <Box w="2px" />
                <Description>Ficha de Compensação</Description>
              </Flex>
            </GridItem>
            {/* End Line 7 */}

            {/* Start Line 8 */}
            <GridItem colSpan={5} paddingY="16px">
              <BarCode
                value={linhaDigitavel2CodBarras(codigoDeBarras)}
                format="ITF"
                width={2}
                displayValue={false}
                background={barcodeBackground}
              />
            </GridItem>
            {/* End Line 8 */}

            {/* Start Line 9 */}
            <GridItem colSpan={5} borderBottom="1px dotted #000">
              <Flex>
                <Title>Corte na linha pontilhada</Title>
              </Flex>
            </GridItem>
            {/* End Line 9 */}
          </Grid>
          {/* End Grid Bottom */}
        </Flex>
      </Flex>
    </div>
  );
}
