import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, ContentLoading } from "./style";

import Shipment from "../Shipment";
import { formatPrice } from "../../../util/format";
import api from "../../../services/api";
import { selectShipment } from "../../../store/modules/cart/actions";
import LoadingShipments from "../LoadingShipment";

export default function List() {
  const dispatch = useDispatch();
  const [shipments, setShipment] = useState([]);
  const { selectedContract } = useSelector(state => state.session);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api.get("shipments").then(({ data }) => {
      setShipment(data.shipments);
      setLoading(false);
    });
  }, [selectedContract]);

  const selectedId = useSelector(state => state.cart.shipmentSelected.id);

  const handleSelectShipment = useCallback(
    shipment => {
      dispatch(selectShipment(shipment));
    },
    [dispatch]
  );

  return (
    <>
      {loading ? (
        <ContentLoading>
          <LoadingShipments />
        </ContentLoading>
      ) : (
        <Container>
          {shipments.map(shipment => (
            <Shipment
              key={shipment.id}
              shipment={shipment}
              handleSelectShipment={handleSelectShipment}
              selected={selectedId === shipment.id}
            />
          ))}
        </Container>
      )}
    </>
  );
}
