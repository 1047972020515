import React from "react";
import PropTypes from "prop-types";

import Modal from "../DefaultModal";
import { LinksBox } from "../DefaultModal/style";
import Button from "../../defaultComponents/Button";
import history from "../../../services/history";

export default function WaitingBought({
  opened,
  onClose,
  closeOnDocumentClick,
}) {
  function handleOrders() {
    history.push("orders");
    onClose();
  }
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <i className="far fa-check-circle" />
      <h2>Compra sendo processada!</h2>
      <p>
        No prazo máximo de 24 horas você receberá uma notificação aqui no site
        com o status da sua compra. E também receberá no e-mail cadastrado a
        confirmação com os detalhes do seu pedido.
      </p>
      <ul>
        <li>
          Certifique-se de verificar sua caixa de spam ou seu lixo eletrônico.
        </li>
        <li>
          Qualquer dúvida entre em contato pelo e-mail: selos@netpark.com.br
        </li>
      </ul>
      <h3>O que você deseja fazer agora?</h3>
      <LinksBox>
        <Button
          className="light"
          value="Validar selos digitais"
          onClick={onClose}
        />
        <Button
          className="dark"
          value="Ver meus pedidos"
          onClick={handleOrders}
        />
      </LinksBox>
    </Modal>
  );
}

WaitingBought.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
};

WaitingBought.defaultProps = {
  closeOnDocumentClick: true,
};
