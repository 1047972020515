import React from "react";
import PropTypes from "prop-types";

import { Container, Box, Error } from "./style";

export default function Input2({
  id,
  name,
  value,
  placeholder,
  label,
  onBlur,
  onChange,
  onFocus,
  className,
  error,
  maxLength,
}) {
  return (
    <Box className={className}>
      <Container name={label}>
        <input
          className={error ? "invalid" : ""}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          id={id}
          type="text"
          placeholder={placeholder}
          maxLength={maxLength}
          onFocus={onFocus}
        />
        <label htmlFor={id}>{label}</label>
      </Container>
      {error && <Error>{error}</Error>}
    </Box>
  );
}

Input2.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input2.defaultProps = {
  id: null,
  name: null,
  value: "",
  placeholder: "",
  label: "",
  className: "",
  error: "",
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  maxLength: null,
};
