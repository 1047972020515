import styled from "styled-components";
import { LinksBox as DefaultLinksBox } from "../DefaultModal/style";
import DefaultModal from "../DefaultModal";

export const LinksBox = styled(DefaultLinksBox)`
  justify-content: flex-end;
  margin-top: 30px;
  .dark {
    min-width: 120px;
  }
`;

export const Modal = styled(DefaultModal)`
  .redColor {
    color: red;
  }
`;
