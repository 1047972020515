import React, { useState } from "react";
import PropTypes from "prop-types";

import ReactLoading from "react-loading";
import { LinksBox } from "../DefaultModal/style";
import Button from "../../defaultComponents/Button";
import { Modal, CardBox, List, Span, LoadingBox } from "./style";
import Card from "../../CreditCard";
import api from "../../../services/api";
import { successMessage } from "../../../util/toastNotification";

export default function DeleteCard({
  opened,
  onClose,
  closeOnDocumentClick,
  card,
}) {
  const [loading, setLoading] = useState(false);
  async function handleDeleteCard() {
    setLoading(true);
    await api.delete("/cards", { data: { id: card.token } });
    successMessage({ message: "Cartão deletado com sucesso" });
    setLoading(false);
    onClose();
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <h2>Excluir Cartão de Crédito</h2>
      <Span>
        Ao confirmar esta ação, não será mais possível realizar compras com este
        cartão de crédito
      </Span>

      <List>
        {card && (
          <CardBox key={card.token}>
            <Card
              lastNumbers={card.lastFourDigits}
              name={card.name}
              expiry={card.expiry}
              brand={card.brand}
            />
          </CardBox>
        )}
      </List>

      {loading ? (
        <LoadingBox>
          <ReactLoading type="bars" color="#00205b" height={24} width={24} />
        </LoadingBox>
      ) : (
        <LinksBox>
          <Button className="light" value="Cancelar" onClick={onClose} />
          <Button
            className="dark"
            value="Confirmar"
            onClick={handleDeleteCard}
          />
        </LinksBox>
      )}
    </Modal>
  );
}

DeleteCard.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDocumentClick: PropTypes.bool,
  card: PropTypes.shape({
    token: PropTypes.string,
    lastFourDigits: PropTypes.string,
    name: PropTypes.string,
    expiry: PropTypes.string,
    brand: PropTypes.string,
  }).isRequired,
};

DeleteCard.defaultProps = {
  closeOnDocumentClick: true,
};
