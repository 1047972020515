import produce from "immer";

const INITIAL_STATE = {
  selectedPage: "",
};

export default function header(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@header/SET_SELECTED_PAGE":
      return produce(state, draft => {
        draft.selectedPage = action.payload.page;
      });
    default:
      return state;
  }
}
