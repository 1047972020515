import styled from "styled-components";
import { COLORS } from "../../styles/defaults";

export const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  height: 66px;
  background-color: #00205b;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 350px) {
    padding-left: 0;
    padding-right: 0;
  }

  .left-side {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
      li {
        font-size: 12px;
        color: white;
        opacity: 0.7;
      }
    }
    @media only screen and (max-width: 920px) {
      display: none;
    }
    @media only screen and (max-width: 1076px) {
      img {
        display: none;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    outline-style: none;
    @media only screen and (max-width: 920px) {
      justify-content: center;
      width: 100%;
    }
    @media only screen and (max-width: 475px) {
      flex-direction: column;
    }
    li {
      margin-left: 25px;
      @media only screen and (max-width: 350px) {
        margin-left: 0;
      }
      a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        opacity: 0.7;
        transition: ease 0.4s;
      }
      button {
        cursor: pointer;
        border: none;
        background: transparent;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        opacity: 0.7;
        text-transform: uppercase;
        transition: ease 0.4s;
      }
    }
  }
`;

export const ContentModal = styled.ul`
  li {
    color: ${COLORS.$darkGray};
  }
`;
