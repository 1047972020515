import React, { useEffect, useState } from "react";
import atob from "atob";

import BarCode from "qrcode.react";
import history from "../../services/history";
import { Container } from "./style";

export default function Barcode({ location }) {
  const [stamps, setStamps] = useState([]);
  useEffect(() => {
    function getTokenBy({ search }) {
      return new URLSearchParams(search).get("selos");
    }
    const encodedStamps = getTokenBy(location);
    if (!encodedStamps) {
      history.push("/");
    }

    try {
      const decodedStamps = atob(encodedStamps)
        .replace("[", "")
        .replace("]", "")
        .replace(/ /g, "")
        .replace(/"/g, "")
        .split(",");
      setStamps(decodedStamps);
    } catch (e) {
      history.push("/");
    }
  }, [location]);

  return (
    <Container>
      <h5>Apresente essa tela para o funcionário no guichê</h5>{" "}
      <span>
        <br />
      </span>
      {stamps.map(stamp => (
        <>
          <span>{stamp.match(/.{1,4}/g).join(" ")}</span>
          <BarCode value={stamp} />
          <span>
            <br />
          </span>
        </>
      ))}
    </Container>
  );
}
