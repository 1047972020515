import produce from "immer";

const INITIAL_STATE = {
  opened: false,
  title: "",
  text: "",
  notificationId: "",
  request: {},
};

export default function modalTicketSendSms(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@ticket_send_sms_modal/SHOW_MODAL":
      return produce(state, draft => {
        draft.opened = true;
        draft.title = action.payload.title;
        draft.text = action.payload.text;
        draft.request = action.payload.request;
        draft.notificationId = action.payload.notificationId;
      });
    case "@ticket_send_sms_modal/CLOSE_MODAL":
      return INITIAL_STATE;
    default:
      return state;
  }
}
