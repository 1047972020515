import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPayment } from "../../../store/modules/cart/actions";
import { Container } from "./style";
import CardModal from "../../modals/SelectCard";
import Payment from "../Payment";
import { Cartao, Boleto } from "../data";

export default function List() {
  const dispatch = useDispatch();
  const [payments, setPayments] = useState([]);
  const selectedId = useSelector(state => state.cart.paymentSelected.id);
  const { selectedCard } = useSelector(state => state.cart.paymentSelected);
  const [cardModalOpened, setCardModalOpened] = useState(false);
  const { sites, selectedContract } = useSelector(state => state.session);

  useEffect(() => {
    async function loadPayments(tsites, tselectedContract) {
      const tempSelectedContract = tsites.find(
        item => item.contract === tselectedContract.contract
      );
      const data = [];

      if (!tempSelectedContract || tempSelectedContract.boleto !== "FALSE") {
        data.push(Boleto);
      }

      if (!tempSelectedContract || tempSelectedContract.cartao !== "FALSE") {
        data.push(Cartao);
      }

      setPayments(data);
    }

    loadPayments(sites, selectedContract);
  }, [sites, selectedContract]);

  function closeCardModal() {
    if (!selectedCard) {
      dispatch(selectPayment(Boleto));
    }
    setCardModalOpened(false);
  }

  function openCardModal() {
    setCardModalOpened(true);
  }

  function handleSelectCard(card) {
    dispatch(selectPayment({ ...Cartao, selectedCard: card }));
    setCardModalOpened(false);
  }

  const handleSelectPayment = useCallback(
    payment => {
      if (payment.id === 2) {
        openCardModal();
      }
      dispatch(selectPayment(payment));
    },
    [dispatch]
  );

  return (
    <Container>
      {payments.map(payment => (
        <Payment
          key={payment.id}
          selected={selectedId === payment.id}
          handleSelectPayment={() => handleSelectPayment(payment)}
          payment={
            selectedId === payment.id ? { ...payment, selectedCard } : payment
          }
        />
      ))}
      <CardModal
        opened={cardModalOpened}
        onClose={closeCardModal}
        onSelectCard={handleSelectCard}
      />
    </Container>
  );
}
