import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  > span {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  > canvas + span {
    margin-top: 25px;
  }
`;
