import produce from "immer";

const INITIAL_STATE = {
  wallet: [],
  totalHours: 0,
  totalStamps: 0,
  loading: false,
};

export default function wallet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@wallet/SET_WALLET":
      return produce(state, draft => {
        const w = action.payload.wallet;
        draft.loading = false;
        draft.wallet = w;
        draft.totalStamps = w.reduce((t, stamp) => t + stamp.quantity, 0);
        draft.totalHours = w.reduce(
          (t, stamp) => t + stamp.hourValue * stamp.quantity,
          0
        );
      });
    case "@wallet/RELOAD":
      return { ...INITIAL_STATE, loading: true };
    default:
      return state;
  }
}
